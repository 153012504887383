import { Box, List, styled } from "@mui/material";
import Button from "@component/button/Button";
import Text from "@component/text/Text";

export const StyledButton = styled(Button)(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.common.white,
  color: theme.palette.grey[900],
  border: "1px solid",
  borderColor: theme.palette.grey[900],
  height: 40,
  justifyContent: "space-between",
  padding: theme.spacing(0, 8),
  fontSize: 15,
  borderRadius: theme.spacing(2),
  [theme.breakpoints.down("md")]: {
    height: 55,
    padding: theme.spacing(10, 8),
  },
  [theme.breakpoints.down("sm")]: {
    height: 45,
  },
}));

export const StyledList = styled(List)(({ theme }) => ({
  padding: theme.spacing(4, 4),
}));

export const StyledListContainer = styled(Box)(({ theme }) => ({
  overflow: "auto",
  maxHeight: "40vh",
  marginBottom: theme.spacing(8),
  margin: theme.spacing(4, 0),
}));

export const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flex: 1,
}));

export const MainSelectionContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  borderBottom: "1px solid",
  borderBottomColor: theme.palette.grey[400],
  padding: theme.spacing(4, 0),
}));

export const StyledText = styled(Text)(({ theme }) => ({
  color: theme.palette.common.black,
  display: "flex",
  flex: 1,
}));
