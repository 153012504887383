import { Box, styled } from "@mui/material";

export const StyledContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  marginTop: theme.spacing(6),
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "80vh",
}));

export const StyledButton = styled(Box)(({ theme }) => ({
  border: "1px solid",
  width: "50%",
  borderRadius: theme.spacing(0),
  marginTop: theme.spacing(4),
  padding: theme.spacing(3, 0),
  display: "flex",
  justifyContent: "center",
  cursor: "pointer",
}));
