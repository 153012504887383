import { useEffect, useState } from "react";
import { addZones, updateZones } from "@services/redux/zone/action";
import {
  AddEditZoneSliderControllerProps,
  AddEditZoneSliderProps,
} from "./addEditZoneSlider.interface";
import { axiosInstance } from "@services/api/api";
import { checkZone } from "@utils/validation/validation";
import { Constant } from "@services/constant";
import { useAppDispatch, useAppSelector } from "@services/redux/controller";
import validationMessage from "@utils/validation/validationMessage";
import { ZoneProps } from "@services/redux/zone/interface";

const addEditZoneSliderController = ({
  handleCloseIcon,
  zoneId,
}: AddEditZoneSliderProps): AddEditZoneSliderControllerProps => {
  const [zone, setZone] = useState<string>();
  const [zoneError, setZoneError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string>("");

  const dispatch = useAppDispatch();
  const { zones } = useAppSelector((state) => state.zoneReducer);

  const zoneName = zones.filter((item: ZoneProps) => item._id === zoneId);

  useEffect(() => {
    if (zoneId) {
      setZone(zoneName[0].zone);
    } else {
      setZone("");
    }
  }, [zoneId]);

  const handleCloseSnackBar = (): void => {
    setOpenSnackBar(false);
  };

  const addZone = async () => {
    /* API call for adding or editing zone */
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(Constant.addEditZone, {
        zone: zone,
        id: zoneId,
      });
      if (zoneId) {
        dispatch(updateZones(data.data));
      } else {
        dispatch(addZones(data.data));
        setZone("");
      }
      setLoading(false);
      setTimeout(() => handleCloseIcon(), 1000);
      setOpenSnackBar(true);
      setSnackBarMessage(data.message);
    } catch (error: any) {
      setLoading(false);
      setOpenSnackBar(true);
      setSnackBarMessage(error.data.message);
    }
  };

  const validation = (): void => {
    /* Validation for zone */
    let isValid = true;
    if (!zone) {
      isValid = false;
      setZoneError(validationMessage.validationZone);
    } else if (!checkZone(zone)) {
      isValid = false;
      setZoneError(validationMessage.validationValidZone);
    } else {
      setZoneError("");
    }
    if (isValid) {
      addZone();
    }
  };

  return {
    zone,
    setZone,
    zoneError,
    loading,
    openSnackBar,
    validation,
    handleCloseSnackBar,
    snackBarMessage,
  };
};

export default addEditZoneSliderController;
