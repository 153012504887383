import React, { memo } from "react";
import ProgressBar from "@component/progressBar/ProgressBar";
import {
  StyledContainer,
  StyledDataContainer,
  StyledList,
  StyledListContainer,
  StyledProgressBarContainer,
  StyledUsersContainer,
} from "./usersFromCountries.style";
import Text from "@component/text/Text";
import { UsersFromCountriesDataItemProps } from "./usersFromCountries.interface";
import { usersFromCountriesData } from "./usersFromCountries.const";
import { useTheme } from "@mui/material";

const UsersFromCountries: React.FC = () => {
  const theme = useTheme();
  const ProgressBarRender: React.FC<UsersFromCountriesDataItemProps> = ({
    item,
  }) => {
    return (
      <StyledProgressBarContainer>
        <StyledContainer>
          <Text color={theme.palette.primary.main}>{item.country}</Text>
          <Text color={theme.palette.primary.main}>{item.numberOfUsers}</Text>
        </StyledContainer>
        <ProgressBar
          value={item.numberOfUsers / 15}
          sx={{
            "& .MuiLinearProgress-bar1Determinate": {
              backgroundColor: item.progressColor,
            },
          }}
        />
      </StyledProgressBarContainer>
    );
  };
  return (
    <StyledUsersContainer>
      <Text variant="h6" flex={1} color={theme.palette.primary.main}>
        Users from Countries
      </Text>
      <StyledListContainer>
        <StyledList>
          <StyledDataContainer>
            {usersFromCountriesData.map((item) => (
              <ProgressBarRender key={item.id} item={item} />
            ))}
          </StyledDataContainer>
        </StyledList>
      </StyledListContainer>
    </StyledUsersContainer>
  );
};

export default memo(UsersFromCountries);
