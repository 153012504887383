import { useEffect, useMemo, useState } from "react";
import { axiosInstance } from "@services/api/api";
import { Constant } from "@services/constant";
import { checkNumeric } from "@utils/validation/validation";
import {
  EditPortfolioProductControllerProps,
  EditPortfolioProductProps,
  ErrorProps,
} from "./editPortfolioProduct.interface";
import {
  addPortfolioProductData,
  editPortfolioProductData,
} from "@services/redux/companyPortfolio/action";
import { useAppDispatch, useAppSelector } from "@services/redux/controller";
import validationMessage from "@utils/validation/validationMessage";

const editPortfolioProductController = ({
  handleCloseIcon,
  productId,
  userId,
}: EditPortfolioProductProps): EditPortfolioProductControllerProps => {
  const { portfolioProduct } = useAppSelector(
    (state) => state.companyPortfolioReducer
  );

  useEffect(() => {
    if (productId) {
      setProductName(productData[0]?.productName);
      setProductTag(productData[0]?.tags);
      setDescription(productData[0]?.description);
      setNoOfProduct(productData[0]?.noOfProducts);
      setSelectedFile(productData[0]?.fileUrl);
    }
  }, []);

  const productData = useMemo(
    () =>
      productId &&
      portfolioProduct?.filter((item: any) => {
        return item._id === productId;
      }),
    [portfolioProduct]
  );
  const dispatch = useAppDispatch();

  const [productName, setProductName] = useState<string>("");
  const [productTag, setProductTag] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [noOfProduct, setNoOfProduct] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<Blob | string>("");
  const [removeFile, setRemoveFile] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorProps>({
    productNameError: null,
    productTagError: null,
    descriptionError: null,
    noOfProductError: null,
  });
  const [selectedFileError, setSelectedFileError] = useState<string | null>(
    null
  );
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string>("");

  const handleCloseSnackBar = (): void => {
    setOpenSnackBar(false);
  };

  const fileType = ["image/png"];

  const handleProfileImage = (e: any): void => {
    /* Functionality to choose image for profile image */
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile && fileType.includes(selectedFile.type)) {
        setSelectedFile(selectedFile);
        setSelectedFileError("");
      } else {
        setSelectedFile("");
        setSelectedFileError("Please select image file");
      }
    }
  };

  const validation = (): void => {
    /* Validation for edit product form */
    let isValidate = true;
    if (!productName) {
      isValidate = false;
      error.productNameError = validationMessage.productNameError;
    } else {
      error.productNameError = "";
    }
    if (!productTag) {
      isValidate = false;
      error.productTagError = validationMessage.productTagError;
    } else {
      error.productTagError = "";
    }
    if (!description) {
      isValidate = false;
      error.descriptionError = validationMessage.validationDescription;
    } else {
      error.descriptionError = "";
    }
    if (!noOfProduct) {
      isValidate = false;
      error.noOfProductError = validationMessage.validationNoOfProductError;
    } else if (!checkNumeric(noOfProduct)) {
      isValidate = false;
      error.noOfProductError =
        validationMessage.validationValidNoOfProductError;
    } else {
      error.noOfProductError = "";
    }
    setError({ ...error });

    if (isValidate) {
      if (productId) {
        editProduct();
      } else {
        addProduct();
      }
    }
  };

  const addProduct = async () => {
    /* API call for adding product */
    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("productName", productName);
    formData.append("tags", productTag);
    formData.append("noOfProducts", noOfProduct);
    formData.append("description", description);
    formData.append("files", selectedFile);
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(
        Constant.addPortfolioProduct,
        formData
      );
      setLoading(false);
      setTimeout(() => handleCloseIcon(), 2000);
      setOpenSnackBar(true);
      setSnackBarMessage(data.message);
      dispatch(addPortfolioProductData(data?.data));
    } catch (error: any) {
      setLoading(false);
      setOpenSnackBar(true);
      setSnackBarMessage(error.data.message);
    }
  };

  const editProduct = async () => {
    /* API call for editing product */
    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("productId", productId);
    formData.append("productName", productName);
    formData.append("tags", productTag);
    formData.append("noOfProducts", noOfProduct);
    formData.append("description", description);
    formData.append("files", selectedFile);
    if (removeFile) {
      formData.append("removeUrl", removeFile);
    }
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(
        Constant.editPortfolioProduct,
        formData
      );
      setLoading(false);
      setTimeout(() => handleCloseIcon(), 2000);
      setOpenSnackBar(true);
      setSnackBarMessage(data.message);
      dispatch(editPortfolioProductData(data?.data));
    } catch (error: any) {
      setLoading(false);
      setOpenSnackBar(true);
      setSnackBarMessage(error.data.message);
    }
  };

  const onDeleteIcon = (): void => {
    if (selectedFile) {
      setRemoveFile(productData[0]?.fileUrl);
    }
    setSelectedFile("");
  };

  return {
    description,
    noOfProduct,
    productName,
    productTag,
    removeFile,
    selectedFile,
    setDescription,
    setNoOfProduct,
    setProductName,
    setProductTag,
    error,
    validation,
    handleProfileImage,
    selectedFileError,
    loading,
    openSnackBar,
    snackBarMessage,
    handleCloseSnackBar,
    onDeleteIcon,
  };
};

export default editPortfolioProductController;
