import { TableCellDataProps } from "@component/customTable/table.interface";

export const tableCellData: TableCellDataProps[] = [
  {
    id: 1,
    title: "Qualification",
  },
  {
    id: 2,
    title: "Status",
  },
  {
    id: 3,
    title: "",
  },
];
