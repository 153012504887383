import { createTheme } from "@mui/material/styles";

const themeConfig = createTheme({
  palette: {
    primary: {
      main: "#3366CC",
      700: "#8FC899",
      500: "#AFD8B6",
      200: "#CFE8D3",
      100: "#EFF7F0",
      light: "#27739c",
      dark: "#074267",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#f36b10",
      700: "#576866",
      500: "#879392",
      200: "#CFD4D3",
      100: "#E7E9E9",
      dark: "#5768664D",
    },
    info: {
      main: "#FFFFFF",
      light: "#FFEED3",
      contrastText: "#E97216",
    },
    success: {
      main: "#39B54E",
      700: "#82D390",
      500: "#B3EFBE",
      200: "#D9FEDF",
      100: "#F6FFF8",
    },
    error: {
      main: "#EF1F1F",
      700: "#F46262",
      500: "#F78F8F",
      200: "#FCD2D2",
      100: "#FDE9E9",
    },
    warning: {
      main: "#FFBC0F",
      700: "#FFD260",
      500: "#FFE193",
      200: "#FFEDBF",
      100: "#FFFAED",
    },
    grey: {
      A100: "#94A3B8",
      800: "#808080",
      700: "#B4BFCD",
      500: "#C9D1DC",
      200: "#EAEDF1",
      100: "#F4F6F8",
    },
    divider: "#94A3B8",
    text: {
      primary: "#1C2639",
    },
  },
  spacing: [
    0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 48, 64, 128,
    256,
  ],
});

const scaleFontSize = (
  originalFontSize: number,
  mdCoefficient: number,
  smCoefficient: number
) => {
  return {
    fontSize: originalFontSize,
    [themeConfig.breakpoints.down("md")]: {
      fontSize: originalFontSize * mdCoefficient,
    },
    [themeConfig.breakpoints.down("sm")]: {
      fontSize: originalFontSize * smCoefficient,
    },
  };
};

themeConfig.typography.h1 = scaleFontSize(48, 0.85, 0.75);
themeConfig.typography.h2 = scaleFontSize(36, 0.85, 0.75);
themeConfig.typography.h3 = scaleFontSize(30, 0.85, 0.75);
themeConfig.typography.h4 = scaleFontSize(24, 0.85, 0.75);
themeConfig.typography.h5 = scaleFontSize(20, 0.85, 0.75);
themeConfig.typography.h6 = scaleFontSize(16, 0.85, 0.75);
// themeConfig.typography.bodyBig = scaleFontSize(14, 0.85, 0.75);
// themeConfig.typography.body = scaleFontSize(12, 0.85, 0.75);
// themeConfig.typography.bodySmall = scaleFontSize(10, 0.85, 0.75);

export const theme = themeConfig;
