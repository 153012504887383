import React, { memo } from "react";
import { Add } from "@mui/icons-material";
import { CompanyProductsCard } from "@component";
import EditPortfolioProduct from "./editPortfolioProduct/EditPortfolioProduct";
import portfolioProductController from "./portfolioProduct.controller";
import { PortfolioProductsProps } from "./portfolioProduct.interface";
import {
  StyledContainer,
  StyledList,
  StyledListContainer,
  StyledText,
  StyledFabButton,
  StyledFabButtonContainer,
} from "../portfolioMachine/portfolioMachine.style";

const PortfolioProducts: React.FC<PortfolioProductsProps> = ({ userId }) => {
  const {
    productData,
    handleCloseDrawer,
    handleOpenDrawer,
    openDrawer,
    index,
    removeProduct,
    loading,
    productId,
  } = portfolioProductController({ userId });
  return (
    <>
      <StyledFabButtonContainer>
        <StyledFabButton color="primary" onClick={() => handleOpenDrawer()}>
          <Add />
        </StyledFabButton>
      </StyledFabButtonContainer>
      {productData.length !== 0 ? (
        <StyledListContainer>
          <StyledList>
            {productData?.map((item) => (
              <CompanyProductsCard
                item={item}
                key={item._id}
                onEditIcon={(id) => handleOpenDrawer(id)}
                onDeleteIcon={(id) => removeProduct(id)}
                loading={loading}
                id={productId}
              />
            ))}
          </StyledList>
        </StyledListContainer>
      ) : (
        <StyledContainer>
          <StyledText>Nothing to show here</StyledText>
        </StyledContainer>
      )}
      {openDrawer && (
        <EditPortfolioProduct
          openDrawer={openDrawer}
          handleCloseIcon={handleCloseDrawer}
          productId={index}
          userId={userId}
        />
      )}
    </>
  );
};

export default memo(PortfolioProducts);
