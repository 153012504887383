import React, { memo } from "react";
import { Drawer as MUIDrawer, Theme, useMediaQuery } from "@mui/material";
import { SliderProps } from "./slider.interface";
import {
  BoxContainer,
  StyledBox,
  StyledCloseIcon,
  StyledContainer,
  StyledIconButton,
  StyledText,
} from "./slider.style";

const Slider: React.FC<SliderProps> = ({
  openDrawer,
  handleCloseIcon,
  children,
  title,
}) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  return (
    <MUIDrawer
      open={openDrawer}
      variant="temporary"
      onClose={handleCloseIcon}
      anchor={"right"}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <BoxContainer>
        <StyledContainer>
          <StyledBox>
            {title && <StyledText variant="h5">{title}</StyledText>}
            {isMobile || title ? (
              <StyledIconButton onClick={handleCloseIcon}>
                <StyledCloseIcon />
              </StyledIconButton>
            ) : null}
          </StyledBox>
          {children}
        </StyledContainer>
      </BoxContainer>
    </MUIDrawer>
  );
};

export default memo(Slider);
