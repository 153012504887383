import { useMemo, useState } from "react";
import {
  addJobWorkData,
  editJobWorkData,
} from "@services/redux/allCompanyDetails/action";
import { axiosInstance } from "@services/api/api";
import { checkNumeric } from "@utils/validation/validation";
import { Constant } from "@services/constant";
import {
  editProcessSliderControllerProps,
  EditProcessSliderProps,
  ErrorProps,
} from "./editProcessSlider.interface";
import { JobWorkType } from "../../machine/editMachineSlider/editMachineSlider.interface";
import { useAppDispatch, useAppSelector } from "@services/redux/controller";
import validationMessage from "@utils/validation/validationMessage";

const editProcessSliderController = ({
  handleCloseIcon,
  openDrawer,
  processId,
  userId,
}: EditProcessSliderProps): editProcessSliderControllerProps => {
  const { jobWork } = useAppSelector((state) => state.allCompanyDetailsReducer);
  const dispatch = useAppDispatch();
  const processData = useMemo(
    () =>
      jobWork?.filter((item: any) => {
        return item._id === processId;
      }),
    [jobWork]
  );
  const [description, setDescription] = useState<string>(
    processData[0]?.processesJobDescription
  );
  const [noOfProcess, setNoOfProcess] = useState<string>(
    processData[0]?.noOfProcesses
  );
  const [selectedFile, setSelectedFile] = useState<Blob | MediaSource | null>(
    processData[0]?.processesFiles
  );
  const [removeFile, setRemoveFile] = useState<string>();
  const [error, setError] = useState<ErrorProps>({
    selectProcessError: null,
    descriptionError: null,
    noOfProcessError: null,
  });
  const [selectedFileError, setSelectedFileError] = useState<string | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string>("");
  const [selectedProcess, setSelectedProcess] = useState<JobWorkType | string>(
    ""
  );
  const [selectProcessMainCategory, setSelectProcessMainCategory] = useState<
    JobWorkType | string
  >("");
  const [selectProcessSubMainCategory, setSelectProcessSubMainCategory] =
    useState<JobWorkType | string>("");

  const selectProcess = selectProcessSubMainCategory
    ? `${selectProcessMainCategory?.name}-${selectProcessSubMainCategory?.name}-${selectedProcess?.name}`
    : selectedProcess
    ? `${
        selectProcessMainCategory
          ? selectProcessMainCategory?.name + "-" + selectedProcess?.name
          : selectedProcess?.name
      }`
    : processData[0]?.processes
    ? processData[0]?.processes
    : "Select Processes";

  const handleCloseSnackBar = (): void => {
    setOpenSnackBar(false);
  };

  const fileType = ["image/png"];

  const handleSelectedFile = (e: any): void => {
    /* Functionality to choose image for profile image */
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile && fileType.includes(selectedFile.type)) {
        setSelectedFile(selectedFile);
        setSelectedFileError("");
      } else {
        setSelectedFile(null);
        setSelectedFileError("Please select image file");
      }
    }
  };

  const onDeleteIcon = (): void => {
    if (selectedFile) {
      setRemoveFile(processData[0]?.processesFiles);
    }
    setSelectedFile(null);
  };

  const validation = (): void => {
    /* Validation for edit process form */
    let isValidate = true;
    if (!selectProcess) {
      isValidate = false;
      error.selectProcessError = validationMessage.selectProcessValidation;
    } else {
      error.selectProcessError = "";
    }
    if (!description) {
      isValidate = false;
      error.descriptionError = validationMessage.validationDescription;
    } else {
      error.descriptionError = "";
    }
    if (!noOfProcess) {
      isValidate = false;
      error.noOfProcessError = validationMessage.noOfProcessValidation;
    } else if (!checkNumeric(noOfProcess)) {
      isValidate = false;
      error.noOfProcessError = validationMessage.validNoOfProcessValidation;
    } else {
      error.noOfProcessError = "";
    }
    setError({ ...error });
    if (isValidate) {
      if (processId) {
        editProcess();
      } else {
        addProcess();
      }
    }
  };

  const addProcess = async () => {
    /* API call for adding process */
    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("processes", selectProcess);
    formData.append("processesJobDescription", description);
    formData.append("noOfProcesses", noOfProcess);
    formData.append("processesFiles", selectedFile);
    if (removeFile) {
      formData.append("removeProcessesUrl", removeFile);
    }
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(Constant.addJobWork, formData);
      setLoading(false);
      dispatch(addJobWorkData(data.data));
      setTimeout(() => handleCloseIcon(), 2000);
      setOpenSnackBar(true);
      setSnackBarMessage(data.message);
    } catch (error: any) {
      setLoading(false);
      setOpenSnackBar(true);
      setSnackBarMessage(error.data.message);
    }
  };

  const editProcess = async () => {
    /* API call for editing process */
    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("jobWorkId", processId);
    formData.append("processes", selectProcess);
    formData.append("processesJobDescription", description);
    formData.append("noOfProcesses", noOfProcess);
    formData.append("processesFiles", selectedFile);
    if (removeFile) {
      formData.append("removeProcessesUrl", removeFile);
    }
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(
        Constant.editJobWorkAdmin,
        formData
      );
      setLoading(false);
      dispatch(editJobWorkData(data.data));
      setTimeout(() => handleCloseIcon(), 2000);
      setOpenSnackBar(true);
      setSnackBarMessage(data.message);
    } catch (error: any) {
      setLoading(false);
      setOpenSnackBar(true);
      setSnackBarMessage(error.data.message);
    }
  };

  return {
    description,
    error,
    handleSelectedFile,
    selectedFile,
    selectedFileError,
    setDescription,
    validation,
    handleCloseSnackBar,
    loading,
    openSnackBar,
    snackBarMessage,
    noOfProcess,
    selectProcess,
    setNoOfProcess,
    onDeleteIcon,
    selectedProcess,
    selectProcessMainCategory,
    selectProcessSubMainCategory,
    setSelectedProcess,
    setSelectProcessMainCategory,
    setSelectProcessSubMainCategory,
  };
};

export default editProcessSliderController;
