import React, { memo } from "react";
import { DialogActions, DialogTitle } from "@mui/material";
import Button from "@component/button/Button";
import { DeleteDialogProps } from "./deleteDialog.interface";
import { StyledDialog } from "./deleteDialog.style";

const DeleteDialog: React.FC<DeleteDialogProps> = ({
  handleCancel,
  handleDelete,
  dialogTitle,
  loading,
  ...rest
}) => {
  return (
    <StyledDialog
      BackdropProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0.1)" } }}
      {...rest}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleDelete} loading={loading} progressColor="info">
          Delete
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default memo(DeleteDialog);
