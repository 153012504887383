import { useMemo, useState } from "react";
import { axiosInstance } from "@services/api/api";
import { Constant } from "@services/constant";
import { checkNumeric } from "@utils/validation/validation";
import {
  addProductData,
  editProductData,
} from "@services/redux/allCompanyDetails/action";
import {
  EditProductSliderControllerProps,
  EditProductSliderProps,
  ErrorProps,
} from "./editProductSlider.interface";
import { useAppDispatch, useAppSelector } from "@services/redux/controller";
import validationMessage from "@utils/validation/validationMessage";

const editProductSliderController = ({
  handleCloseIcon,
  productId,
  userId,
}: EditProductSliderProps): EditProductSliderControllerProps => {
  const { product } = useAppSelector((state) => state.allCompanyDetailsReducer);
  const productData = useMemo(
    () =>
      product?.filter((item: any) => {
        return item._id === productId;
      }),
    [product]
  );
  const dispatch = useAppDispatch();

  const [productName, setProductName] = useState<string>(
    productData[0]?.productName
  );
  const [productTag, setProductTag] = useState<string>(productData[0]?.tags);
  const [description, setDescription] = useState<string>(
    productData[0]?.description
  );
  const [noOfProduct, setNoOfProduct] = useState<string>(
    productData[0]?.noOfProducts
  );
  const [selectedFile, setSelectedFile] = useState<Blob | string>(
    productData[0]?.fileUrl
  );
  const [removeFile, setRemoveFile] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorProps>({
    productNameError: null,
    productTagError: null,
    descriptionError: null,
    noOfProductError: null,
  });
  const [selectedFileError, setSelectedFileError] = useState<string | null>(
    null
  );
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string>("");

  const handleCloseSnackBar = (): void => {
    setOpenSnackBar(false);
  };

  const fileType = ["image/png"];

  const handleProfileImage = (e: any): void => {
    /* Functionality to choose image for profile image */
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile && fileType.includes(selectedFile.type)) {
        setSelectedFile(selectedFile);
        setSelectedFileError("");
      } else {
        setSelectedFile("");
        setSelectedFileError("Please select image file");
      }
    }
  };

  const validation = (): void => {
    /* Validation for edit product form */
    let isValidate = true;
    if (!productName) {
      isValidate = false;
      error.productNameError = validationMessage.productNameError;
    } else {
      error.productNameError = "";
    }
    if (!productTag) {
      isValidate = false;
      error.productTagError = validationMessage.productTagError;
    } else {
      error.productTagError = "";
    }
    if (!description) {
      isValidate = false;
      error.descriptionError = validationMessage.validationDescription;
    } else {
      error.descriptionError = "";
    }
    if (!noOfProduct) {
      isValidate = false;
      error.noOfProductError = validationMessage.validationNoOfProductError;
    } else if (!checkNumeric(noOfProduct)) {
      isValidate = false;
      error.noOfProductError =
        validationMessage.validationValidNoOfProductError;
    } else {
      error.noOfProductError = "";
    }
    setError({ ...error });

    if (isValidate) {
      if (productId) {
        editProduct();
      } else {
        addProduct();
      }
    }
  };

  const editProduct = async () => {
    /* API call for editing product */
    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("productId", productId);
    formData.append("productName", productName);
    formData.append("tags", productTag);
    formData.append("noOfProducts", noOfProduct);
    formData.append("description", description);
    formData.append("files", selectedFile);
    if (removeFile) {
      formData.append("removeUrl", removeFile);
    }
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(
        Constant.editProductAdmin,
        formData
      );
      setLoading(false);
      setTimeout(() => handleCloseIcon(), 2000);
      setOpenSnackBar(true);
      setSnackBarMessage(data.message);
      dispatch(editProductData(data?.data));
    } catch (error: any) {
      setLoading(false);
      setOpenSnackBar(true);
      setSnackBarMessage(error.data.message);
    }
  };

  const addProduct = async () => {
    /* API call for adding product */
    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("productName", productName);
    formData.append("tags", productTag);
    formData.append("noOfProducts", noOfProduct);
    formData.append("description", description);
    formData.append("files", selectedFile);
    if (removeFile) {
      formData.append("removeUrl", removeFile);
    }
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(Constant.addProduct, formData);
      setLoading(false);
      setTimeout(() => handleCloseIcon(), 2000);
      setOpenSnackBar(true);
      setSnackBarMessage(data.message);
      dispatch(addProductData(data?.data));
    } catch (error: any) {
      setLoading(false);
      setOpenSnackBar(true);
      setSnackBarMessage(error.data.message);
    }
  };

  const onDeleteIcon = (): void => {
    if (selectedFile) {
      setRemoveFile(productData[0]?.fileUrl);
    }
    setSelectedFile("");
  };

  return {
    description,
    noOfProduct,
    productName,
    productTag,
    removeFile,
    selectedFile,
    setDescription,
    setNoOfProduct,
    setProductName,
    setProductTag,
    error,
    validation,
    handleProfileImage,
    selectedFileError,
    loading,
    openSnackBar,
    snackBarMessage,
    handleCloseSnackBar,
    onDeleteIcon,
  };
};

export default editProductSliderController;
