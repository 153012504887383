import { memo } from "react";
import { BoxProps } from "@mui/system";
import { StyledBox } from "./usersContainer.style";

const UsersContainer: React.FC<BoxProps> = (props) => {
  return (
    <StyledBox boxShadow={4} {...props}>
      {props.children}
    </StyledBox>
  );
};

export default memo(UsersContainer);
