import React, { memo } from "react";
import { Add } from "@mui/icons-material";
import { CompanyMachineProcessCard } from "@component";
import EditPortfolioMachine from "./editPortfolioMachine/EditPortfolioMachine";
import portfolioMachineController from "./portfolioMachine.controller";
import { PortfolioMachineProps } from "./portfolioMachine.interface";
import {
  StyledContainer,
  StyledFabButton,
  StyledFabButtonContainer,
  StyledList,
  StyledListContainer,
  StyledText,
} from "./portfolioMachine.style";

const PortfolioMachine: React.FC<PortfolioMachineProps> = ({ userId }) => {
  const {
    machineData,
    handleCloseDrawer,
    handleOpenDrawer,
    index,
    openDrawer,
    removeMachine,
    loading,
    machineId,
  } = portfolioMachineController({ userId });
  return (
    <>
      <StyledFabButtonContainer>
        <StyledFabButton color="primary" onClick={() => handleOpenDrawer()}>
          <Add />
        </StyledFabButton>
      </StyledFabButtonContainer>
      {machineData.length !== 0 ? (
        <StyledListContainer>
          <StyledList>
            {machineData?.map((item) => (
              <CompanyMachineProcessCard
                item={item}
                key={item._id}
                onEditIcon={(id) => handleOpenDrawer(id)}
                onDeleteIcon={(id) => removeMachine(id)}
                loading={loading}
                id={machineId}
              />
            ))}
          </StyledList>
        </StyledListContainer>
      ) : (
        <StyledContainer>
          <StyledText>Nothing to show here</StyledText>
        </StyledContainer>
      )}
      {openDrawer && (
        <EditPortfolioMachine
          openDrawer={openDrawer}
          handleCloseIcon={handleCloseDrawer}
          machineId={index}
          userId={userId}
        />
      )}
    </>
  );
};

export default memo(PortfolioMachine);
