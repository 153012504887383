import React, { memo } from "react";
import {
  FormControl,
  InputLabel,
  InputLabelProps,
  Select,
  SelectProps,
  useTheme,
} from "@mui/material";

interface SelectionProps {
  labelname?: string;
  inputlabelstyle?: any;
}

const Selection: React.FC<SelectProps & SelectionProps & InputLabelProps> = (
  props
) => {
  const theme = useTheme();
  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <InputLabel sx={props.inputlabelstyle}>{props.labelname}</InputLabel>
      <Select label={props.labelname} {...props}>
        {props.children}
      </Select>
    </FormControl>
  );
};

export default memo(Selection);
