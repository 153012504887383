import React, { memo } from "react";
import { useTheme } from "@mui/material";
import { CheckBox } from "@mui/icons-material";
import { companyTypeData } from "./companyUserProfileStepTwo.const";
import { CompanyUserProfileStepTwoProps } from "./companyUserProfileStepTwo.interface";
import companyUserProfileStepTwoController from "./companyUserProfileStepTwo.controller";
import { SnackBar } from "@component";
import {
  StyledButton,
  StyledButtonContainer,
  StyledContainer,
  StyledErrorText,
  StyledInput,
  StyledSelectionButton,
  StyledText,
  StyledTitleText,
} from "./companyUserProfileStepTwo.style";

const CompanyUserProfileStepTwo: React.FC<CompanyUserProfileStepTwoProps> = ({
  handleFinish,
  companyUserId,
}) => {
  const theme = useTheme();
  const {
    gstNumber,
    setGstNumber,
    annualTurnOver,
    setAnnualTurnOver,
    noOfEmployees,
    setNoOfEmployees,
    aboutCompany,
    setAboutCompany,
    websiteLink,
    setWebsiteLink,
    select,
    validation,
    error,
    multipleSelect,
    loading,
    snackBarMessage,
    openSnackBar,
    handleCloseSnackBar,
  } = companyUserProfileStepTwoController({
    handleFinish,
    companyUserId,
  });
  return (
    <StyledContainer>
      <StyledTitleText>Company Type</StyledTitleText>
      {companyTypeData.map((item) => (
        <StyledSelectionButton
          key={item.id}
          onClick={() => multipleSelect(item)}
          startIcon={
            <CheckBox
              color={select.includes(item.type) ? "primary" : "disabled"}
            />
          }
        >
          <StyledText>{item.type}</StyledText>
        </StyledSelectionButton>
      ))}
      <StyledErrorText>{error.select}</StyledErrorText>
      <StyledInput
        variant="outlined"
        placeholder="GST Number"
        InputLabelProps={{ style: { color: theme.palette.common.black } }}
        value={gstNumber}
        onChange={(val) => setGstNumber(val.target.value.slice(0, 23))}
        sx={{ mt: theme.spacing(10) }}
        helperText={error.gstNumber}
      />
      <StyledInput
        variant="outlined"
        placeholder="Annual Turnover"
        InputLabelProps={{ style: { color: theme.palette.common.black } }}
        value={annualTurnOver}
        onChange={(val) => setAnnualTurnOver(val?.target?.value)}
        helperText={error.annualTurnOver}
      />
      <StyledInput
        variant="outlined"
        placeholder="No. of Employees"
        InputLabelProps={{ style: { color: theme.palette.common.black } }}
        value={noOfEmployees}
        onChange={(val) => setNoOfEmployees(val?.target?.value)}
        helperText={error.noOfEmployees}
      />
      <StyledInput
        variant="outlined"
        placeholder="Write about your company"
        InputLabelProps={{ style: { color: theme.palette.common.black } }}
        value={aboutCompany}
        onChange={(val) => setAboutCompany(val?.target?.value)}
        multiline
        rows={5}
        helperText={error.aboutCompany}
      />
      <StyledInput
        variant="outlined"
        placeholder="Website Link"
        InputLabelProps={{ style: { color: theme.palette.common.black } }}
        value={websiteLink}
        onChange={(val) => setWebsiteLink(val?.target?.value)}
        helperText={error.websiteLink}
      />
      <SnackBar
        open={openSnackBar}
        onClose={handleCloseSnackBar}
        onCloseClick={handleCloseSnackBar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        message={snackBarMessage}
      />
      <StyledButtonContainer>
        <StyledButton
          onClick={() => {
            validation();
            handleFinish;
          }}
          loading={loading}
        >
          Finish
        </StyledButton>
      </StyledButtonContainer>
    </StyledContainer>
  );
};

export default memo(CompanyUserProfileStepTwo);
