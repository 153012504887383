import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledInput = styled(TextField)(({ theme }) => ({
  input: {
    color: theme.palette.primary.contrastText,
    borderColor: theme.palette.primary.contrastText,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  overflow: "hidden",
  backgroundColor: "transparent",
  padding: theme.spacing(4, 0),
  transition: theme.transitions.create([
    "border-color",
    "background-color",
    "box-shadow",
  ]),
  "&:hover": {
    backgroundColor: "transparent",
  },
  "&.Mui-focused": {
    backgroundColor: "transparent",
    boxShadow: `0 0 0 0 2px`,
    borderColor: theme.palette.primary.contrastText,
  },
  "&.Mui-error": {
    borderColor: theme.palette.error.main,
  },
  "&.Mui-disabled": {
    backgroundColor: "#F4F6F8",
    input: {
      color: theme.palette.primary.contrastText,
    },
  },
  "& label": {
    color: theme.palette.primary.contrastText,
    paddingLeft: theme.spacing(4),
    fontSize: 18,
    "&.Mui-focused": {
      color: theme.palette.primary.contrastText,
    },
    "&.Mui-error": {
      color: theme.palette.error.main,
    },
    "&.Mui-disabled": {
      color: theme.palette.grey.A100,
    },
  },
}));
