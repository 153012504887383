import { memo } from "react";
import { useTheme } from "@mui/material";
import PieChart from "@component/pieChart/PieChart";
import Text from "@component/text/Text";
import UsersContainer from "@component/usersContainer/UsersContainer";
import { StyledBox } from "./usersChart.style";

const UsersChart: React.FC = () => {
  const theme = useTheme();
  return (
    <UsersContainer>
      <StyledBox>
        <Text variant="h6" flex={1} color={theme.palette.primary.main}>
          Users Chart
        </Text>
      </StyledBox>
      <PieChart />
    </UsersContainer>
  );
};

export default memo(UsersChart);
