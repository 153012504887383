import React from "react";
import { useTheme } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import AllCompanyDetails from "./allCompanyDetails/AllCompanyDetails";
import companyDetailsController from "./companyDetails.controller";
import CompanyPortfolio from "./companyPortfolio/CompanyPortfolio";
import { CompanyProfileDetails } from "@component";
import {
  StyledButton,
  StyledContainer,
  StyledDetailsContainer,
  StyledText,
} from "./companyDetails.style";

const CompanyDetails: React.FC = () => {
  const theme = useTheme();
  const { goBack, companyDetails, loading, companyId, portfolioLoading } =
    companyDetailsController();
  return (
    <StyledContainer>
      <StyledButton
        startIcon={<ChevronLeft color="primary" />}
        variant="text"
        onClick={goBack}
      >
        Back
      </StyledButton>
      <StyledText variant="h5" mt={theme.spacing(10)}>
        Company Profile Details
      </StyledText>
      <StyledDetailsContainer>
        <CompanyProfileDetails
          userDetailsData={companyDetails}
          profileImage={companyDetails[0].profileImage}
          firstName={companyDetails[0].firstName}
          lastName={companyDetails[0].lastName}
          email={companyDetails[0].email}
          isAccountVerified={companyDetails[0].isAccountVerified}
        />
        <AllCompanyDetails loading={loading} userId={companyId} />
      </StyledDetailsContainer>
      <CompanyPortfolio loading={portfolioLoading} userId={companyId} />
    </StyledContainer>
  );
};

export default CompanyDetails;
