import { useState } from "react";
import {
  AddCompanyUserSliderControllerProps,
  AddCompanyUserSliderProps,
} from "./addCompanyUserSlider.interface";
import { stepsData } from "./addCompanyUserSlider.const";

const addCompanyUserSliderController = ({
  handleCloseIcon,
}: AddCompanyUserSliderProps): AddCompanyUserSliderControllerProps => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? stepsData.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const totalSteps = (): number => {
    return stepsData.length;
  };

  const completedSteps = (): number => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = (): boolean => {
    return completedSteps() === totalSteps();
  };

  const handleStep = (step: number): void => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
    if (allStepsCompleted()) {
      handleCloseIcon();
      setActiveStep(0);
      setCompleted({});
    }
  };

  const resetStepper = (): void => {
    setActiveStep(0);
    setCompleted({});
  };

  return {
    activeStep,
    allStepsCompleted,
    completedSteps,
    handleComplete,
    handleNext,
    handleStep,
    isLastStep,
    totalSteps,
    completed,
    resetStepper,
  };
};

export default addCompanyUserSliderController;
