import { useState } from "react";
import { AllCompanyDetailsControllerProps } from "./allCompanyDetails.interface";

const allCompanyDetailsController = (): AllCompanyDetailsControllerProps => {
  const [select, setSelect] = useState<number>(1);

  return { select, setSelect };
};

export default allCompanyDetailsController;
