import React from "react";
import { Grid, useTheme } from "@mui/material";
import { SupportCard } from "@component";
import { supportData } from "./support.const";
import { StyledBox } from "./support.style";

const Support: React.FC = () => {
  const theme = useTheme();
  return (
    <StyledBox>
      <Grid container spacing={theme.spacing(10)}>
        {supportData.map((item) => (
          <Grid item xs={12} sm={12} md={6} lg={4} key={item.id}>
            <SupportCard item={item} />
          </Grid>
        ))}
      </Grid>
    </StyledBox>
  );
};

export default Support;
