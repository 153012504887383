import React, { memo } from "react";
import { useTheme } from "@mui/material";
import addEditZoneSliderController from "./addEditZoneSlider.controller";
import { AddEditZoneSliderProps } from "./addEditZoneSlider.interface";
import { Slider, SnackBar } from "@component";
import {
  StyledButton,
  StyledContainer,
  StyledInput,
  StyledText,
} from "./addEditZoneSlider.style";

const AddEditZoneSlider: React.FC<AddEditZoneSliderProps> = ({
  openDrawer,
  handleCloseIcon,
  zoneId,
}) => {
  const theme = useTheme();
  const {
    zone,
    setZone,
    zoneError,
    loading,
    validation,
    handleCloseSnackBar,
    openSnackBar,
    snackBarMessage,
  } = addEditZoneSliderController({
    openDrawer,
    handleCloseIcon,
    zoneId,
  });
  return (
    <Slider
      title={zoneId ? "Edit Zone" : "Add Zone"}
      openDrawer={openDrawer}
      handleCloseIcon={handleCloseIcon}
    >
      <StyledContainer>
        <StyledText>Zone</StyledText>
        <StyledInput
          variant="outlined"
          placeholder="Zone"
          InputLabelProps={{ style: { color: theme.palette.common.black } }}
          value={zone}
          onChange={(val) => setZone(val?.target?.value)}
          helperText={zoneError}
        />
        <StyledButton
          loading={loading}
          onClick={validation}
          progressColor="info"
        >
          {zoneId ? "Edit" : " Add"}
        </StyledButton>
        <SnackBar
          open={openSnackBar}
          onClose={handleCloseSnackBar}
          onCloseClick={handleCloseSnackBar}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          message={snackBarMessage}
        />
      </StyledContainer>
    </Slider>
  );
};

export default memo(AddEditZoneSlider);
