import React, { memo } from "react";
import { useTheme } from "@mui/material";
import { colors } from "./pieChart.const";
import dashboardController from "@pages/home/dashboard/dashboard.controller";
import { Pie, Cell, PieChart as PieChartRecharts } from "recharts";
import { PieChartDataItemProps } from "./pieChart.interface";
import Text from "@component/text/Text";
import {
  PieChartDataListContainer,
  StyledBox,
  StyledColoredBackground,
  StyledDataRenderBoxContainer,
  StyledDataRenderContainer,
  StyledPieChartContainer,
  StyledWhiteBackground,
} from "./pieChart.style";
import CircularProgress from "@component/circularProgress/CircularProgress";

const PieChart: React.FC = () => {
  const theme = useTheme();
  const { pieChartData } = dashboardController();

  const PieChartDataRender: React.FC<PieChartDataItemProps> = (props) => {
    return (
      <StyledDataRenderContainer>
        <StyledDataRenderBoxContainer>
          <StyledWhiteBackground>
            <StyledColoredBackground
              sx={{
                background: props.item.color,
              }}
            />
          </StyledWhiteBackground>
          <Text ml={5} color={theme.palette.primary.main}>
            {props.item.name}
          </Text>
        </StyledDataRenderBoxContainer>
        <Text ml={15} color={theme.palette.primary.main}>
          {!props.item.value && props.item.value !== 0 ? (
            <CircularProgress size={20} color="primary" />
          ) : (
            `${props.item.value}%`
          )}
        </Text>
      </StyledDataRenderContainer>
    );
  };
  return (
    <StyledPieChartContainer>
      <PieChartRecharts width={210} height={210}>
        <Pie
          data={pieChartData}
          cx={100}
          cy={100}
          innerRadius={50}
          outerRadius={100}
          dataKey="value"
        >
          {pieChartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
      </PieChartRecharts>
      <StyledBox>
        <PieChartDataListContainer>
          {pieChartData.map((item) => (
            <PieChartDataRender key={item.id} item={item} />
          ))}
        </PieChartDataListContainer>
      </StyledBox>
    </StyledPieChartContainer>
  );
};

export default memo(PieChart);
