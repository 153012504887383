import { Button, Container, Input } from "@component";
import { Box, styled } from "@mui/material";

export const StyledLoginContainer = styled(Container)(({ theme }) => ({
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
}));

export const StyledCenterBox = styled(Box)(({ theme }) => ({
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  flexDirection: "column",
  width: "400px",
  padding: theme.spacing(16, 0),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(16, 10),
  },
}));

export const StyledInput = styled(Input)(({ theme }) => ({
  marginTop: theme.spacing(4),
  input: {
    color: theme.palette.common.black,
  },
  "& .MuiFormHelperText-root": {
    color: theme.palette.error.main,
  },
}));

export const StyledLoginButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(16),
  height: 40,
}));

export const StyledLogo = styled("img")(({ theme }) => ({
  height: 150,
}));
