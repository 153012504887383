import type from "./type";

const editBasicDetails = (payload: any) => ({
  type: type.editBasicDetails,
  payload,
});

const editQualifications = (payload: any) => ({
  type: type.editQualifications,
  payload,
});

const removeUserData = () => ({
  type: type.removeUserData,
});

export { editBasicDetails, editQualifications, removeUserData };
