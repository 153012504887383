import React, { memo } from "react";
import { Delete } from "@mui/icons-material";
import editMachineSliderController from "./editMachineSlider.controller";
import { EditMachineSliderProps } from "./editMachineSlider.interface";
import { IconButton, useTheme } from "@mui/material";
import { machiningData } from "./editMachineSlider.const";
import { MultipleSelectionDropDown, Slider, SnackBar } from "@component";
import {
  StyledButton,
  StyledButtonContainer,
  StyledChooseButton,
  StyledChooseFileContainer,
  StyledContainer,
  StyledErrorText,
  StyledFileInput,
  StyledFileText,
  StyledInput,
  StyledLabel,
  StyledText,
} from "./editMachineSlider.style";

const EditMachineSlider: React.FC<EditMachineSliderProps> = ({
  openDrawer,
  handleCloseIcon,
  machineId,
  userId,
}) => {
  const theme = useTheme();
  const {
    description,
    error,
    handleSelectedFile,
    noOfMachine,
    selectedFile,
    selectedFileError,
    setDescription,
    setNoOfMachine,
    validation,
    handleCloseSnackBar,
    loading,
    openSnackBar,
    snackBarMessage,
    onDeleteIcon,
    selectedMachining,
    setSelectMachiningMainCategory,
    setSelectMachiningSubMainCategory,
    setSelectedMachining,
    selectedMachine,
  } = editMachineSliderController({
    handleCloseIcon,
    machineId,
    openDrawer,
    userId,
  });
  return (
    <Slider
      openDrawer={openDrawer}
      handleCloseIcon={() => {
        handleCloseIcon();
      }}
      title={machineId ? "Edit Machine" : "Add Machine"}
    >
      <StyledContainer>
        <MultipleSelectionDropDown
          data={machiningData}
          placeholder={selectedMachine}
          onSelectValue={(
            details: any,
            mainCategory: any,
            subMainCategory: any
          ) => {
            setSelectMachiningMainCategory(mainCategory);
            setSelectMachiningSubMainCategory(subMainCategory);
            setSelectedMachining(details);
          }}
          values={selectedMachining}
        />
        <StyledText>Job Description</StyledText>
        <StyledInput
          variant="outlined"
          placeholder="Job Description"
          InputLabelProps={{ style: { color: theme.palette.common.black } }}
          value={description}
          onChange={(val) => setDescription(val?.target?.value)}
          helperText={error.descriptionError}
        />
        <StyledText>No. of Machine</StyledText>
        <StyledInput
          variant="outlined"
          placeholder="No. of Machine"
          InputLabelProps={{ style: { color: theme.palette.common.black } }}
          value={noOfMachine}
          onChange={(val) => setNoOfMachine(val?.target?.value)}
          helperText={error.noOfMachineError}
        />
        <StyledText>Upload (Photos, videos, catalogue)</StyledText>
        <StyledFileInput
          id="selectPortfolio"
          type="file"
          onChange={handleSelectedFile}
        />
        <StyledChooseFileContainer>
          <StyledLabel htmlFor="selectPortfolio">
            <StyledChooseButton>
              {selectedFile ? "Selected file" : "Choose file"}
            </StyledChooseButton>
          </StyledLabel>
          {selectedFile && (
            <StyledFileText>
              {selectedFile.name ?? selectedFile}
              <IconButton onClick={onDeleteIcon}>
                <Delete color="secondary" />
              </IconButton>
            </StyledFileText>
          )}
        </StyledChooseFileContainer>
        <StyledErrorText>{selectedFileError}</StyledErrorText>
        <SnackBar
          open={openSnackBar}
          onClose={handleCloseSnackBar}
          onCloseClick={handleCloseSnackBar}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          message={snackBarMessage}
        />
        <StyledButtonContainer>
          <StyledButton
            loading={loading}
            onClick={validation}
            progressColor="info"
          >
            {machineId ? "Edit" : "Add"}
          </StyledButton>
        </StyledButtonContainer>
      </StyledContainer>
    </Slider>
  );
};

export default memo(EditMachineSlider);
