import { Box, Fab, List, styled } from "@mui/material";
import { Text } from "@component";

export const StyledContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "70vh",
}));

export const StyledText = styled(Text)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 22,
}));

export const StyledList = styled(List)(({ theme }) => ({
  maxHeight: "90%",
  overflow: "auto",
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: "-15px",
  overflowY: "scroll",
}));

export const StyledListContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "90%",
  overflow: "hidden",
  position: "relative",
  marginTop: theme.spacing(10),
}));

export const StyledFabButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "60vh",
  width: "73%",
  position: "absolute",
  justifyContent: "flex-end",
  alignItems: "flex-end",
  [theme.breakpoints.between("md", "lg")]: {
    width: "65%",
  },
  [theme.breakpoints.down("xs")]: {
    width: "70%",
  },
}));

export const StyledFabButton = styled(Fab)(({ theme }) => ({}));
