import { useCallback, useEffect, useMemo, useState } from "react";
import { SelectChangeEvent } from "@mui/material";
import {
  AddExpertiseObject,
  QualificationProps,
  UserProfileStepOneControllerProps,
  UserProfileStepOneProps,
  ValidationProps,
} from "./userProfileStepOne.interface";
import { axiosInstance } from "@services/api/api";
import { checkNumeric, checkString } from "@utils/validation/validation";
import { Constant } from "@services/constant";
import { editQualifications } from "@services/redux/addPersonUser/action";
import { User } from "@services/redux/userList/interface";
import { useAppDispatch, useAppSelector } from "@services/redux/controller";
import validationMessage from "@utils/validation/validationMessage";

const userProfileStepOneController = ({
  handleNextButton,
  userId,
}: UserProfileStepOneProps): UserProfileStepOneControllerProps => {
  const [selectQualification, setSelectQualification] = useState<string>();
  const [qualificationsData, setQualificationsData] = useState<
    QualificationProps[]
  >([]);
  const [courseName, setCourseName] = useState<string>();
  const [expertiseAndExperience, setExpertiseAndExperience] = useState<
    AddExpertiseObject[]
  >([
    {
      selectMainCategory: null,
      selectSubMainCategory: null,
      selectedCategory: null,
      expertise: null,
      experience: null,
      experienceError: null,
      expertiseError: null,
    },
  ]);
  const [companyName, setCompanyName] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ValidationProps>({
    selectQualification: null,
    courseName: null,
    companyName: null,
  });

  const { userList } = useAppSelector((state) => state.userListReducer);
  const { editQualificationsData } = useAppSelector(
    (state) => state.editPersonUserReducer
  );
  const dispatch = useAppDispatch();
  let editData: User[] = userList.filter((item: User) => item._id === userId);

  const placeholderText = (item: any) => {
    return item?.selectSubMainCategory
      ? `${item?.selectMainCategory?.name}-${item?.selectSubMainCategory?.name}-${item?.selectedCategory?.name}`
      : item?.selectedCategory?.name
      ? `${
          item?.selectMainCategory
            ? item?.selectMainCategory?.name +
              "-" +
              item?.selectedCategory?.name
            : item?.selectedCategory?.name
        }`
      : "Select Expertise";
  };
  // expertiseAndExperience[0].selectedCategory({
  //   id: 1,
  //   name: editData[0].qualificationData,
  // });
  useEffect(() => {
    if (userId) {
      setCompanyName(editData[0].currentlyWorkingCompanyName);
      setCourseName(editData[0].degree);
      let temp: AddExpertiseObject[] = [];
      if (editData[0].qualificationData.length != 0) {
        const temp = editData[0].qualificationData?.map((item: any) => {
          return {
            selectMainCategory: null,
            selectSubMainCategory: null,
            selectedCategory: { name: item.expertise },
            experience: item.experience,
            expertise: { name: item.expertise },
            expertiseError: undefined,
            experienceError: undefined,
            _id: item._id,
          };
        });
        setExpertiseAndExperience(temp);
      } else {
        temp.push({
          experience: undefined,
          expertise: undefined,
        });
        setExpertiseAndExperience(temp);
      }
    }
    getQualificationsData();
  }, []);

  const expertiseAndExperienceData = useMemo(() => {
    return expertiseAndExperience.map((item) => {
      if (item._id) {
        return {
          _id: item._id,
          expertise: placeholderText(item),
          experience: item.experience,
        };
      } else {
        return {
          expertise: placeholderText(item),
          experience: item.experience,
        };
      }
    });
  }, [expertiseAndExperience]);

  const getQualificationsData = async () => {
    /* API call for getting qualifications data */
    try {
      const { data } = await axiosInstance.get(Constant.getQualifications);
      setQualificationsData(data.data);
    } catch (error) {}
  };

  // const qualificationData = expertiseAndExperience.map((item) => ({
  //   expertise: item.expertise,
  //   experience: item.experience,
  // }));

  const handleSelectionChange = (event: SelectChangeEvent): void => {
    setSelectQualification(event.target.value);
  };

  const addMoreExpertiseHandler = useCallback(() => {
    expertiseAndExperience.push({
      selectMainCategory: null,
      selectSubMainCategory: null,
      selectedCategory: null,
      expertise: null,
      experience: null,
      experienceError: null,
      expertiseError: null,
    });
    setExpertiseAndExperience([...expertiseAndExperience]);
  }, [expertiseAndExperience]);

  const selectExpertise = useCallback(
    (index: number, details: any, mainCategory: any, subMainCategory: any) => {
      expertiseAndExperience[index].selectedCategory = details;
      expertiseAndExperience[index].selectMainCategory = mainCategory;
      expertiseAndExperience[index].selectSubMainCategory = subMainCategory;
      expertiseAndExperience[index].expertise = details;
      setExpertiseAndExperience([...expertiseAndExperience]);
    },
    [expertiseAndExperience]
  );

  const expertiseAndExperienceInputHandler = useCallback(
    (value: string, index: number) => {
      expertiseAndExperience[index]["experience"] = value;
      setExpertiseAndExperience([...expertiseAndExperience]);
    },
    [expertiseAndExperience]
  );

  const saveEditedData = (): void => {
    let temp = { ...editQualificationsData };
    temp.qualification = selectQualification;
    temp.degree = courseName;
    temp.qualificationData = expertiseAndExperienceData;
    temp.currentlyWorkingCompanyName = companyName;
    dispatch(editQualifications(temp));
  };

  const addedUserId: string = localStorage.getItem("addUserId");

  const formData = {
    id: addedUserId,
    qualification: selectQualification && selectQualification,
    degree: courseName && courseName,
    qualificationData: JSON.stringify(expertiseAndExperienceData),
    // qualificationData[0]?.expertise !== null
    //   ? JSON.stringify(qualificationData)
    //   : JSON.stringify([]),
    currentlyWorkingCompanyName: companyName && companyName,
  };

  const userRegistrationStepTwo = async () => {
    /* API call for user registration step two */
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(
        Constant.personalProfile,
        formData
      );
      setLoading(false);
      handleNextButton();
    } catch (error) {
      setLoading(false);
    }
  };

  const validation = (): void => {
    /* Validation for qualification data */
    let isValidate = true;
    // if (selectQualification) {
    //   isValidate = false;
    //   error.selectQualification =
    //     validationMessage.validationSelectQualification;
    // } else {
    //   error.selectQualification = null;
    // }
    // expertiseAndExperience.map((item) => {
    //   if (item.expertise) {
    //     if (!checkString(item.expertise)) {
    //       isValidate = false;
    //       item.expertiseError =
    //         validationMessage.validationValidExpertiseMessage;
    //     } else {
    //       item.expertiseError = null;
    //     }
    //   }
    //   if (item.experience) {
    //     if (!checkNumeric(item.experience)) {
    //       isValidate = false;
    //       item.experienceError =
    //         validationMessage.validationValidExperienceMessage;
    //     } else {
    //       item.experienceError = null;
    //     }
    //   }
    // });
    if (courseName) {
      if (!checkString(courseName)) {
        isValidate = false;
        error.courseName = validationMessage.validationValidCourseName;
      } else {
        error.courseName = null;
      }
    }
    if (companyName) {
      if (!checkString(companyName)) {
        isValidate = false;
        error.companyName = validationMessage.validationValidCompanyName;
      } else {
        error.companyName = null;
      }
    }
    setExpertiseAndExperience([...expertiseAndExperience]);
    setError({ ...error });
    if (isValidate) {
      if (!userId) {
        userRegistrationStepTwo();
      } else {
        saveEditedData();
        handleNextButton();
      }
    }
  };

  return {
    selectQualification,
    handleSelectionChange,
    courseName,
    setCourseName,
    companyName,
    setCompanyName,
    expertiseAndExperience,
    addMoreExpertiseHandler,
    expertiseAndExperienceInputHandler,
    validation,
    error,
    loading,
    qualificationsData,
    placeholderText,
    selectExpertise,
    editData,
  };
};

export default userProfileStepOneController;
