import { useEffect, useMemo, useState } from "react";
import { axiosInstance } from "@services/api/api";
import { Constant } from "@services/constant";
import {
  ProductDataProps,
  ProductsControllerProps,
  ProductsProps,
} from "./products.interface";
import { removeProductData } from "@services/redux/allCompanyDetails/action";
import { useAppDispatch, useAppSelector } from "@services/redux/controller";

const productsController = ({
  userId,
}: ProductsProps): ProductsControllerProps => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [index, setIndex] = useState<string | null>(null);
  const [productId, setProductId] = useState<string>("");
  const { product } = useAppSelector((state) => state.allCompanyDetailsReducer);
  const [productData, setProductData] = useState<ProductDataProps[]>([
    {
      _id: null,
      productName: null,
      description: null,
      fileUrl: null,
      tags: null,
    },
  ]);
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (product) {
      setProductData(
        product
          ?.map((item: ProductDataProps) => ({
            _id: item._id,
            productName: item.productName,
            description: item.description,
            fileUrl: item.fileUrl,
            tags: item.tags,
          }))
          .reverse()
      );
    }
  }, [product]);

  const handleCloseDrawer = (): void => {
    setOpenDrawer(false);
  };

  const handleOpenDrawer = (id?: string | null): void => {
    setOpenDrawer(true);
    setIndex(id);
  };

  const removeProduct = async (productId: string | null) => {
    setProductId(productId);
    /* API call for removing product */
    const formData = new URLSearchParams();
    formData.append("userId", userId);
    formData.append("type", "0");
    formData.append("productId", productId);
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(
        Constant.RemoveProductJobworkJobVacancy,
        formData.toString()
      );
      setLoading(false);
      dispatch(removeProductData(data.data));
    } catch (error) {
      setLoading(false);
    }
  };

  return {
    productData,
    handleCloseDrawer,
    openDrawer,
    handleOpenDrawer,
    index,
    removeProduct,
    loading,
    productId,
  };
};

export default productsController;
