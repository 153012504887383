import { useState } from "react";
import { CompanyPortfolioControllerProps } from "./companyPortfolio.interface";

const companyPortfolioController = (): CompanyPortfolioControllerProps => {
  const [select, setSelect] = useState<number>(1);

  return { select, setSelect };
};

export default companyPortfolioController;
