import { useEffect, useState } from "react";
import { AboutUsControllerProps } from "./aboutUs.interface";
import { axiosInstance } from "@services/api/api";
import { Constant } from "@services/constant";

const aboutUsController = (): AboutUsControllerProps => {
  const [aboutUs, setAboutUs] = useState<string>("Write something here!");
  const [loading, setLoading] = useState<boolean>(false);
  const [getDataLoading, setGetDataLoading] = useState<boolean>(false);
  const [aboutUsId, setAboutUsId] = useState<string>("");
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string>("");

  useEffect(() => {
    getAboutUs();
  }, []);

  const handleAboutUs = (e: any, editor: any): void => {
    const data = editor.getData();
    setAboutUs(data);
  };

  const handleSnackBar = (): void => {
    setOpenSnackBar(false);
  };

  const getAboutUs = async () => {
    /* API call for getting about us data */
    try {
      setGetDataLoading(true);
      const { data } = await axiosInstance.get(Constant.getAboutUs);
      setGetDataLoading(false);
      setAboutUs(data.data.description);
      setAboutUsId(data.data._id);
    } catch (error: any) {
      setGetDataLoading(false);
    }
  };

  const addOrEditAboutUs = async () => {
    /* API call for adding or editing about us */
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(Constant.addEditAboutUs, {
        description: aboutUs,
        id: aboutUsId,
      });
      setOpenSnackBar(true);
      setSnackBarMessage(data.message);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setOpenSnackBar(true);
      setSnackBarMessage(error.message);
    }
  };

  return {
    aboutUs,
    handleAboutUs,
    loading,
    addOrEditAboutUs,
    aboutUsId,
    getDataLoading,
    openSnackBar,
    snackBarMessage,
    handleSnackBar,
  };
};

export default aboutUsController;
