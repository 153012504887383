export const processesData: object[] = [
  {
    name: "None",
    id: 121,
  },
  {
    id: 1,
    name: "Casting",
    children: [
      {
        id: 2,
        name: "Aluminum",
        children: [
          {
            id: 3,
            name: "sand",
          },
          {
            id: 4,
            name: "Die",
          },
        ],
      },
      {
        id: 5,
        name: "Steel",
        children: [
          {
            id: 6,
            name: "sand",
          },
          {
            id: 7,
            name: "Centrifugal",
          },
          {
            id: 8,
            name: "Investment",
          },
          {
            id: 9,
            name: "Shell",
          },
          {
            id: 10,
            name: "Plaster mould",
          },
          {
            id: 11,
            name: "Ceramic mould",
          },
          {
            id: 12,
            name: "Press Die",
          },
          {
            id: 13,
            name: "Continuous",
          },
        ],
      },
    ],
  },

  {
    id: 14,
    name: "Fabrication",
  },

  {
    id: 15,
    name: "Lapping",
  },
  {
    id: 16,
    name: "Profile Projector",
  },
  {
    id: 17,
    name: "CMM",
  },
  {
    id: 18,
    name: "Bending",
    children: [
      {
        id: 19,
        name: "CNC",
      },
      {
        id: 20,
        name: "Conventional",
      },
      {
        id: 21,
        name: "Sheet",
      },
      {
        id: 22,
        name: "Pipe",
      },
    ],
  },
  {
    id: 23,
    name: "Hydraulic Press",
    children: [
      {
        id: 24,
        name: "CNC",
      },
      {
        id: 25,
        name: "Conventional",
      },
    ],
  },

  {
    id: 26,
    name: "Nitriding",
    children: [
      {
        id: 27,
        name: "Liquid",
      },
      {
        id: 28,
        name: "Gas",
      },
      {
        id: 30,
        name: "Plasma",
      },
    ],
  },

  {
    id: 31,
    name: "Welding",
    children: [
      {
        id: 32,
        name: "Argen",
      },
      {
        id: 33,
        name: "Gas",
      },
      {
        id: 34,
        name: "Arc",
      },
      {
        id: 35,
        name: "Spot",
      },
      {
        id: 36,
        name: "Mig",
      },
      {
        id: 37,
        name: "Plasma",
      },
    ],
  },

  {
    id: 38,
    name: "Hardening",
    children: [
      {
        id: 39,
        name: "Through Hardening",
      },
      {
        id: 40,
        name: "Case Hardening",
      },
      {
        id: 41,
        name: "Quenching",
      },
      {
        id: 42,
        name: "Tempering",
      },
      {
        id: 43,
        name: "Gas carburising",
      },
      {
        id: 44,
        name: "Liquid Carburising",
      },
      {
        id: 45,
        name: "Solid Carburising",
      },
      {
        id: 46,
        name: "Induction Hardening",
      },
      {
        id: 47,
        name: "Vacuum hardening",
      },
    ],
  },

  {
    id: 48,
    name: "Annealing",
  },
  {
    id: 49,
    name: "Stabizing",
  },
  {
    id: 50,
    name: "Others",
  },
];
