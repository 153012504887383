import { ReportDataProps } from "./reports.interface";

export const reportData: ReportDataProps[] = [
  {
    id: 1,
    profileImage: "/broken-image.jpg",
    userName: "Shannon Carter",
    date: "22 dec 2022",
    reason: "Inappropriate",
    reporter: "Laura Dern",
    resolved: true,
  },
  {
    id: 2,
    profileImage: "/broken-image.jpg",
    userName: "Shannon Carter",
    date: "22 dec 2022",
    reason: "Inappropriate",
    reporter: "Laura Dern",
    resolved: false,
  },
  {
    id: 3,
    profileImage: "/broken-image.jpg",
    userName: "Shannon Carter",
    date: "22 dec 2022",
    reason: "Inappropriate",
    reporter: "Laura Dern",
    resolved: true,
  },
  {
    id: 4,
    profileImage: "/broken-image.jpg",
    userName: "Shannon Carter",
    date: "22 dec 2022",
    reason: "Inappropriate",
    reporter: "Laura Dern",
    resolved: false,
  },
  {
    id: 5,
    profileImage: "/broken-image.jpg",
    userName: "Shannon Carter",
    date: "22 dec 2022",
    reason: "Inappropriate",
    reporter: "Laura Dern",
    resolved: true,
  },
  {
    id: 6,
    profileImage: "/broken-image.jpg",
    userName: "Shannon Carter",
    date: "22 dec 2022",
    reason: "Inappropriate",
    reporter: "Laura Dern",
    resolved: false,
  },
  {
    id: 7,
    profileImage: "/broken-image.jpg",
    userName: "Shannon Carter",
    date: "22 dec 2022",
    reason: "Inappropriate",
    reporter: "Laura Dern",
    resolved: false,
  },
];
