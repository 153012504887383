import { combineReducers } from "redux";
import editPersonUserReducer from "./addPersonUser/reducer";
import editCompanyUserReducer from "./addCompanyUser/reducer";
import qualificationReducer from "./qualifications/reducer";
import userReducer from "./user/reducer";
import userListReducer from "./userList/reducer";
import zoneReducer from "./zone/reducer";
import allCompanyDetailsReducer from "./allCompanyDetails/reducer";
import companyPortfolioReducer from "./companyPortfolio/reducer";

const rootReducer = combineReducers({
  userReducer,
  userListReducer,
  qualificationReducer,
  zoneReducer,
  editPersonUserReducer,
  editCompanyUserReducer,
  allCompanyDetailsReducer,
  companyPortfolioReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
