import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ForgotPasswordControllerProps } from "./forgotPassword.interface";

const forgotPasswordController = (): ForgotPasswordControllerProps => {
  const navigation = useNavigate();
  const [email, setEmail] = useState<string>();

  const onBack = (): void => {
    navigation(-1);
  };
  return { email, setEmail, onBack };
};

export default forgotPasswordController;
