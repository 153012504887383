import { useEffect, useState } from "react";
import {
  AddUserControllerProps,
  AddUserProps,
  ValidationProps,
} from "./addUser.interface";
import { axiosInstance } from "@services/api/api";
import {
  checkEmail,
  checkMobileNumber,
  checkPassword,
  checkString,
} from "@utils/validation/validation";
import { Constant } from "@services/constant";
import { User } from "@services/redux/userList/interface";
import { useAppDispatch, useAppSelector } from "@services/redux/controller";
import validationMessage from "@utils/validation/validationMessage";
import { editBasicDetails } from "@services/redux/addPersonUser/action";

const addUserController = ({
  handleNextButton,
  userId,
}: AddUserProps): AddUserControllerProps => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [mobileNo, setMobileNo] = useState<number | string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ValidationProps>({
    firstName: null,
    lastName: null,
    email: null,
    mobileNo: null,
    password: null,
  });

  const { userList } = useAppSelector((state) => state.userListReducer);
  const { editBasicDetailsData } = useAppSelector(
    (state) => state.editPersonUserReducer
  );
  const dispatch = useAppDispatch();
  let editData: User[] = userList.filter((item: User) => item._id === userId);

  useEffect(() => {
    if (userId) {
      setFirstName(editData[0]?.firstName);
      setLastName(editData[0]?.lastName);
      setEmail(editData[0]?.email);
      setMobileNo(editData[0]?.mobileNumber);
    }
  }, []);

  const saveEditedData = (): void => {
    let temp = { ...editBasicDetailsData };
    temp.firstName = firstName;
    temp.lastName = lastName;
    temp.email = email;
    temp.mobileNumber = mobileNo;
    dispatch(editBasicDetails(temp));
  };

  let formData = {
    firstName: firstName,
    lastName: lastName,
    email: email,
    mobileNumber: mobileNo,
    password: password,
    registerType: "1",
  };

  const registerUserStepOne = async () => {
    /* API call for user register step one */
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(
        Constant.companyRegisterStep1,
        formData
      );
      setLoading(false);
      localStorage.setItem("addUserId", data.data[0]._id);
      handleNextButton();
    } catch (error) {
      setLoading(false);
    }
  };

  const validation = (): void => {
    /* Validation for basic user details */
    let isValidate = true;
    if (!firstName) {
      isValidate = false;
      error.firstName = validationMessage.validationFirstName;
    } else if (!checkString(firstName)) {
      isValidate = false;
      error.firstName = validationMessage.validationValidFirstName;
    } else {
      error.firstName = null;
    }
    if (!lastName) {
      isValidate = false;
      error.lastName = validationMessage.validationLastName;
    } else if (!checkString(lastName)) {
      isValidate = false;
      error.lastName = validationMessage.validationValidLastName;
    } else {
      error.lastName = null;
    }
    if (!email) {
      isValidate = false;
      error.email = validationMessage.validationEmail;
    } else if (!checkEmail(email)) {
      isValidate = false;
      error.email = validationMessage.validationValidEmail;
    } else {
      error.email = null;
    }
    if (!mobileNo) {
      isValidate = false;
      error.mobileNo = validationMessage.validationMobileNo;
    } else if (!checkMobileNumber(mobileNo.toString())) {
      isValidate = false;
      error.mobileNo = validationMessage.validationValidMobileNo;
    } else {
      error.mobileNo = null;
    }
    if (!userId) {
      if (!password) {
        isValidate = false;
        error.password = validationMessage.validationPassword;
      } else if (!checkPassword(password)) {
        isValidate = false;
        error.password = validationMessage.validationValidPassword;
      } else {
        error.password = null;
      }
    }
    setError({ ...error });
    if (isValidate) {
      if (!userId) {
        registerUserStepOne();
      } else {
        saveEditedData();
        handleNextButton();
      }
    }
  };

  return {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    mobileNo,
    setMobileNo,
    password,
    setPassword,
    error,
    validation,
    loading,
  };
};

export default addUserController;
