import { Box, styled } from "@mui/material";

export const StyledDataRenderContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

export const StyledDataRenderBoxContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  flex: 1,
}));

export const StyledWhiteBackground = styled(Box)(({ theme }) => ({
  display: "flex",
  height: theme.spacing(8),
  width: theme.spacing(8),
  borderRadius: theme.spacing(4),
  background: theme.palette.common.white,
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledColoredBackground = styled(Box)(({ theme }) => ({
  height: theme.spacing(6),
  width: theme.spacing(6),
  borderRadius: theme.spacing(4),
  alignSelf: "center",
}));

export const StyledPieChartContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginTop: theme.spacing(16),
  marginLeft: theme.spacing(6),
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },
}));

export const PieChartDataListContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    marginTop: theme.spacing(8),
  },
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  alignItems: "flex-end",
}));
