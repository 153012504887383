import { CompanyTypeDataProps } from "./companyUserProfileStepTwo.interface";

export const companyTypeData: CompanyTypeDataProps[] = [
  {
    id: 1,
    type: "Manufacturer",
  },
  {
    id: 2,
    type: "Supplier",
  },
  {
    id: 3,
    type: "Trader",
  },
];
