import { Button } from "@component";
import { Box, styled } from "@mui/material";

export const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6, 14),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(0, 6),
  },
}));

export const StyledButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  marginTop: theme.spacing(6),
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  width: "20%",
  height: 45,
  marginRight: theme.spacing(8),
  [theme.breakpoints.down("sm")]: {
    marginBottom: theme.spacing(4),
  },
  [theme.breakpoints.down("lg")]: {
    width: "30%",
  },
}));

export const StyledProgressContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "50vh",
}));
