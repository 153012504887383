import React from "react";
import allUsersController from "./allUsers.controller";
import { CircularProgress, Table } from "@component";
import { tableCellData } from "./allUsers.const";
import {
  StyledBox,
  StyledContainer,
  StyledProgressContainer,
  StyledSearchInput,
  StyledText,
} from "../company/company.style";

const AllUsers: React.FC = () => {
  const { loading, searchValue, allUsersData, filterData, filter } =
    allUsersController();
  return (
    <StyledContainer>
      <StyledBox>
        <StyledText variant="h4">All Users</StyledText>
        <StyledSearchInput
          placeholder="Type something..."
          value={searchValue}
          onChange={(val) => filter(val?.target.value)}
        />
      </StyledBox>
      {loading ? (
        <StyledProgressContainer>
          <CircularProgress size={40} color={"primary"} />
        </StyledProgressContainer>
      ) : (
        <Table
          tableData={filterData == null ? allUsersData : filterData}
          tableCellData={tableCellData}
        />
      )}
    </StyledContainer>
  );
};

export default AllUsers;
