import React from "react";
import { Add } from "@mui/icons-material";
import AddQualificationsSlider from "./addQualificationsSlider/AddQualificationsSlider";
import { CircularProgress, Table } from "@component";
import qualificationsController from "./qualifications.controller";
import { tableCellData } from "./qualifications.const";
import {
  StyledBox,
  StyledButton,
  StyledContainer,
  StyledProgressContainer,
  StyledSearchInput,
  StyledText,
} from "./qualifications.style";

const Qualifications: React.FC = () => {
  const {
    openDrawer,
    handleOpen,
    handleClose,
    index,
    loading,
    qualification,
    editQualificationStatus,
    filter,
    filterData,
    searchValue,
  } = qualificationsController();
  return (
    <StyledContainer>
      <StyledBox>
        <StyledText variant="h4">Qualifications</StyledText>
        <StyledSearchInput
          placeholder="Type something..."
          value={searchValue}
          onChange={(val) => filter(val?.target.value)}
        />
        <StyledButton
          onClick={() => handleOpen()}
          startIcon={<Add fontSize="large" />}
        >
          Add Qualification
        </StyledButton>
        <AddQualificationsSlider
          openDrawer={openDrawer}
          handleCloseIcon={handleClose}
          qualificationId={index}
        />
      </StyledBox>
      {loading ? (
        <StyledProgressContainer>
          <CircularProgress size={40} color={"primary"} />
        </StyledProgressContainer>
      ) : (
        <Table
          tableData={filterData === null ? qualification : filterData}
          tableCellData={tableCellData}
          isEditButton
          handleEdit={(id) => handleOpen(id)}
          handleSwitch={(id, check) => editQualificationStatus(id, check)}
        />
      )}
    </StyledContainer>
  );
};

export default Qualifications;
