import { TableCellDataProps } from "@component/customTable/table.interface";

export const tableCellData: TableCellDataProps[] = [
  {
    id: 1,
    title: "",
  },
  {
    id: 2,
    title: "Name",
  },
  {
    id: 3,
    title: "Email",
  },
  {
    id: 4,
    title: "Mobile Number",
  },
  {
    id: 5,
    title: "Company Name",
  },
  {
    id: 6,
    title: "Company Details",
  },
  {
    id: 8,
    title: "",
  },
  {
    id: 9,
    title: "",
  },
];
