import React, { memo } from "react";
import { Add } from "@mui/icons-material";
import { CompanyVacancyCard } from "@component";
import EditVacancySlider from "./editVacancySlider/EditVacancySlider";
import vacancyController from "./vacancy.controller";
import { VacancyProps } from "./vacancy.interface";
import { StyledContainer, StyledText } from "../process/process.style";
import {
  StyledList,
  StyledListContainer,
} from "@component/usersFromCountries/usersFromCountries.style";
import {
  StyledFabButton,
  StyledFabButtonContainer,
} from "../products/products.style";

const Vacancy: React.FC<VacancyProps> = ({ userId }) => {
  const {
    vacancyData,
    handleCloseDrawer,
    handleOpenDrawer,
    index,
    openDrawer,
    removeVacancy,
    loading,
    vacancyId,
  } = vacancyController({ userId });
  return (
    <>
      {vacancyData.length !== 0 ? (
        <StyledListContainer>
          <StyledList>
            {vacancyData?.map((item) => (
              <CompanyVacancyCard
                item={item}
                key={item._id}
                onEditIcon={(id) => handleOpenDrawer(id)}
                onDeleteIcon={(id) => removeVacancy(id)}
                loading={loading}
                id={vacancyId}
              />
            ))}
          </StyledList>
        </StyledListContainer>
      ) : (
        <StyledContainer>
          <StyledText>Nothing to show here</StyledText>
        </StyledContainer>
      )}
      {openDrawer && (
        <EditVacancySlider
          openDrawer={openDrawer}
          handleCloseIcon={handleCloseDrawer}
          vacancyId={index}
          userId={userId}
        />
      )}
      <StyledFabButtonContainer>
        <StyledFabButton color="primary" onClick={() => handleOpenDrawer()}>
          <Add />
        </StyledFabButton>
      </StyledFabButtonContainer>
    </>
  );
};

export default memo(Vacancy);
