import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "@services/api/api";
import { Constant } from "@services/constant";
import {
  DashboardControllerProps,
  UserCountProps,
} from "./dashboard.interface";
import { PieChartDataProps } from "@component/pieChart/pieChart.interface";
import { setNavigation } from "@services/redux/user/action";
import { useAppDispatch } from "@services/redux/controller";

const dashboardController = (): DashboardControllerProps => {
  const navigation = useNavigate();
  const dispatch = useAppDispatch();
  const [userCount, setUserCount] = useState<UserCountProps>({
    companyCount: 0,
    personCount: 0,
  });
  const [loading, setLoading] = useState<boolean>(false);

  const total = userCount?.companyCount + userCount?.personCount;

  const companyData = parseInt(
    ((userCount?.companyCount / total) * 100).toFixed()
  );
  const userData = parseInt(((userCount?.personCount / total) * 100).toFixed());

  const pieChartData: PieChartDataProps[] = [
    {
      id: 1,
      name: "Company Users",
      value: companyData,
      color: "#0066b2",
    },
    {
      id: 2,
      name: "Person Users",
      value: userData,
      color: "#002D62",
    },
    { id: 3, name: "Banned Users", value: 0, color: "#6699CC" },
  ];

  useEffect(() => {
    getUserCount();
  }, []);

  const getUserCount = async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(Constant.userCount);
      setUserCount(data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onPersonUser = (): void => {
    navigation("/drawer/users");
    dispatch(setNavigation("Users"));
  };

  const onCompanyUser = (): void => {
    navigation("/drawer/company");
    dispatch(setNavigation("Company"));
  };

  const onTotalUser = (): void => {
    navigation("/drawer/allUsers");
    dispatch(setNavigation("All Users"));
  };

  return {
    userCount,
    loading,
    pieChartData,
    onCompanyUser,
    onPersonUser,
    onTotalUser,
  };
};

export default dashboardController;
