import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "@services/redux/controller";
import { UserDetailsControllerProps } from "./userDetails.interface";
import { User } from "@services/redux/userList/interface";

const userDetailsController = (): UserDetailsControllerProps => {
  const navigation = useNavigate();
  const { userId } = useParams();
  const userList = useAppSelector((state) => state.userListReducer);
  const userDetails = userList.userList.filter((item: User) => {
    if (item._id === userId) {
      return item;
    }
  });
  const goBack = (): void => {
    navigation(-1);
  };

  return { goBack, userDetails };
};

export default userDetailsController;
