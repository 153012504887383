import { Box, styled } from "@mui/material";
import Button from "@component/button/Button";
import Input from "@component/input/Input";
import { Text } from "@component";

export const StyledContainer = styled(Box)(({ theme }) => ({
  width: "80%",
  alignSelf: "center",
  display: "flex",
  flexDirection: "column",
  marginBottom: theme.spacing(18),
}));

export const StyledInput = styled(Input)(({ theme }) => ({
  width: "100%",
  alignSelf: "center",
  margin: theme.spacing(2, 0),
  padding: theme.spacing(2, 0),
  input: {
    color: theme.palette.common.black,
    height: 10,
  },
  "& .MuiFormHelperText-root": {
    color: theme.palette.error.main,
  },
}));

export const StyledSelectionButton = styled(Button)(({ theme }) => ({
  background: theme.palette.grey[300],
  marginTop: theme.spacing(6),
  width: "100%",
  height: 38,
  justifyContent: "flex-start",
  paddingLeft: theme.spacing(14),
  "&:hover": {
    background: theme.palette.grey[300],
  },
}));

export const StyledText = styled(Text)(({ theme }) => ({
  color: theme.palette.common.black,
  marginLeft: theme.spacing(6),
}));

export const StyledTitleText = styled(Text)(({ theme }) => ({
  color: theme.palette.common.black,
  marginTop: theme.spacing(6),
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  width: "27%",
  height: 45,
  color: theme.palette.secondary.main,
  border: "2px solid",
  margin: theme.spacing(4),
  background: theme.palette.common.white,
  "&:hover": {
    background: theme.palette.common.white,
  },
  [theme.breakpoints.down("lg")]: {
    width: "30%",
  },
}));

export const StyledButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  position: "fixed",
  bottom: 0,
  width: "31vw",
  alignSelf: "center",
  background: theme.palette.common.white,
  padding: theme.spacing(6, 0),
  [theme.breakpoints.down("sm")]: {
    width: "80vw",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export const StyledErrorText = styled(Text)(({ theme }) => ({
  fontSize: 13,
  alignSelf: "flex-start",
  color: "red",
  marginLeft: theme.spacing(10),
  marginTop: theme.spacing(2),
}));
