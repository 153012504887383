import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Button, styled, lighten } from "@mui/material";

export const StyledButton = styled(Button)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  margin: theme.spacing(4, 0),
  padding: theme.spacing(4, 0),
  textTransform: "none",
  "&:hover": {
    background: lighten(theme.palette.primary.main, 0.3),
  },
}));

export const StyledBorder = styled(Box)(({ theme }) => ({
  height: 20,
  width: theme.spacing(2),
  borderRadius: theme.spacing(4),
}));

export const StyledDropDownButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  textTransform: "none",
  justifyContent: "flex-start",
  fontSize: 16,
  marginLeft: theme.spacing(17),
  padding: theme.spacing(1),
  marginTop: theme.spacing(2),
  paddingLeft: theme.spacing(8),
  width: "50%",
  [theme.breakpoints.down("sm")]: {
    width: "72%",
  },
  "&:hover": {
    background: lighten(theme.palette.primary.main, 0.3),
  },
}));

export const StyledExpandMoreIcon = styled(ExpandMore)(({ theme }) => ({
  flex: 1,
  marginRight: theme.spacing(12),
  color: theme.palette.common.white,
}));

export const StyledExpandLessIcon = styled(ExpandLess)(({ theme }) => ({
  flex: 1,
  marginRight: theme.spacing(12),
  color: theme.palette.common.white,
}));
