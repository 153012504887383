import { useEffect, useState } from "react";
import {
  listItemData,
  MultipleSelectionDropDownControllerProps,
  MultipleSelectionDropDownProps,
} from "./multipleSelectionDropDown.interface";

const multipleSelectionDropDownController = ({
  data,
  onSelectValue,
  placeholder,
  selectedValues,
  values,
}: MultipleSelectionDropDownProps): MultipleSelectionDropDownControllerProps => {
  const [openDetails, setOpenDetails] = useState<listItemData>();
  const [openSubDetails, setOpenSubDetails] = useState<listItemData>();
  const [openSubChildDetails, setOpenSubChildDetails] =
    useState<listItemData>();
  const [singleSelectionValue, setSingleSelectionValue] =
    useState<listItemData>(values);
  const [multipleSelectionValue, setMultipleSelectionValue] =
    useState<listItemData[]>(values);
  const [dropdownData, setDropdownData] = useState<object[]>([]);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [arrow, setArrow] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setSingleSelectionValue(values);
    }, 300);
  }, [values]);

  useEffect(() => {
    onSelectValue(singleSelectionValue, openDetails, openSubDetails);
    setDropdownData(data);
  }, [singleSelectionValue]);

  const currentExpandView = (item: any) => {
    setOpenSubDetails(undefined);
    setOpenDetails(item);
  };

  const currentSubExpandView = (item: any) => {
    // if (openSubDetails) {
    //   setOpenSubDetails(undefined);
    // } else {
    //   setOpenSubDetails(item);
    // }
    setOpenSubDetails(item);
  };

  const currentSubChildExpandView = (item: any) => {
    if (openSubChildDetails) {
      setOpenSubChildDetails(undefined);
    } else {
      setOpenSubChildDetails(item);
    }
  };

  const onClickItems = (data: any) => {
    setSingleSelectionValue(data);
    setModalVisible(!modalVisible);
    // Find index and id from Array
    // let isInclude = selectedValue.filter(
    //   (res: listItemData) => res?.id == data.id,
    // );
    // let index = selectedValue.findIndex(
    //   (value: listItemData) => value.id == data.id,
    // );
    // // Add and remove data from array
    // if (isInclude.length == 0) {
    //   selectedValue.push(data);
    //   setSelectedValue([...selectedValue]);
    // } else {
    //   selectedValue.splice(index, 1);
    //   setSelectedValue([...selectedValue]);
    // }
  };
  const onClickSingleItem = (data: any) => {
    setOpenSubDetails(undefined);
    setOpenDetails(undefined);
    setSingleSelectionValue(data);
    setModalVisible(!modalVisible);
  };

  return {
    currentExpandView,
    currentSubChildExpandView,
    currentSubExpandView,
    onClickItems,
    onClickSingleItem,
    singleSelectionValue,
    openDetails,
    openSubDetails,
    openSubChildDetails,
    modalVisible,
    setModalVisible,
    arrow,
    setArrow,
  };
};

export default multipleSelectionDropDownController;
