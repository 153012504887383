import React, { memo } from "react";
import { Box, useTheme } from "@mui/material";
import { Star } from "@mui/icons-material";
import drawerItemController from "./drawerItem.controller";
import { DrawerItemProps, MenuItemProps } from "./drawerItem.interface";
import DrawerUserProfile from "@component/drawerUserProfile/DrawerUserProfile";
import Text from "@component/text/Text";
import {
  StyledBorder,
  StyledButton,
  StyledDropDownButton,
  StyledExpandLessIcon,
  StyledExpandMoreIcon,
} from "./drawerItem.style";

const DrawerItem: React.FC<DrawerItemProps> = ({ data, onItemClick }) => {
  const theme = useTheme();
  const {
    selectedItem,
    openCollapse,
    onItemPress,
    onQualificationButton,
    onZoneButton,
  } = drawerItemController({
    data,
    onItemClick,
  });
  const MenuItem: React.FC<MenuItemProps> = ({ item }) => {
    return (
      <>
        <StyledButton
          key={item.id}
          fullWidth
          onClick={() => {
            onItemPress(item);
            item.title !== "Master" && onItemClick();
          }}
        >
          <StyledBorder
            sx={{
              background: selectedItem(item),
            }}
          />
          <item.icon
            sx={{
              ml: 9,
              color: theme.palette.common.white,
            }}
          />
          <Text ml={10} color={theme.palette.common.white}>
            {item.title}
          </Text>
          {item.expand &&
            (openCollapse ? (
              <StyledExpandLessIcon />
            ) : (
              <StyledExpandMoreIcon />
            ))}
        </StyledButton>
        {openCollapse && item.expand && (
          <>
            <StyledDropDownButton
              variant="text"
              startIcon={<Star />}
              onClick={onQualificationButton}
            >
              Qualifications
            </StyledDropDownButton>
            <StyledDropDownButton
              variant="text"
              startIcon={<Star />}
              onClick={onZoneButton}
            >
              Zone
            </StyledDropDownButton>
          </>
        )}
      </>
    );
  };

  return (
    <>
      <DrawerUserProfile />
      <Box mt={theme.spacing(8)}>
        {data.map((item) => (
          <MenuItem key={item.id} item={item} />
        ))}
      </Box>
    </>
  );
};

export default memo(DrawerItem);
