import { EditCompanyUserDataProps } from "./interface";
import type from "./type";

const initialState: EditCompanyUserDataProps = {
  editBasicDetailsData: {
    firstName: null,
    lastName: null,
    email: null,
    mobileNo: null,
  },
  editCompanyDetailsData: {
    legalFirmName: null,
    ownerName: null,
    companyEmail: null,
    companyMobileNo: null,
    address: null,
  },
};

const editCompanyUserReducer = (
  state: EditCompanyUserDataProps = initialState,
  action: any
) => {
  switch (action.type) {
    case type.editBasicDetails:
      return {
        ...state,
        editBasicDetailsData: action.payload,
      };
    case type.editCompanyDetails:
      return {
        ...state,
        editCompanyDetailsData: action.payload,
      };
    case type.removeEditData:
      return initialState;
    default:
      return state;
  }
};

export default editCompanyUserReducer;
