import React from "react";
import { Add } from "@mui/icons-material";
import AddCompanyUserSlider from "./addCompanyUserSlider/AddCompanyUserSlider";
import { CircularProgress, Table } from "@component";
import companyController from "./company.controller";
import { tableCellData } from "./company.const";
import {
  StyledBox,
  StyledButton,
  StyledContainer,
  StyledProgressContainer,
  StyledSearchInput,
  StyledText,
} from "./company.style";

const Company: React.FC = () => {
  const {
    loading,
    searchValue,
    filter,
    filterData,
    openDrawer,
    handleOpen,
    handleClose,
    companyDetailsData,
    handleDialogClose,
    handleDialogOpen,
    openDialog,
    index,
    removeUser,
    deleteLoading,
  } = companyController();
  return (
    <StyledContainer>
      <StyledBox>
        <StyledText variant="h4">Company</StyledText>
        <StyledSearchInput
          placeholder="Type something..."
          value={searchValue}
          onChange={(val) => filter(val?.target.value)}
        />
        <StyledButton
          onClick={() => handleOpen()}
          startIcon={<Add fontSize="medium" />}
        >
          Add Company
        </StyledButton>
        <AddCompanyUserSlider
          openDrawer={openDrawer}
          handleCloseIcon={handleClose}
          companyUserId={index}
        />
      </StyledBox>
      {loading ? (
        <StyledProgressContainer>
          <CircularProgress size={40} color={"primary"} />
        </StyledProgressContainer>
      ) : (
        <Table
          tableData={filterData == null ? companyDetailsData : filterData}
          tableCellData={tableCellData}
          navigationTo={"companyDetails"}
          isButton
          buttonText="View"
          isDeleteButton
          isEditButton
          handleOpenDialog={(id) => handleDialogOpen(id)}
          openDialog={openDialog}
          handleCloseDialog={handleDialogClose}
          handleEdit={(id) => {
            handleOpen(id);
          }}
          handleDeleteDialog={removeUser}
          handleDeleteDialogLoading={deleteLoading}
        />
      )}
    </StyledContainer>
  );
};

export default Company;
