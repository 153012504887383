import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AccountControllerProps, ValidationProps } from "./account.interface";
import { axiosInstance } from "@services/api/api";
import { checkPassword } from "@utils/validation/validation";
import { Constant } from "@services/constant";
import validationMessage from "@utils/validation/validationMessage";

const accountController = (): AccountControllerProps => {
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [updateMessage, setUpdateMessage] = useState<string>("");
  const [error, setError] = useState<ValidationProps>({
    oldPassword: null,
    newPassword: null,
    confirmPassword: null,
  });
  const navigation = useNavigate();

  const onChangePassword = async () => {
    /* API call for change password */
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(Constant.changePassword, {
        password: oldPassword,
        newPassword: newPassword,
      });
      setLoading(false);
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
      navigation("/");
    } catch (error: any) {
      setLoading(false);
      setOpenSnackBar(true);
      setUpdateMessage(error.data.message);
    }
  };

  const validation = (): void => {
    let isValidate = true;
    if (!oldPassword) {
      isValidate = false;
      error.oldPassword = validationMessage.validationPassword;
    } else if (!checkPassword(oldPassword)) {
      isValidate = false;
      error.oldPassword = validationMessage.validationValidPassword;
    } else {
      error.oldPassword = null;
    }
    if (!newPassword) {
      isValidate = false;
      error.newPassword = validationMessage.validationPassword;
    } else if (!checkPassword(newPassword)) {
      isValidate = false;
      error.newPassword = validationMessage.validationValidPassword;
    } else if (!newPassword.localeCompare(oldPassword)) {
      isValidate = false;
      error.newPassword = validationMessage.validationPasswordOldAndNew;
    } else {
      error.newPassword = null;
    }
    if (!confirmPassword) {
      isValidate = false;
      error.confirmPassword = validationMessage.validationPassword;
    } else if (!checkPassword(confirmPassword)) {
      isValidate = false;
      error.confirmPassword = validationMessage.validationValidPassword;
    } else if (!confirmPassword.match(newPassword)) {
      isValidate = false;
      error.confirmPassword =
        validationMessage.validationPassConfirmPassNotMatch;
    } else {
      error.confirmPassword = null;
    }
    setError({ ...error });
    if (isValidate) {
      onChangePassword();
    }
  };

  return {
    onChangePassword,
    oldPassword,
    setOldPassword,
    newPassword,
    setNewPassword,
    confirmPassword,
    setConfirmPassword,
    loading,
    openSnackBar,
    setOpenSnackBar,
    validation,
    error,
    updateMessage,
  };
};

export default accountController;
