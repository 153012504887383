import { Box, styled } from "@mui/material";
import { Button, Container, Text } from "@component";

export const StyledForgotPasswordContainer = styled(Container)(({ theme }) => ({
  justifyContent: "center",
  alignItems: "center",
}));

export const StyledCenterBox = styled(Box)(({ theme }) => ({
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  flexDirection: "column",
  width: "400px",
  padding: theme.spacing(16, 0),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(8, 10),
  },
}));

export const StyledLogo = styled("img")(({ theme }) => ({
  height: 150,
  marginTop: theme.spacing(12),
}));

export const StyledBackToLogin = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(16),
  borderBottom: `1px solid`,
  borderRadius: `0px`,
  color: theme.palette.common.black,
  padding: theme.spacing(4, 0),
  height: 0,
}));

export const StyledText = styled(Text)(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.common.black,
}));
