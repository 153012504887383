import type from "./type";

const editBasicDetails = (payload: any) => ({
  type: type.editBasicDetails,
  payload,
});

const editCompanyDetails = (payload: any) => ({
  type: type.editCompanyDetails,
  payload,
});

const removeEditData = () => ({
  type: type.removeEditData,
});

export { editBasicDetails, editCompanyDetails, removeEditData };
