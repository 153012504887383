import { memo } from "react";
import { Box } from "@mui/material";
import { Constant } from "@services/constant";
import { image } from "@assets";
import UserDetailsCard from "@component/card/userDetailsCard/UserDetailsCard";
import { UserProfileDetailsProps } from "./userProfileDetails.interface";
import {
  StyledContainer,
  StyledProfileContainer,
  StyledText,
  StyledTextWithBackground,
  StyledUserNameText,
  StyledUserProfile,
} from "./userProfileDetails.style";

const UserProfileDetails: React.FC<UserProfileDetailsProps> = ({
  userDetailsData,
  profileImage,
  firstName,
  lastName,
  email,
  isAccountVerified,
}) => {
  return (
    <StyledContainer sx={{ boxShadow: 3 }}>
      <StyledProfileContainer>
        <StyledUserProfile
          src={
            profileImage
              ? `${Constant.baseUrl}${profileImage}`
              : image.userProfile
          }
        />
        <Box>
          <StyledUserNameText color="primary">{`${firstName} ${lastName}`}</StyledUserNameText>
          <StyledText>{email}</StyledText>
          <StyledTextWithBackground>
            {isAccountVerified ? "Verified" : "Not verified"}
          </StyledTextWithBackground>
        </Box>
      </StyledProfileContainer>
      {userDetailsData.map((item) => (
        <UserDetailsCard item={item} key={item._id} />
      ))}
    </StyledContainer>
  );
};

export default memo(UserProfileDetails);
