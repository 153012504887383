import { AppBarDataProps } from "./appBar.interface";

export const appBarData: AppBarDataProps[] = [
  {
    id: 1,
    title: "Account",
    navigation: "account",
  },
  {
    id: 2,
    title: "Manage Admins",
    navigation: "manageAdmins",
  },
  {
    id: 3,
    title: "Support",
    navigation: "support",
  },
  {
    id: 4,
    title: "Reports",
    navigation: "reports",
  },
];
