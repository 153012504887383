import { Box, styled } from "@mui/material";
import { Button, Input, Text } from "@component";

export const StyledContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(10, 0),
  padding: theme.spacing(0, 14),
}));

export const StyledInputBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "55%",
  justifyContent: "center",
  margin: theme.spacing(6, 0),
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}));

export const StyledChangeText = styled(Text)(({ theme }) => ({
  color: theme.palette.primary.dark,
  marginTop: theme.spacing(10),
}));

export const StyledText = styled(Text)(({ theme }) => ({
  fontSize: 16,
  fontWeight: "bold",
  color: theme.palette.primary.dark,
  [theme.breakpoints.down("sm")]: {
    fontSize: 13,
  },
}));

export const StyledInput = styled(Input)(({ theme }) => ({
  margin: theme.spacing(4, 0),
  padding: theme.spacing(0),
  borderRadius: theme.spacing(2),
  width: "50%",
  input: {
    color: "black",
    height: 13,
  },
  "& .MuiFormHelperText-root": {
    color: theme.palette.error.main,
  },
  [theme.breakpoints.down("lg")]: {
    width: "60%",
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  width: "50%",
  height: 40,
  alignSelf: "flex-end",
  marginTop: theme.spacing(4),
  [theme.breakpoints.down("lg")]: {
    width: "60%",
  },
}));

export const StyledProfileContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(10),
}));

export const StyledUserProfile = styled("img")(({ theme }) => ({
  height: 100,
  width: 100,
  borderRadius: 50,
  [theme.breakpoints.down("sm")]: {
    height: 70,
    width: 70,
  },
}));
