import { Edit } from "@mui/icons-material";
import { styled } from "@mui/material";
import Button from "@component/button/Button";
import Input from "@component/input/Input";
import { Text } from "@component";

export const StyledInput = styled(Input)(({ theme }) => ({
  width: "80%",
  alignSelf: "center",
  padding: theme.spacing(2, 0),
  input: {
    color: theme.palette.common.black,
    height: 10,
  },
  "& .MuiFormHelperText-root": {
    color: theme.palette.error.main,
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  width: "40%",
  height: 40,
  alignSelf: "center",
  marginTop: theme.spacing(8),
}));

export const StyledUserProfile = styled("img")(({ theme }) => ({
  height: 95,
  width: 95,
  borderRadius: theme.spacing(18),
  alignSelf: "center",
}));

export const StyledFileInput = styled("input")(({ theme }) => ({
  display: "none",
}));

export const StyledLabel = styled("label")(({ theme }) => ({
  alignSelf: "center",
  marginLeft: theme.spacing(18),
  borderRadius: theme.spacing(8),
}));

export const StyledIconButton = styled("span")(({ theme }) => ({
  borderRadius: theme.spacing(8),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(2),
  marginLeft: -6,
  position: "absolute",
  cursor: "pointer",
  background: theme.palette.grey[100],
}));

export const StyledEditIcon = styled(Edit)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

export const StyledErrorText = styled(Text)(({ theme }) => ({
  fontSize: 13,
  textAlign: "center",
  color: "red",
  margin: theme.spacing(8),
}));
