import { useMemo, useState } from "react";
import { axiosInstance } from "@services/api/api";
import { Constant } from "@services/constant";
import {
  PortfolioMachineControllerProps,
  PortfolioMachineProcessDataProps,
  PortfolioMachineProps,
} from "./portfolioMachine.interface";
import { removePortfolioJobWorkData } from "@services/redux/companyPortfolio/action";
import { useAppDispatch, useAppSelector } from "@services/redux/controller";

const portfolioMachineController = ({
  userId,
}: PortfolioMachineProps): PortfolioMachineControllerProps => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [index, setIndex] = useState<string>();
  const [machineId, setMachineId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { portfolioJobWork } = useAppSelector(
    (state) => state.companyPortfolioReducer
  );
  const dispatch = useAppDispatch();
  const machineData: PortfolioMachineProcessDataProps[] = useMemo(
    () =>
      portfolioJobWork &&
      portfolioJobWork
        ?.filter((item: any) => item.machining)
        .map((item: any) => ({
          _id: item._id,
          title: item.machining,
          description: item.machinigJobDescription,
          file: item.machiningFiles,
        }))
        .reverse(),
    [portfolioJobWork]
  );

  const handleCloseDrawer = (): void => {
    setOpenDrawer(false);
  };

  const handleOpenDrawer = (id?: string): void => {
    setOpenDrawer(true);
    setIndex(id);
  };

  const removeMachine = async (machineId: string) => {
    /* API call for removing machine */
    setMachineId(machineId);
    const formData = new URLSearchParams();
    formData.append("userId", userId);
    formData.append("type", "1");
    formData.append("jobWorkId", machineId);
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(
        Constant.removePortfolioProductJobWork,
        formData.toString()
      );
      setLoading(false);
      dispatch(removePortfolioJobWorkData(data.data));
    } catch (error) {
      setLoading(false);
    }
  };

  return {
    machineData,
    handleCloseDrawer,
    openDrawer,
    handleOpenDrawer,
    index,
    removeMachine,
    loading,
    machineId,
  };
};

export default portfolioMachineController;
