import { Box, IconButton, styled } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import Text from "@component/text/Text";

export const BoxContainer = styled(Box)(({ theme }) => ({
  width: "37vw",
  height: "100%",
  [theme.breakpoints.down("lg")]: {
    padding: theme.spacing(16, 10),
    width: "45vw",
  },
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(16, 10),
    width: "100vw",
  },
  zIndex: theme.zIndex.drawer + 1,
}));

export const StyledContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8),
  display: "flex",
  flexDirection: "column",
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  alignSelf: "flex-end",
}));

export const StyledCloseIcon = styled(CloseOutlined)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(10),
}));

export const StyledText = styled(Text)(({ theme }) => ({
  textAlign: "center",
  flex: 1,
  color: theme.palette.common.black,
}));
