import { LinearProgress, styled } from "@mui/material";

export const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: theme.spacing(6),
  borderRadius: theme.spacing(4),
  width: "55%",
  marginBottom: theme.spacing(6),
  [theme.breakpoints.down("md")]: {
    width: "65%",
  },
}));
