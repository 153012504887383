import { styled } from "@mui/material";
import Button from "@component/button/Button";

export const StyledButton = styled(Button)(({ theme }) => ({
  background: theme.palette.primary.main,
  borderRadius: theme.spacing(2),
  width: "100%",
  height: 40,
  "&:hover": {
    background: theme.palette.primary.main,
  },
}));
