import { Box, Paper, styled } from "@mui/material";
import Button from "@component/button/Button";
import Text from "@component/text/Text";

export const StyledPaper = styled(Paper)(({ theme }) => ({
  height: 250,
  width: 290,
  padding: theme.spacing(6),
  background: theme.palette.primary.main,
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-end",
}));

export const StyledTitleText = styled(Text)(({ theme }) => ({
  fontWeight: "500",
}));

export const StyledText = styled(Text)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  height: 36,
  borderRadius: theme.spacing(4),
  "&.Mui-disabled": {
    color: theme.palette.grey[600],
    background: theme.palette.grey[500],
  },
}));
