import { Box, styled } from "@mui/material";
import { Button, Container, Text } from "@component";

export const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(14),
  flexDirection: "column",
}));

export const StyledText = styled(Text)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginLeft: theme.spacing(4),
}));

export const StyledEditorContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(8),
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  width: "13%",
  marginTop: theme.spacing(14),
  marginLeft: theme.spacing(4),
  [theme.breakpoints.down("md")]: {
    width: "28%",
  },
}));

export const StyledProgressContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "80vh",
}));
