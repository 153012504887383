import { useEffect, useMemo, useState } from "react";
import {
  addPortfolioJobWorkData,
  editPortfolioJobWorkData,
} from "@services/redux/companyPortfolio/action";
import { axiosInstance } from "@services/api/api";
import { checkNumeric } from "@utils/validation/validation";
import { Constant } from "@services/constant";
import {
  editPortfolioProcessControllerProps,
  EditPortfolioProcessProps,
  ErrorProps,
} from "./editPortfolioProcess.interface";
import { JobWorkType } from "@pages/home/companyDetails/allCompanyDetails/machine/editMachineSlider/editMachineSlider.interface";
import { useAppDispatch, useAppSelector } from "@services/redux/controller";
import validationMessage from "@utils/validation/validationMessage";

const editPortfolioProcessController = ({
  handleCloseIcon,
  openDrawer,
  processId,
  userId,
}: EditPortfolioProcessProps): editPortfolioProcessControllerProps => {
  useEffect(() => {
    if (processId) {
      setDescription(processData[0]?.processesJobDescription);
      setNoOfProcess(processData[0]?.noOfProcesses);
      setSelectedFile(processData[0]?.processesFiles);
    }
  }, []);
  const { portfolioJobWork } = useAppSelector(
    (state) => state.companyPortfolioReducer
  );
  const dispatch = useAppDispatch();
  const processData = useMemo(
    () =>
      portfolioJobWork?.filter((item: any) => {
        return item._id === processId;
      }),
    [portfolioJobWork]
  );
  const [description, setDescription] = useState<string>("");
  const [noOfProcess, setNoOfProcess] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<Blob | MediaSource | null>(
    null
  );
  const [removeFile, setRemoveFile] = useState<string>();
  const [error, setError] = useState<ErrorProps>({
    selectProcessError: null,
    descriptionError: null,
    noOfProcessError: null,
  });
  const [selectedFileError, setSelectedFileError] = useState<string | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string>("");
  const [selectedProcess, setSelectedProcess] = useState<JobWorkType | string>(
    ""
  );
  const [selectProcessMainCategory, setSelectProcessMainCategory] = useState<
    JobWorkType | string
  >("");
  const [selectProcessSubMainCategory, setSelectProcessSubMainCategory] =
    useState<JobWorkType | string>("");

  const selectProcess = selectProcessSubMainCategory
    ? `${selectProcessMainCategory?.name}-${selectProcessSubMainCategory?.name}-${selectedProcess?.name}`
    : selectedProcess
    ? `${
        selectProcessMainCategory
          ? selectProcessMainCategory?.name + "-" + selectedProcess?.name
          : selectedProcess?.name
      }`
    : processData[0]?.processes
    ? processData[0]?.processes
    : "Select Processes";

  const handleCloseSnackBar = (): void => {
    setOpenSnackBar(false);
  };

  const fileType = ["image/png"];

  const handleSelectedFile = (e: any): void => {
    /* Functionality to choose image for profile image */
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile && fileType.includes(selectedFile.type)) {
        setSelectedFile(selectedFile);
        setSelectedFileError("");
      } else {
        setSelectedFile(null);
        setSelectedFileError("Please select image file");
      }
    }
  };

  const onDeleteIcon = (): void => {
    if (selectedFile) {
      setRemoveFile(processData[0]?.processesFiles);
    }
    setSelectedFile(null);
  };

  const validation = (): void => {
    /* Validation for edit process form */
    let isValidate = true;
    if (!selectProcess) {
      isValidate = false;
      error.selectProcessError = validationMessage.selectProcessValidation;
    } else {
      error.selectProcessError = "";
    }
    if (!description) {
      isValidate = false;
      error.descriptionError = validationMessage.validationDescription;
    } else {
      error.descriptionError = "";
    }
    if (!noOfProcess) {
      isValidate = false;
      error.noOfProcessError = validationMessage.noOfProcessValidation;
    } else if (!checkNumeric(noOfProcess)) {
      isValidate = false;
      error.noOfProcessError = validationMessage.validNoOfProcessValidation;
    } else {
      error.noOfProcessError = "";
    }
    setError({ ...error });
    if (isValidate) {
      if (processId) {
        editProcess();
      } else {
        addProcess();
      }
    }
  };

  const addProcess = async () => {
    /* API call for editing process */
    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("processes", selectProcess);
    formData.append("processesJobDescription", description);
    formData.append("noOfProcesses", noOfProcess);
    formData.append("processesFiles", selectedFile);
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(
        Constant.addPortfolioJobWork,
        formData
      );
      setLoading(false);
      dispatch(addPortfolioJobWorkData(data.data));
      setTimeout(() => handleCloseIcon(), 2000);
      setOpenSnackBar(true);
      setSnackBarMessage(data.message);
    } catch (error: any) {
      setLoading(false);
      setOpenSnackBar(true);
      setSnackBarMessage(error.data.message);
    }
  };

  const editProcess = async () => {
    /* API call for editing process */
    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("jobWorkId", processId);
    formData.append("processes", selectProcess);
    formData.append("processesJobDescription", description);
    formData.append("noOfProcesses", noOfProcess);
    formData.append("processesFiles", selectedFile);
    if (removeFile) {
      formData.append("removeProcessesUrl", removeFile);
    }
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(
        Constant.editPortfolioJobWork,
        formData
      );
      setLoading(false);
      dispatch(editPortfolioJobWorkData(data.data));
      setTimeout(() => handleCloseIcon(), 2000);
      setOpenSnackBar(true);
      setSnackBarMessage(data.message);
    } catch (error: any) {
      setLoading(false);
      setOpenSnackBar(true);
      setSnackBarMessage(error.data.message);
    }
  };

  return {
    description,
    error,
    handleSelectedFile,
    selectedFile,
    selectedFileError,
    setDescription,
    validation,
    handleCloseSnackBar,
    loading,
    openSnackBar,
    snackBarMessage,
    noOfProcess,
    setNoOfProcess,
    onDeleteIcon,
    selectProcess,
    selectedProcess,
    selectProcessMainCategory,
    selectProcessSubMainCategory,
    setSelectedProcess,
    setSelectProcessMainCategory,
    setSelectProcessSubMainCategory,
  };
};

export default editPortfolioProcessController;
