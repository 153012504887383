import React, { memo } from "react";
import { Delete } from "@mui/icons-material";
import { IconButton, useTheme } from "@mui/material";
import editProcessSliderController from "./editProcessSlider.controller";
import { EditProcessSliderProps } from "./editProcessSlider.interface";
import { MultipleSelectionDropDown, Slider, SnackBar } from "@component";
import { processesData } from "./editProcessSlider.const";
import {
  StyledButton,
  StyledButtonContainer,
  StyledChooseButton,
  StyledChooseFileContainer,
  StyledContainer,
  StyledErrorText,
  StyledFileInput,
  StyledFileText,
  StyledInput,
  StyledLabel,
  StyledText,
} from "../../machine/editMachineSlider/editMachineSlider.style";

const EditProcessSlider: React.FC<EditProcessSliderProps> = ({
  openDrawer,
  handleCloseIcon,
  processId,
  userId,
}) => {
  const theme = useTheme();
  const {
    description,
    error,
    handleSelectedFile,
    selectedFile,
    selectedFileError,
    setDescription,
    validation,
    handleCloseSnackBar,
    loading,
    openSnackBar,
    snackBarMessage,
    noOfProcess,
    selectProcess,
    setNoOfProcess,
    onDeleteIcon,
    selectedProcess,
    setSelectProcessMainCategory,
    setSelectProcessSubMainCategory,
    setSelectedProcess,
  } = editProcessSliderController({
    handleCloseIcon,
    openDrawer,
    processId,
    userId,
  });
  return (
    <Slider
      openDrawer={openDrawer}
      handleCloseIcon={() => {
        handleCloseIcon();
      }}
      title={processId ? "Edit Process" : "Add Process"}
    >
      <StyledContainer>
        <MultipleSelectionDropDown
          data={processesData}
          placeholder={selectProcess}
          onSelectValue={(
            details: any,
            mainCategory: any,
            subMainCategory: any
          ) => {
            setSelectProcessMainCategory(mainCategory);
            setSelectProcessSubMainCategory(subMainCategory);
            setSelectedProcess(details);
          }}
          values={selectedProcess}
        />
        <StyledText>Job Description</StyledText>
        <StyledInput
          variant="outlined"
          placeholder="Job Description"
          InputLabelProps={{ style: { color: theme.palette.common.black } }}
          value={description}
          onChange={(val) => setDescription(val?.target?.value)}
          helperText={error.descriptionError}
        />
        <StyledText>No. of Process</StyledText>
        <StyledInput
          variant="outlined"
          placeholder="No. of Process"
          InputLabelProps={{ style: { color: theme.palette.common.black } }}
          value={noOfProcess}
          onChange={(val) => setNoOfProcess(val?.target?.value)}
          helperText={error.noOfProcessError}
        />
        <StyledText>Upload (Photos, videos, catalogue)</StyledText>
        <StyledFileInput
          id="selectPortfolio"
          type="file"
          onChange={handleSelectedFile}
        />
        <StyledChooseFileContainer>
          <StyledLabel htmlFor="selectPortfolio">
            <StyledChooseButton>
              {selectedFile ? "Selected file" : "Choose file"}
            </StyledChooseButton>
          </StyledLabel>
          {selectedFile && (
            <StyledFileText>
              {selectedFile.name ?? selectedFile}
              <IconButton onClick={onDeleteIcon}>
                <Delete color="secondary" />
              </IconButton>
            </StyledFileText>
          )}
        </StyledChooseFileContainer>
        <StyledErrorText>{selectedFileError}</StyledErrorText>
        <SnackBar
          open={openSnackBar}
          onClose={handleCloseSnackBar}
          onCloseClick={handleCloseSnackBar}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          message={snackBarMessage}
        />
        <StyledButtonContainer>
          <StyledButton
            loading={loading}
            onClick={validation}
            progressColor="info"
          >
            {processId ? "Edit" : "Add"}
          </StyledButton>
        </StyledButtonContainer>
      </StyledContainer>
    </Slider>
  );
};

export default memo(EditProcessSlider);
