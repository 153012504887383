import { Box, styled } from "@mui/material";
import { SettingsBar, Text } from "@component";

export const StyledContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.common.white,
  padding: theme.spacing(10, 10),
  wordBreak: "break-word",
  marginTop: theme.spacing(10),
  height: "90vh",
}));

export const StyledText = styled(Text)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(10),
  marginLeft: theme.spacing(4),
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "80vh",
}));

export const StyledSettingsBar = styled(SettingsBar)(({ theme }) => ({
  width: "18vw",
  marginRight: 10,
  [theme.breakpoints.down("sm")]: {
    width: "25vw",
  },
}));
