import { useMemo, useState } from "react";
import {
  addVacancyData,
  editVacancyData,
} from "@services/redux/allCompanyDetails/action";
import { axiosInstance } from "@services/api/api";
import { checkNumeric } from "@utils/validation/validation";
import { Constant } from "@services/constant";
import {
  EditVacancySliderControllerProps,
  EditVacancySliderProps,
  ErrorProps,
} from "./editVacancySlider.interface";
import { JobWorkType } from "../../machine/editMachineSlider/editMachineSlider.interface";
import { useAppDispatch, useAppSelector } from "@services/redux/controller";
import validationMessage from "@utils/validation/validationMessage";

const editVacancySliderController = ({
  handleCloseIcon,
  openDrawer,
  userId,
  vacancyId,
}: EditVacancySliderProps): EditVacancySliderControllerProps => {
  const { vacancy } = useAppSelector((state) => state.allCompanyDetailsReducer);
  const dispatch = useAppDispatch();
  const vacancyData = useMemo(
    () =>
      vacancy?.filter((item: any) => {
        return item._id === vacancyId;
      }),
    [vacancy]
  );
  const [selectMainCategory, setSelectMainCategory] = useState<
    JobWorkType | undefined
  >(undefined);
  const [selectSubMainCategory, setSelectSubMainCategory] = useState<
    JobWorkType | undefined
  >(undefined);
  const [selectedCategory, setSelectedCategory] = useState<
    JobWorkType | undefined
  >(undefined);
  const [qualification, setQualification] = useState<string>(
    vacancyData[0]?.qualification
  );
  const [experience, setExperience] = useState<string>(
    vacancyData[0]?.experience
  );
  const [description, setDescription] = useState<string>(
    vacancyData[0]?.description
  );
  const [noOfPosition, setNoOfPosition] = useState<string>(
    vacancyData[0]?.noOfPosition
  );
  const [expectedSalary, setExpectedSalary] = useState<string>(
    vacancyData[0]?.approxSalary
  );
  const [selectedFile, setSelectedFile] = useState<Blob | MediaSource | null>(
    vacancyData[0]?.files
  );
  const [removeFile, setRemoveFile] = useState<string>("");
  const [workAs, setWorkAs] = useState<string>(vacancyData[0]?.workAs);
  const [error, setError] = useState<ErrorProps>({
    selectExpertiseError: null,
    qualificationError: null,
    experienceError: null,
    descriptionError: null,
    noOfPositionError: null,
    currentSalaryError: null,
    expectedSalaryError: null,
    workAsError: null,
  });
  const [selectedFileError, setSelectedFileError] = useState<string | null>(
    null
  );
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const selectedVacancy: string = selectSubMainCategory
    ? `${selectMainCategory?.name}-${selectSubMainCategory?.name}-${selectedCategory?.name}`
    : selectedCategory
    ? `${
        selectMainCategory
          ? selectMainCategory.name + "-" + selectedCategory?.name
          : selectedCategory?.name
      }`
    : vacancyData[0]?.expertise
    ? vacancyData[0]?.expertise
    : "Select Expertise";

  const handleCloseSnackBar = (): void => {
    setOpenSnackBar(false);
  };

  const fileType = ["image/png"];

  const handleSelectFile = (e: any): void => {
    /* Functionality to choose image for profile image */
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile && fileType.includes(selectedFile.type)) {
        setSelectedFile(selectedFile);
        setSelectedFileError("");
      } else {
        setSelectedFile(null);
        setSelectedFileError("Please select image file");
      }
    }
  };

  const validation = (): void => {
    /* Validation for edit vacancy form */
    let isValidate = true;
    if (!selectedVacancy) {
      isValidate = false;
      error.selectExpertiseError =
        validationMessage.validationSelectedExpertise;
    } else {
      error.selectExpertiseError = "";
    }
    if (!qualification) {
      isValidate = false;
      error.qualificationError = validationMessage.qualificationValidation;
    } else {
      error.qualificationError = "";
    }
    if (!experience) {
      isValidate = false;
      error.experienceError = validationMessage.validationExperienceMessage;
    } else if (!checkNumeric(experience)) {
      isValidate = false;
      error.experienceError =
        validationMessage.validationValidExperienceMessage;
    } else {
      error.experienceError = "";
    }
    if (!description) {
      isValidate = false;
      error.descriptionError = validationMessage.validationDescription;
    } else {
      error.descriptionError = "";
    }
    if (!noOfPosition) {
      isValidate = false;
      error.noOfPositionError = validationMessage.noOfPositionValidation;
    } else if (!checkNumeric(noOfPosition)) {
      isValidate = false;
      error.noOfPositionError = validationMessage.validNoOfPositionValidation;
    } else {
      error.noOfPositionError = "";
    }
    if (!expectedSalary) {
      isValidate = false;
      error.expectedSalaryError = validationMessage.approxSalaryValidation;
    } else if (!checkNumeric(expectedSalary)) {
      isValidate = false;
      error.expectedSalaryError = validationMessage.validApproxSalaryValidation;
    } else {
      error.expectedSalaryError = "";
    }
    if (!workAs) {
      isValidate = false;
      error.workAsError = validationMessage.workAsValidation;
    } else {
      error.workAsError = "";
    }
    setError({ ...error });
    if (isValidate) {
      if (vacancyId) {
        editVacancy();
      } else {
        addVacancy();
      }
    }
  };

  const onDeleteIcon = (): void => {
    if (selectedFile) {
      setRemoveFile(vacancyData[0]?.files);
    }
    setSelectedFile(null);
  };

  const addVacancy = async () => {
    /* API call for adding vacancy */
    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("expertise", selectedVacancy);
    formData.append("qualification", qualification);
    formData.append("experience", experience);
    formData.append("description", description);
    formData.append("approxSalary", expectedSalary);
    formData.append("noOfPosition", noOfPosition);
    formData.append("files", selectedFile);
    formData.append("workAs", workAs);
    if (removeFile) {
      formData.append("removeUrl", removeFile);
    }
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(
        Constant.addJobVacancy,
        formData
      );
      setLoading(false);
      dispatch(addVacancyData(data.data[0]));
      setTimeout(() => handleCloseIcon(), 2000);
      setOpenSnackBar(true);
      setSnackBarMessage(data.message);
    } catch (error: any) {
      setLoading(false);
      setOpenSnackBar(true);
      setSnackBarMessage(error.data.message);
    }
  };

  const editVacancy = async () => {
    /* API call for editing vacancy */
    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("expertise", selectedVacancy);
    formData.append("qualification", qualification);
    formData.append("experience", experience);
    formData.append("description", description);
    formData.append("approxSalary", expectedSalary);
    formData.append("noOfPosition", noOfPosition);
    formData.append("jobVacancyId", vacancyId);
    formData.append("files", selectedFile);
    formData.append("workAs", workAs);
    if (removeFile) {
      formData.append("removeUrl", removeFile);
    }
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(
        Constant.editJobVacancyAdmin,
        formData
      );
      setLoading(false);
      dispatch(editVacancyData(data.data));
      setTimeout(() => handleCloseIcon(), 2000);
      setOpenSnackBar(true);
      setSnackBarMessage(data.message);
    } catch (error: any) {
      setLoading(false);
      setOpenSnackBar(true);
      setSnackBarMessage(error.data.message);
    }
  };

  return {
    description,
    expectedSalary,
    experience,
    noOfPosition,
    qualification,
    removeFile,
    selectedFile,
    selectedVacancy,
    setDescription,
    setExpectedSalary,
    setExperience,
    setNoOfPosition,
    setQualification,
    setRemoveFile,
    setSelectedFile,
    setWorkAs,
    workAs,
    error,
    selectedFileError,
    handleCloseSnackBar,
    openSnackBar,
    snackBarMessage,
    loading,
    validation,
    handleSelectFile,
    onDeleteIcon,
    selectedCategory,
    selectMainCategory,
    selectSubMainCategory,
    setSelectedCategory,
    setSelectMainCategory,
    setSelectSubMainCategory,
  };
};

export default editVacancySliderController;
