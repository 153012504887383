import React, { memo } from "react";
import {
  CircularProgress as MUICircularProgress,
  CircularProgressProps,
} from "@mui/material";

const CircularProgress: React.FC<CircularProgressProps> = (props) => {
  return <MUICircularProgress {...props} />;
};

export default memo(CircularProgress);
