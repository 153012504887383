import { SupportDataProps } from "./support.interface";

export const supportData: SupportDataProps[] = [
  {
    id: 1,
    profileImage: "/broken-image.jpg",
    userName: "Shannon Carter",
    date: "22 dec 2022",
    complaint: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
    resolved: true,
  },
  {
    id: 2,
    profileImage: "/broken-image.jpg",
    userName: "Shannon Carter",
    date: "22 dec 2022",
    complaint: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
    resolved: false,
  },
  {
    id: 3,
    profileImage: "/broken-image.jpg",
    userName: "Shannon Carter",
    date: "22 dec 2022",
    complaint: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
    resolved: true,
  },
  {
    id: 4,
    profileImage: "/broken-image.jpg",
    userName: "Shannon Carter",
    date: "22 dec 2022",
    complaint: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
    resolved: false,
  },
  {
    id: 5,
    profileImage: "/broken-image.jpg",
    userName: "Shannon Carter",
    date: "22 dec 2022",
    complaint: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
    resolved: true,
  },
  {
    id: 6,
    profileImage: "/broken-image.jpg",
    userName: "Shannon Carter",
    date: "22 dec 2022",
    complaint: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
    resolved: false,
  },
];
