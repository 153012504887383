import { Box, styled } from "@mui/material";
import { Button, Container, SearchInput, Text } from "@component";

export const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(10, 10),
  background: theme.palette.common.white,
  flexDirection: "column",
}));

export const StyledText = styled(Text)(({ theme }) => ({
  flex: 1,
  margin: theme.spacing(4),
  color: theme.palette.primary.main,
}));

export const StyledProgressContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "80vh",
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
}));

export const StyledSearchInput = styled(SearchInput)(({ theme }) => ({
  marginTop: theme.spacing(6),
  [theme.breakpoints.down("sm")]: {
    marginTop: theme.spacing(6),
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  width: "15%",
  height: 45,
  marginLeft: theme.spacing(4),
  [theme.breakpoints.down("lg")]: {
    width: "30%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "45%",
  },
}));
