import { useEffect, useState } from "react";
import { axiosInstance } from "@services/api/api";
import {
  checkEmail,
  checkMobileNumber,
  checkString,
} from "@utils/validation/validation";
import { Company } from "@services/redux/userList/interface";
import {
  CompanyUserProfileStepOneProps,
  CompnayUserProfileStepOneControllerProps,
  ValidationProps,
} from "./companyUserProfileStepOne.interface";
import { Constant } from "@services/constant";
import { editCompanyDetails } from "@services/redux/addCompanyUser/action";
import { useAppDispatch, useAppSelector } from "@services/redux/controller";
import validationMessage from "@utils/validation/validationMessage";

const compnayUserProfileStepOneController = ({
  handleNext,
  companyUserId,
}: CompanyUserProfileStepOneProps): CompnayUserProfileStepOneControllerProps => {
  const [legalFirmName, setLegalFirmName] = useState<string>();
  const [ownerName, setOwnerName] = useState<string>();
  const [companyEmail, setCompanyEmail] = useState<string>();
  const [mobileNo, setMobileNo] = useState<number | string>();
  const [address, setAddress] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ValidationProps>({
    legalFirmName: null,
    ownerName: null,
    companyEmail: null,
    mobileNo: null,
    address: null,
  });
  const { companyUserList } = useAppSelector((state) => state.userListReducer);
  const { editCompanyDetailsData } = useAppSelector(
    (state) => state.editCompanyUserReducer
  );
  let editData: Company[] = companyUserList.filter(
    (item: Company) => item._id === companyUserId
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (companyUserId) {
      setLegalFirmName(editData[0].legalFirmName);
      setOwnerName(editData[0].ownerName);
      setCompanyEmail(editData[0].companyEmail);
      setMobileNo(editData[0].companyMobileNumber);
      setAddress(editData[0].address);
    }
  }, []);

  const saveEditedData = (): void => {
    let temp = { ...editCompanyDetailsData };
    temp.legalFirmName = legalFirmName;
    temp.ownerName = ownerName;
    temp.companyEmail = companyEmail;
    temp.companyMobileNo = mobileNo;
    temp.address = address;
    dispatch(editCompanyDetails(temp));
  };

  const userId: string | null = localStorage.getItem("addCompanyUserId");

  const formData = {
    id: userId,
    legalFirmName: legalFirmName && legalFirmName,
    ownerName: ownerName && ownerName,
    companyMobileNumber: mobileNo && mobileNo,
    companyEmail: companyEmail && companyEmail,
    address: address && address,
  };

  const companyUserRegistrationStepTwo = async () => {
    /* API call for company user registration step two */
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(
        Constant.companyRegisterStep2,
        formData
      );
      setLoading(false);
      handleNext();
    } catch (error) {
      setLoading(false);
    }
  };

  const validation = (): void => {
    /* Validation for company details */
    let isValidate = true;
    if (legalFirmName) {
      if (!legalFirmName) {
        isValidate = false;
        error.legalFirmName = validationMessage.validationLegalFirmName;
      } else {
        error.legalFirmName = null;
      }
    }
    if (ownerName) {
      if (!ownerName) {
        isValidate = false;
        error.ownerName = validationMessage.validationOwnerName;
      } else if (!checkString(ownerName)) {
        isValidate = false;
        error.ownerName = validationMessage.validationValidOwnerName;
      } else {
        error.ownerName = null;
      }
    }
    if (companyEmail) {
      if (!companyEmail) {
        isValidate = false;
        error.companyEmail = validationMessage.validationEmail;
      } else if (!checkEmail(companyEmail)) {
        isValidate = false;
        error.companyEmail = validationMessage.validationValidEmail;
      } else {
        error.companyEmail = null;
      }
    }
    if (mobileNo) {
      if (!mobileNo) {
        isValidate = false;
        error.mobileNo = validationMessage.validationMobileNo;
      } else if (!checkMobileNumber(mobileNo.toString())) {
        isValidate = false;
        error.mobileNo = validationMessage.validationValidMobileNo;
      } else {
        error.mobileNo = null;
      }
    }
    if (address) {
      if (!address.trim()) {
        isValidate = false;
        error.address = validationMessage.validationAddress;
      } else {
        error.address = null;
      }
    }
    setError({ ...error });
    if (isValidate) {
      if (!companyUserId) {
        companyUserRegistrationStepTwo();
      } else {
        saveEditedData();
        handleNext();
      }
    }
  };

  return {
    legalFirmName,
    setLegalFirmName,
    ownerName,
    setOwnerName,
    companyEmail,
    setCompanyEmail,
    mobileNo,
    setMobileNo,
    address,
    setAddress,
    validation,
    error,
    loading,
  };
};

export default compnayUserProfileStepOneController;
