import { memo } from "react";
import { CircularProgress, useTheme } from "@mui/material";
import { NumberOfUsersCardProps } from "./numberOfUsersCard.interface";
import Text from "@component/text/Text";
import { StyledContainer } from "./numberOfUsersCard.style";

const NumberOfUsersCard: React.FC<NumberOfUsersCardProps> = ({
  totalNumber,
  title,
  loading,
  onUserCount,
}) => {
  const theme = useTheme();
  return (
    <StyledContainer sx={{ boxShadow: 3 }} onClick={onUserCount}>
      {loading ? (
        <CircularProgress size={20} />
      ) : (
        totalNumber && (
          <Text variant="h6" color={theme.palette.primary.main}>
            {totalNumber}
          </Text>
        )
      )}
      {title && (
        <Text
          variant="h6"
          color={theme.palette.primary.main}
          sx={{ wordBreak: "break-word" }}
        >
          {title}
        </Text>
      )}
    </StyledContainer>
  );
};

export default memo(NumberOfUsersCard);
