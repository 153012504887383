import React, { memo } from "react";
import { Add } from "@mui/icons-material";
import { CompanyMachineProcessCard } from "@component";
import EditPortfolioProcess from "./editPortfolioProcess/EditPortfolioProcess";
import portfolioProcessController from "./portfolioProcess.controller";
import { PortfolioProcessProps } from "./portfolioProcess.interface";
import {
  StyledContainer,
  StyledFabButton,
  StyledFabButtonContainer,
  StyledList,
  StyledListContainer,
  StyledText,
} from "../portfolioMachine/portfolioMachine.style";

const PortfolioProcess: React.FC<PortfolioProcessProps> = ({ userId }) => {
  const {
    processData,
    handleCloseDrawer,
    handleOpenDrawer,
    index,
    openDrawer,
    removeProcess,
    loading,
    processId,
  } = portfolioProcessController({ userId });
  return (
    <>
      <StyledFabButtonContainer>
        <StyledFabButton color="primary" onClick={() => handleOpenDrawer()}>
          <Add />
        </StyledFabButton>
      </StyledFabButtonContainer>
      {processData.length !== 0 ? (
        <StyledListContainer>
          <StyledList>
            {processData?.map((item) => (
              <CompanyMachineProcessCard
                item={item}
                key={item._id}
                onEditIcon={(id) => handleOpenDrawer(id)}
                onDeleteIcon={(id) => removeProcess(id)}
                loading={loading}
                id={processId}
              />
            ))}
          </StyledList>
        </StyledListContainer>
      ) : (
        <StyledContainer>
          <StyledText>Nothing to show here</StyledText>
        </StyledContainer>
      )}
      {openDrawer && (
        <EditPortfolioProcess
          openDrawer={openDrawer}
          handleCloseIcon={handleCloseDrawer}
          processId={index}
          userId={userId}
        />
      )}
    </>
  );
};

export default memo(PortfolioProcess);
