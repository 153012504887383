import React, { memo } from "react";
import { Box } from "@mui/material";
import { CompanyProductsCardProps } from "./companyProductsCard.interface";
import { Constant } from "@services/constant";
import { Delete, Edit } from "@mui/icons-material";
import { image } from "@assets";
import {
  StyledCircularProgress,
  StyledContainer,
  StyledIconButton,
  StyledIconContainer,
  StyledPlaceholder,
  StyledText,
} from "../companyMachineProcessCard/companyMachineProcessCard.style";

const CompanyProductsCard: React.FC<CompanyProductsCardProps> = ({
  item,
  onEditIcon,
  onDeleteIcon,
  loading,
  id,
}) => {
  return (
    <StyledContainer>
      <StyledPlaceholder
        src={
          item.fileUrl !== "files/" && item.fileUrl !== ""
            ? `${Constant.baseUrl}${item.fileUrl}`
            : image.placeholder
        }
      />
      <Box flex={0.9}>
        <StyledText variant="h6">{item.productName}</StyledText>
        <StyledText>{item.tags}</StyledText>
        <StyledText>{item.description}</StyledText>
      </Box>
      <StyledIconContainer>
        <StyledIconButton onClick={() => onEditIcon(item._id)}>
          <Edit color="secondary" />
        </StyledIconButton>
        {loading && item._id === id ? (
          <StyledCircularProgress size={18} color="secondary" />
        ) : (
          <StyledIconButton onClick={() => onDeleteIcon(item._id)}>
            <Delete color="secondary" />
          </StyledIconButton>
        )}
      </StyledIconContainer>
    </StyledContainer>
  );
};

export default memo(CompanyProductsCard);
