import Button from "@component/button/Button";
import { Box, styled } from "@mui/material";

export const StyledContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: theme.spacing(6),
  background: "rgba(0,0,0,0.2)",
}));

export const StyledUserProfile = styled("img")(({ theme }) => ({
  height: 50,
  width: 50,
  borderRadius: theme.spacing(12),
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  height: 0,
  borderBottom: "1px solid",
  borderRadius: 0,
  padding: theme.spacing(4, 0),
}));
