import React, { memo } from "react";
import { CheckBox } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { SnackBar } from "@component";
import userProfileStepTwoController from "./userProfileStepTwo.controller";
import { UserProfileStepTwoProps } from "./userProfileStepTwo.interface";
import {
  StyledSelectionButton,
  StyledChooseButton,
  StyledInput,
  StyledText,
  StyledTitleText,
  StyledSalaryText,
  StyledContainer,
  StyledButtonContainer,
  StyledButton,
  StyledErrorText,
  StyledFileInput,
  StyledLabel,
  StyledChooseFileContainer,
  StyledFileText,
  StyledPdfIcon,
} from "./userProfileStepTwo.style";

const UserProfileStepTwo: React.FC<UserProfileStepTwoProps> = ({
  handleFinish,
  userId,
}) => {
  const theme = useTheme();
  const {
    salary,
    setSalary,
    select,
    setSelect,
    validation,
    error,
    resumeError,
    handleResumeChange,
    handlePortfolioChange,
    portfolioError,
    openSnackBar,
    handleCloseSnackBar,
    snackBarMessage,
    resume,
    portfolio,
    loading,
  } = userProfileStepTwoController({ handleFinish, userId });
  return (
    <StyledContainer>
      <StyledSalaryText>Expected Salary</StyledSalaryText>
      <StyledInput
        variant="outlined"
        placeholder="Expected Salary(Per Month)"
        InputLabelProps={{ style: { color: theme.palette.common.black } }}
        value={salary}
        onChange={(val) => setSalary(val?.target?.value)}
        helperText={error.salary}
      />
      <StyledTitleText variant="h6">Work as</StyledTitleText>
      <StyledSelectionButton
        onClick={() => setSelect("1")}
        startIcon={<CheckBox color={select === "1" ? "primary" : "disabled"} />}
      >
        <StyledText>Full Time</StyledText>
      </StyledSelectionButton>
      <StyledSelectionButton
        onClick={() => setSelect("0")}
        startIcon={<CheckBox color={select === "0" ? "primary" : "disabled"} />}
      >
        <StyledText>Part Time</StyledText>
      </StyledSelectionButton>
      <StyledErrorText>{error.select}</StyledErrorText>
      <StyledTitleText variant="h6" sx={{ mt: theme.spacing(12) }}>
        Upload Resume (Optional)
      </StyledTitleText>
      <StyledFileInput
        id="selectResume"
        type="file"
        onChange={handleResumeChange}
      />
      <StyledChooseFileContainer>
        <StyledLabel htmlFor="selectResume">
          <StyledChooseButton>
            {resume ? "Selected file" : "Choose file"}
          </StyledChooseButton>
        </StyledLabel>
        {resume && (
          <StyledFileText>
            <StyledPdfIcon />
            {resume.name ?? resume}
          </StyledFileText>
        )}
      </StyledChooseFileContainer>
      <StyledErrorText>{resumeError}</StyledErrorText>
      {/* <StyledTitleText variant="h6">
        Upload Portfolio (Optional)
      </StyledTitleText>
      <StyledFileInput
        id="selectPortfolio"
        type="file"
        onChange={handlePortfolioChange}
      />
      <StyledChooseFileContainer>
        <StyledLabel htmlFor="selectPortfolio">
          <StyledChooseButton>
            {portfolio ? "Selected file" : "Choose file"}
          </StyledChooseButton>
        </StyledLabel>
        {portfolio && (
          <StyledFileText>
            <StyledPdfIcon />
            {portfolio.name ?? portfolio}
          </StyledFileText>
        )}
      </StyledChooseFileContainer>
      <StyledErrorText>{portfolioError}</StyledErrorText> */}
      <SnackBar
        open={openSnackBar}
        onClose={handleCloseSnackBar}
        onCloseClick={handleCloseSnackBar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        message={snackBarMessage}
      />
      <StyledButtonContainer>
        <StyledButton
          onClick={() => {
            validation();
            handleFinish;
          }}
          loading={loading}
        >
          Finish
        </StyledButton>
      </StyledButtonContainer>
    </StyledContainer>
  );
};

export default memo(UserProfileStepTwo);
