import { QualificationReducerProps } from "./interface";
import type from "./type";

const initialState: QualificationReducerProps = {
  qualifications: [],
};

const qualificationReducer = (
  state: QualificationReducerProps = initialState,
  action: any
) => {
  switch (action.type) {
    case type.getQualifications:
      return {
        ...state,
        qualifications: action.payload,
      };
    case type.addQualifications: {
      const data = [...state.qualifications, action.payload];
      return {
        ...state,
        qualifications: data,
      };
    }
    case type.updateQualifications: {
      const data = state.qualifications.map((item) =>
        item._id === action.payload._id ? action.payload : item
      );
      return {
        qualifications: data,
      };
    }
    case type.removeQualifications:
      return {
        qualifications: action.payload,
      };

    default:
      return state;
  }
};

export default qualificationReducer;
