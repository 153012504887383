import { axiosInstance } from "@services/api/api";
import { Constant } from "@services/constant";
import type from "./type";

const getQualifications = (payload: any) => ({
  type: type.getQualifications,
  payload,
});

const updateQualifications = (payload: any) => ({
  type: type.updateQualifications,
  payload,
});

const addQualifications = (payload: any) => ({
  type: type.addQualifications,
  payload,
});

const removeQualifications = (payload: any) => ({
  type: type.removeQualifications,
  payload,
});

const getQualificationsData = () => {
  /* API call for getting qualifications data */
  return async (dispatch: any) => {
    try {
      const { data } = await axiosInstance.get(Constant.getQualifications);
      dispatch(getQualifications(data.data));
    } catch (error) {}
  };
};

export {
  getQualifications,
  removeQualifications,
  getQualificationsData,
  updateQualifications,
  addQualifications,
};
