import { Box, styled } from "@mui/material";
import Button from "@component/button/Button";
import Text from "@component/text/Text";

export const StyledContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: theme.spacing(8, 0),
  borderBottom: `${theme.spacing(1)} solid`,
  [theme.breakpoints.down("md")]: {
    alignItems: "flex-start",
  },
}));

export const StyledEmailText = styled(Text)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  wordBreak: "break-word",
  width: "30%",
  marginLeft: theme.spacing(10),
  [theme.breakpoints.down("md")]: {
    flex: 1,
  },
}));

export const StyledTextWithBackground = styled(Text)(({ theme }) => ({
  background: theme.palette.primary.main,
  padding: theme.spacing(2, 18),
  marginLeft: theme.spacing(12),
  borderRadius: theme.spacing(2),
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(2, 14),
    marginLeft: theme.spacing(16),
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  border: `${theme.spacing(1)} solid`,
  marginLeft: theme.spacing(18),
  [theme.breakpoints.down("md")]: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(16),
  },
}));

export const StyledAdminBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
