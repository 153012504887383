import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TableControllerProps } from "./table.interface";

const tableController = (): TableControllerProps => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const navigation = useNavigate();

  const handleUserDetails = (id: string, navigationTo: string): void => {
    navigation(`${id}/${navigationTo}`);
  };

  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    handleUserDetails,
  };
};

export default tableController;
