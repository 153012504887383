import type from "./type";

const getProductData = (payload: any) => ({
  type: type.getProductData,
  payload,
});

const getJobWorkData = (payload: any) => ({
  type: type.getJobWorkData,
  payload,
});

const getVacancyData = (payload: any) => ({
  type: type.getVacancyData,
  payload,
});

const addProductData = (payload: any) => ({
  type: type.addProductData,
  payload,
});

const addJobWorkData = (payload: any) => ({
  type: type.addJobWorkData,
  payload,
});

const addVacancyData = (payload: any) => ({
  type: type.addVacancyData,
  payload,
});

const editProductData = (payload: any) => ({
  type: type.editProductData,
  payload,
});

const editJobWorkData = (payload: any) => ({
  type: type.editJobWorkData,
  payload,
});

const editVacancyData = (payload: any) => ({
  type: type.editVacancyData,
  payload,
});

const removeProductData = (payload: any) => ({
  type: type.removeProductData,
  payload,
});

const removeVacancyData = (payload: any) => ({
  type: type.removeVacancyData,
  payload,
});

const removeJobWorkData = (payload: any) => ({
  type: type.removeJobWorkData,
  payload,
});

export {
  getProductData,
  getJobWorkData,
  getVacancyData,
  addProductData,
  addJobWorkData,
  addVacancyData,
  editProductData,
  editJobWorkData,
  editVacancyData,
  removeProductData,
  removeVacancyData,
  removeJobWorkData,
};
