import React, { memo } from "react";
import { Drawer as MUIDrawer, DrawerProps } from "@mui/material";
import { BoxContainer } from "./drawer.style";

const Drawer: React.FC<DrawerProps> = (props) => {
  return (
    <MUIDrawer {...props}>
      <BoxContainer>{props.children}</BoxContainer>
    </MUIDrawer>
  );
};

export default memo(Drawer);
