import type from "./type";

const getPortfolioProductData = (payload: any) => ({
  type: type.getPortfolioProductData,
  payload,
});

const getPortfolioJobWorkData = (payload: any) => ({
  type: type.getPortfolioJobWorkData,
  payload,
});

const editPortfolioProductData = (payload: any) => ({
  type: type.editPortfolioProductData,
  payload,
});

const editPortfolioJobWorkData = (payload: any) => ({
  type: type.editPortfolioJobWorkData,
  payload,
});

const addPortfolioProductData = (payload: any) => ({
  type: type.addPortfolioProductData,
  payload,
});

const addPortfolioJobWorkData = (payload: any) => ({
  type: type.addPortfolioJobWorkData,
  payload,
});

const removePortfolioProductData = (payload: any) => ({
  type: type.removePortfolioProductData,
  payload,
});

const removePortfolioJobWorkData = (payload: any) => ({
  type: type.removePortfolioJobWorkData,
  payload,
});

export {
  addPortfolioJobWorkData,
  addPortfolioProductData,
  editPortfolioJobWorkData,
  editPortfolioProductData,
  getPortfolioJobWorkData,
  getPortfolioProductData,
  removePortfolioJobWorkData,
  removePortfolioProductData,
};
