export default {
  validationEmpty: undefined,
  validationFirstName: "Please enter first name",
  validationValidFirstName: "Please enter valid first name",
  validationLastName: "Please enter last name",
  validationValidLastName: "Please enter valid last name",
  validationEmail: "Please enter your email",
  validationMobileNo: "Please enter your mobile number",
  validationValidMobileNo: "Please enter valid mobile number",
  validationPassword: "Please enter your password",
  validationValidEmail: "Please enter valid email address",
  validationValidPassword: "Please enter valid password",
  validationNewPassword: "Please enter New Password",
  validationValidNewPassword: "Please enter valid New password",
  validationConfirmPassword:
    "Password length must be greater than 8 characters",
  validationValidConfirmPassword: "Please enter valid Confirm Password",
  validationPassConfirmPassNotMatch:
    "Password and Confirm Password does not match",
  validationQualification: "Please select your qualification",
  validationCourseName: "Please enter course name",
  validationValidCourseName: "Please enter valid course name",
  validationCompanyName: "Please enter company name",
  validationValidCompanyName: "Please enter valid company name",
  validationSalaryMessage: "Please enter your expected salary",
  validationValidSalaryMessage: "Please enter valid expected salary",
  validationSelectWorkMessage: "Please select your work time",
  validationQualificationMessage: "Please select your Qualification",
  validationExpertiseMessage: "Please enter your expertise",
  validationValidExpertiseMessage: "Please enter valid expertise",
  validationExperienceMessage: "Please enter your experience",
  validationValidExperienceMessage: "Please enter valid experience",
  validationOwnerName: "Please enter owner name",
  validationValidOwnerName: "Please enter valid owner name",
  validationAddress: "Please enter your address",
  validationGSTNumber: "Please enter your GST number",
  validationValidGSTNumber: "Please enter valid GST number",
  validatonAnnualTurnOver: "Please enter your annual turnover",
  validatonValidAnnualTurnOver: "Please enter valid annual turnover",
  validatonNoOfEmployees: "Please enter number of employees",
  validatonValidNoOfEmployees: "Please enter valid number of employees",
  validatonWebsiteLink: "Please enter your website link",
  validatonValidWebsiteLink: "Please enter valid website link",
  validationSelectCompanyTypeMessage: "Please select your company type",
  validationAboutCompany: "Please enter something about your company",
  validationValidAboutCompany:
    "Please enter something valid about your company",
  validationSelectQualification: "Please select your qualification",
  validationLegalFirmName: "Please enter legal firm name",
  validationValidLegalFirmName: "Please enter valid legal firm name",
  validationPasswordOldAndNew: "New and old password should not be same",
  validationValidQualification: "Please enter valid qualification",
  validationZone: "Please enter your zone",
  validationValidZone: "Please enter valid zone",
  productNameError: "Please enter product Name",
  productTagError: "Please enter product Tag",
  validationDescription: "Please enter Description",
  validationNoOfProductError: "Please enter No. of Product",
  validationValidNoOfProductError: "Please enter valid No. of Product",
  validationSelectedFileError: "Please choose valid file",
  validationSelectedExpertise: "Please Select Expertise",
  qualificationValidation: "Please enter your qualification",
  noOfPositionValidation: "Please enter number of position",
  validNoOfPositionValidation: "Please enter valid number of position",
  approxSalaryValidation: "Please enter approx salary",
  validApproxSalaryValidation: "Please enter valid approx salary",
  workAsValidation: "Please select Work Type",
  selectMachineValidation: "Please select Machine",
  noOfMachineValidation: "Please enter no. of Machine",
  validNoOfMachineValidation: "Please enter valid no. of Machine",
  selectProcessValidation: "Please select Process",
  noOfProcessValidation: "Please enter no. of Process",
  validNoOfProcessValidation: "Please enter valid no. of Process",
};
