import { useState } from "react";
import { CompanyPortfolioControllerProps } from "../../companyPortfolio/companyPortfolio.interface";

const jobWorkController = (): CompanyPortfolioControllerProps => {
  const [select, setSelect] = useState<number>(1);

  return { select, setSelect };
};

export default jobWorkController;
