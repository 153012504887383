import React, { memo } from "react";
import { useTheme } from "@mui/material";
import addAdminSliderController from "./addAdminSlider.controller";
import { addAdminSliderProps } from "./addAdminSlider.interface";
import { image } from "@assets";
import Slider from "../../../../component/slider/Slider";
import SnackBar from "@component/snackBar/SnackBar";
import {
  StyledButton,
  StyledEditIcon,
  StyledErrorText,
  StyledFileInput,
  StyledIconButton,
  StyledInput,
  StyledLabel,
  StyledUserProfile,
} from "./addAdminSlider.style";

const AddAdminSlider: React.FC<addAdminSliderProps> = ({
  handleCloseIcon,
  openDrawer,
}) => {
  const theme = useTheme();
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    error,
    validation,
    loading,
    openSnackBar,
    snackBarMessage,
    setOpenSnackBar,
    handleProfileImage,
    profileImage,
    profileImageError,
  } = addAdminSliderController({ openDrawer, handleCloseIcon });
  return (
    <Slider
      openDrawer={openDrawer}
      handleCloseIcon={handleCloseIcon}
      title="Add New Admin"
    >
      <StyledFileInput
        id="selectPortfolio"
        type="file"
        onChange={handleProfileImage}
      />
      <StyledLabel htmlFor="selectPortfolio">
        <StyledIconButton>
          <StyledEditIcon />
        </StyledIconButton>
      </StyledLabel>
      <StyledUserProfile
        src={
          profileImage ? URL.createObjectURL(profileImage) : image.userProfile
        }
      />
      <StyledErrorText>{profileImageError}</StyledErrorText>
      <StyledInput
        variant="outlined"
        placeholder="First Name"
        InputLabelProps={{ style: { color: theme.palette.common.black } }}
        value={firstName}
        onChange={(val) => setFirstName(val?.target?.value)}
        helperText={error.firstName}
      />
      <StyledInput
        variant="outlined"
        placeholder="Last Name"
        InputLabelProps={{
          style: { color: theme.palette.common.black },
        }}
        value={lastName}
        onChange={(val) => setLastName(val?.target?.value)}
        helperText={error.lastName}
      />
      <StyledInput
        variant="outlined"
        placeholder="Email"
        InputLabelProps={{ style: { color: theme.palette.common.black } }}
        value={email}
        onChange={(val) => setEmail(val?.target?.value)}
        helperText={error.email}
      />
      <StyledInput
        variant="outlined"
        placeholder="Password"
        InputLabelProps={{ style: { color: theme.palette.common.black } }}
        value={password}
        onChange={(val) => setPassword(val?.target?.value)}
        helperText={error.password}
        showPasswordIcon
      />
      <StyledInput
        variant="outlined"
        placeholder="Confirm Password"
        InputLabelProps={{ style: { color: theme.palette.common.black } }}
        value={confirmPassword}
        onChange={(val) => setConfirmPassword(val?.target?.value)}
        helperText={error.confirmPassword}
        showPasswordIcon
      />
      <SnackBar
        open={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        onCloseClick={() => setOpenSnackBar(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        message={snackBarMessage}
        autoHideDuration={6000}
      />
      <StyledButton
        onClick={() => validation()}
        loading={loading}
        progressColor="info"
      >
        Add
      </StyledButton>
    </Slider>
  );
};

export default memo(AddAdminSlider);
