import React from "react";
import { useTheme } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import userDetailsController from "./userDetails.controller";
import { UserProfileDetails } from "@component";
import VacancyList from "./vacancyList/VacancyList";
import {
  StyledButton,
  StyledContainer,
  StyledDetailsContainer,
  StyledText,
} from "./userDetails.style";

const UserDetails: React.FC = () => {
  const theme = useTheme();
  const { goBack, userDetails } = userDetailsController();
  return (
    <StyledContainer>
      <StyledButton
        startIcon={<ChevronLeft color="primary" />}
        variant="text"
        onClick={goBack}
      >
        Back
      </StyledButton>
      <StyledText variant="h5" mt={theme.spacing(10)}>
        Profile Details
      </StyledText>
      <StyledDetailsContainer>
        <UserProfileDetails
          userDetailsData={userDetails}
          profileImage={userDetails[0].profileImage}
          firstName={userDetails[0].firstName}
          lastName={userDetails[0].lastName ?? ""}
          email={userDetails[0].email}
          isAccountVerified={userDetails[0].isAccountVerified}
        />
      </StyledDetailsContainer>
    </StyledContainer>
  );
};

export default UserDetails;
