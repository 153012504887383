import React, { useState } from "react";
import { Box, Theme, Toolbar, useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Outlet } from "react-router-dom";
import { Drawer as MUIDrawer, DrawerItem, Text } from "@component";
import { drawerData } from "./drawer.const";
import { HeaderMobile } from "./Drawer.style";

const Drawer: React.FC = () => {
  const drawerWidth = 240;
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const handleCloseMenu = () => {
    setOpenDrawer(!openDrawer);
  };
  const isLoggedIn = sessionStorage.getItem("isLoggedIn");
  if (isLoggedIn) {
    return (
      <Box display="flex">
        {isMobile && (
          <HeaderMobile>
            <Text flex={1}>BSetuAdmin</Text>
            <MenuIcon onClick={handleCloseMenu} color={"info"} />
          </HeaderMobile>
        )}
        <Box
          component="nav"
          sx={{
            width: { sm: drawerWidth },
            flexShrink: { sm: 0 },
          }}
        >
          <MUIDrawer
            open
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                overflow: "hidden",
              },
            }}
          >
            <DrawerItem data={drawerData} />
          </MUIDrawer>
          <MUIDrawer
            open={openDrawer}
            variant="temporary"
            onClose={handleCloseMenu}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                overflow: "hidden",
              },
            }}
          >
            <DrawerItem data={drawerData} onItemClick={handleCloseMenu} />
          </MUIDrawer>
        </Box>
        <Box
          flexGrow={1}
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)`, xs: "100%" },
          }}
        >
          {isMobile && <Toolbar />}
          <Box flex={1} height={"100vh"}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    );
  } else {
    // redirect to login page if not logged in
    window.location.href = "/";
    return null;
  }
};

export default Drawer;
