import { Box, styled } from "@mui/material";
import { Text } from "@component";

export const StyledContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "70vh",
}));

export const StyledText = styled(Text)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 22,
}));
