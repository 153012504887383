import React, { memo } from "react";
import { ButtonProps } from "@mui/material";
import { SettingsBarProps } from "./settingsBar.interface";
import { StyledButton } from "./settingsBar.style";

const SettingsBar: React.FC<SettingsBarProps & ButtonProps> = (props) => {
  return (
    <StyledButton variant="contained" {...props}>
      {props.item.title}
    </StyledButton>
  );
};

export default memo(SettingsBar);
