import React, { memo } from "react";
import { useTheme } from "@mui/material";
import compnayUserProfileStepOneController from "./companyUserProfileStepOne.controller";
import { CompanyUserProfileStepOneProps } from "./companyUserProfileStepOne.interface";
import {
  StyledButton,
  StyledButtonContainer,
  StyledContainer,
  StyledInput,
} from "./companyUserProfileStepOne.style";

const CompanyUserProfileStepOne: React.FC<CompanyUserProfileStepOneProps> = ({
  handleNext,
  companyUserId,
}) => {
  const theme = useTheme();
  const {
    legalFirmName,
    setLegalFirmName,
    ownerName,
    setOwnerName,
    companyEmail,
    setCompanyEmail,
    mobileNo,
    setMobileNo,
    address,
    setAddress,
    validation,
    error,
    loading,
  } = compnayUserProfileStepOneController({
    handleNext,
    companyUserId,
  });
  return (
    <StyledContainer>
      <StyledInput
        variant="outlined"
        placeholder="Legal Firm Name"
        InputLabelProps={{ style: { color: theme.palette.common.black } }}
        value={legalFirmName}
        onChange={(val) => setLegalFirmName(val?.target?.value)}
        helperText={error.legalFirmName}
      />
      <StyledInput
        variant="outlined"
        placeholder="Owner Name"
        InputLabelProps={{ style: { color: theme.palette.common.black } }}
        value={ownerName}
        onChange={(val) => setOwnerName(val?.target?.value)}
        helperText={error.ownerName}
      />
      <StyledInput
        variant="outlined"
        placeholder="Company Email"
        InputLabelProps={{ style: { color: theme.palette.common.black } }}
        value={companyEmail}
        onChange={(val) => setCompanyEmail(val?.target?.value)}
        helperText={error.companyEmail}
      />
      <StyledInput
        variant="outlined"
        placeholder="Mobile Number"
        InputLabelProps={{ style: { color: theme.palette.common.black } }}
        value={mobileNo}
        onChange={(val) => setMobileNo(val.target.value.slice(0, 10))}
        helperText={error.mobileNo}
      />
      <StyledInput
        variant="outlined"
        placeholder="Address"
        InputLabelProps={{ style: { color: theme.palette.common.black } }}
        value={address}
        onChange={(val) => setAddress(val?.target?.value)}
        helperText={error.address}
      />
      <StyledButtonContainer>
        <StyledButton
          onClick={() => {
            validation();
            handleNext;
          }}
          loading={loading}
        >
          Next
        </StyledButton>
      </StyledButtonContainer>
    </StyledContainer>
  );
};

export default memo(CompanyUserProfileStepOne);
