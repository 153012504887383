import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import aboutUsController from "./aboutUs.controller";
import { CircularProgress, SnackBar } from "@component";
import {
  StyledButton,
  StyledContainer,
  StyledEditorContainer,
  StyledProgressContainer,
  StyledText,
} from "./aboutUs.style";

const AboutUs: React.FC = () => {
  const {
    aboutUs,
    handleAboutUs,
    loading,
    addOrEditAboutUs,
    aboutUsId,
    getDataLoading,
    openSnackBar,
    snackBarMessage,
    handleSnackBar,
  } = aboutUsController();
  return (
    <StyledContainer>
      <StyledText variant="h4">About Us</StyledText>
      {getDataLoading ? (
        <StyledProgressContainer>
          <CircularProgress size={40} color={"primary"} />
        </StyledProgressContainer>
      ) : (
        <>
          <StyledEditorContainer>
            <CKEditor
              editor={ClassicEditor}
              data={aboutUs}
              onReady={(editor: any) => {
                editor.editing.view.change((writer: any) => {
                  writer.setStyle(
                    "height",
                    "60vh",
                    editor.editing.view.document.getRoot()
                  );
                });
              }}
              onChange={handleAboutUs}
            />
          </StyledEditorContainer>
          <StyledButton
            loading={loading}
            progressColor="info"
            onClick={() => addOrEditAboutUs()}
          >
            {aboutUsId ? "Edit" : "Save"}
          </StyledButton>
          <SnackBar
            open={openSnackBar}
            onClose={handleSnackBar}
            onCloseClick={handleSnackBar}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            message={snackBarMessage}
            autoHideDuration={6000}
          />
        </>
      )}
    </StyledContainer>
  );
};

export default AboutUs;
