import { useEffect, useMemo, useState } from "react";
import {
  AllUsersControllerProps,
  allUsersDataProps,
} from "./allUsers.interface";
import { axiosInstance } from "@services/api/api";
import { Company } from "@services/redux/userList/interface";
import { Constant } from "@services/constant";
import { getAllUsers } from "@services/redux/userList/action";
import { useAppDispatch, useAppSelector } from "@services/redux/controller";

const allUsersController = (): AllUsersControllerProps => {
  const { allUsers } = useAppSelector((state) => state.userListReducer);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(allUsers.length > 0 ? false : true);
  const [searchValue, setSearchValue] = useState<string>("");
  const [filterData, setFilterData] = useState<allUsersDataProps[]>();

  useEffect(() => {
    getAllUsersData();
  }, []);

  const allUsersData: allUsersDataProps[] = useMemo(() => {
    return allUsers?.map((item: Company) => ({
      _id: item._id,
      profileImage: item.profileImage,
      name: `${item.firstName} ${item.lastName}`,
      email: item.email,
      mobileNumber: item.mobileNumber,
      companyName: item.currentlyWorkingCompanyName ?? item.legalFirmName,
    }));
  }, [allUsers]);

  const filter = (value: string): void => {
    /* Functionality to search users */
    let filterData = allUsersData.filter((item: allUsersDataProps) => {
      let search = [item.name, item.email, item.mobileNumber];
      let regex = search.toString().toLowerCase();
      return regex.includes(value.toLowerCase());
    });
    setSearchValue(value);
    setFilterData(filterData);
  };

  const getAllUsersData = async () => {
    /* API call for getting all Users data */
    try {
      const { data } = await axiosInstance.post(Constant.allUsers, {
        registerType: 2,
      });
      dispatch(getAllUsers(data.data));
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return { loading, searchValue, allUsersData, filterData, filter };
};

export default allUsersController;
