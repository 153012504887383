import { AllCompanyDetailsReducerProps } from "./interface";
import type from "./type";

const initialState: AllCompanyDetailsReducerProps = {
  product: [],
  jobWork: [],
  vacancy: [],
};

const allCompanyDetailsReducer = (
  state: AllCompanyDetailsReducerProps = initialState,
  action: any
) => {
  switch (action.type) {
    case type.getProductData:
      return {
        ...state,
        product: action.payload,
      };
    case type.getJobWorkData:
      return {
        ...state,
        jobWork: action.payload,
      };
    case type.getVacancyData:
      return {
        ...state,
        vacancy: action.payload,
      };
    case type.addProductData:
      const productData = [...state.product, action.payload];
      return {
        ...state,
        product: productData,
      };
    case type.addJobWorkData:
      const jobWorkData = [...state.jobWork, action.payload];
      return {
        ...state,
        jobWork: jobWorkData,
      };
    case type.addVacancyData:
      const vacancyData = [...state.vacancy, action.payload];
      return {
        ...state,
        vacancy: vacancyData,
      };
    case type.editProductData:
      return {
        ...state,
        product: action.payload,
      };
    case type.editJobWorkData:
      return {
        ...state,
        jobWork: action.payload,
      };
    case type.editVacancyData:
      return {
        ...state,
        vacancy: action.payload,
      };
    case type.removeProductData:
      return {
        ...state,
        product: action.payload,
      };
    case type.removeVacancyData:
      return {
        ...state,
        vacancy: action.payload,
      };
    case type.removeJobWorkData:
      return {
        ...state,
        jobWork: action.payload,
      };
    default:
      return state;
  }
};

export default allCompanyDetailsReducer;
