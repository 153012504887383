import { useMemo, useState } from "react";
import { axiosInstance } from "@services/api/api";
import { Constant } from "@services/constant";
import { PortfolioMachineProcessDataProps } from "../portfolioMachine/portfolioMachine.interface";
import {
  PortfolioProcessControllerProps,
  PortfolioProcessProps,
} from "./portfolioProcess.interface";
import { removePortfolioJobWorkData } from "@services/redux/companyPortfolio/action";
import { useAppDispatch, useAppSelector } from "@services/redux/controller";

const portfolioProcessController = ({
  userId,
}: PortfolioProcessProps): PortfolioProcessControllerProps => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [index, setIndex] = useState<string>();
  const [processId, setProcessId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { portfolioJobWork } = useAppSelector(
    (state) => state.companyPortfolioReducer
  );
  const dispatch = useAppDispatch();
  const processData: PortfolioMachineProcessDataProps[] = useMemo(
    () =>
      portfolioJobWork
        ?.filter((item: any) => item.processes)
        .map((item: any) => ({
          _id: item._id,
          title: item.processes,
          description: item.processesJobDescription,
          file: item.processesFiles,
        }))
        .reverse(),
    [portfolioJobWork]
  );

  const handleCloseDrawer = (): void => {
    setOpenDrawer(false);
  };

  const handleOpenDrawer = (id?: string): void => {
    setOpenDrawer(true);
    setIndex(id);
  };

  const removeProcess = async (processId: string) => {
    /* API call for removing process */
    setProcessId(processId);
    const formData = new URLSearchParams();
    formData.append("userId", userId);
    formData.append("type", "1");
    formData.append("jobWorkId", processId);
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(
        Constant.removePortfolioProductJobWork,
        formData.toString()
      );
      setLoading(false);
      dispatch(removePortfolioJobWorkData(data.data));
    } catch (error) {
      setLoading(false);
    }
  };

  return {
    processData,
    handleCloseDrawer,
    openDrawer,
    handleOpenDrawer,
    index,
    removeProcess,
    loading,
    processId,
  };
};

export default portfolioProcessController;
