import { useState } from "react";
import {
  AddAdminSliderControllerProps,
  addAdminSliderProps,
  ValidationProps,
} from "./addAdminSlider.interface";
import { axiosInstance } from "@services/api/api";
import {
  checkEmail,
  checkPassword,
  checkString,
} from "@utils/validation/validation";
import { Constant } from "@services/constant";
import validationMessage from "@utils/validation/validationMessage";

const addAdminSliderController = ({
  handleCloseIcon,
}: addAdminSliderProps): AddAdminSliderControllerProps => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string>("");
  const [profileImage, setProfileImage] = useState<Blob | MediaSource | null>(
    null
  );
  const [profileImageError, setProfileImageError] = useState<string>("");
  const [error, setError] = useState<ValidationProps>({
    firstName: null,
    lastName: null,
    email: null,
    password: null,
    confirmPassword: null,
  });
  const fileType = ["image/png"];

  const handleProfileImage = (e: any): void => {
    /* Functionality to choose image for profile image */
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile && fileType.includes(selectedFile.type)) {
        setProfileImage(selectedFile);
        setProfileImageError("");
      } else {
        setProfileImage(null);
        setProfileImageError("Please select image file");
      }
    } else {
      alert("select your file");
    }
  };

  const onAddAdmin = async () => {
    /* Api call for adding admin */
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(Constant.addAdmin, {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
      });
      setLoading(false);
      setOpenSnackBar(true);
      setSnackBarMessage(data.message);
      setTimeout(() => {
        handleCloseIcon();
        setFirstName("");
        setLastName("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
      }, 3000);
    } catch (error: any) {
      setLoading(false);
      setOpenSnackBar(true);
      setSnackBarMessage(error.data.message);
    }
  };

  const validation = (): void => {
    let isValidate = true;
    if (!firstName) {
      isValidate = false;
      error.firstName = validationMessage.validationFirstName;
    } else if (!checkString(firstName)) {
      isValidate = false;
      error.firstName = validationMessage.validationValidFirstName;
    } else {
      error.firstName = null;
    }
    if (!lastName) {
      isValidate = false;
      error.lastName = validationMessage.validationLastName;
    } else if (!checkString(lastName)) {
      isValidate = false;
      error.lastName = validationMessage.validationValidLastName;
    } else {
      error.lastName = null;
    }
    if (!email) {
      isValidate = false;
      error.email = validationMessage.validationEmail;
    } else if (!checkEmail(email)) {
      isValidate = false;
      error.email = validationMessage.validationValidEmail;
    } else {
      error.email = null;
    }
    if (!password) {
      isValidate = false;
      error.password = validationMessage.validationPassword;
    } else if (!checkPassword(password)) {
      isValidate = false;
      error.password = validationMessage.validationValidPassword;
    } else {
      error.password = null;
    }
    if (!confirmPassword) {
      isValidate = false;
      error.confirmPassword = validationMessage.validationPassword;
    } else if (!checkPassword(confirmPassword)) {
      isValidate = false;
      error.confirmPassword = validationMessage.validationValidPassword;
    } else if (!confirmPassword.match(password)) {
      isValidate = false;
      error.confirmPassword =
        validationMessage.validationPassConfirmPassNotMatch;
    } else {
      error.confirmPassword = null;
    }
    setError({ ...error });
    if (isValidate) {
      onAddAdmin();
    }
  };

  return {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    error,
    validation,
    loading,
    openSnackBar,
    snackBarMessage,
    setOpenSnackBar,
    handleProfileImage,
    profileImage,
    profileImageError,
  };
};

export default addAdminSliderController;
