import React, { memo } from "react";
import { Avatar, Box, IconButton, useTheme } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Text from "@component/text/Text";
import { SupportCardProps } from "./supportCard.interface";
import {
  StyledBox,
  StyledButton,
  StyledButtonContainer,
  StyledPaper,
} from "./supportCard.style";

const SupportCard: React.FC<SupportCardProps> = ({ item }) => {
  const theme = useTheme();
  return (
    <StyledPaper elevation={3}>
      <StyledBox>
        <Avatar src={item.profileImage} />
        <Box ml={theme.spacing(6)} flex={1}>
          <Text variant="h6">{item.userName}</Text>
          <Text fontSize={14}>{item.date}</Text>
        </Box>
        <IconButton>
          <DeleteIcon color="info" />
        </IconButton>
      </StyledBox>
      <Text m={theme.spacing(6, 6)} fontSize={15}>
        {item.complaint}
      </Text>
      <StyledButtonContainer>
        <StyledButton
          variant={item.resolved ? "text" : "contained"}
          disabled={item.resolved ?? false}
        >
          {item.resolved ? "Resolved" : "Reply"}
        </StyledButton>
      </StyledButtonContainer>
    </StyledPaper>
  );
};

export default memo(SupportCard);
