import React, { memo } from "react";
import { IconButton, InputAdornment, TextFieldProps } from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import { StyledTextField } from "./searchInput.style";

const SearchInput: React.FC<TextFieldProps> = (props) => {
  return (
    <StyledTextField
      variant="outlined"
      size="small"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" sx={{ display: "flow" }}>
            <IconButton>
              <SearchOutlined />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

export default memo(SearchInput);
