import { useEffect, useState } from "react";
import { getAllAdmins } from "@services/redux/userList/action";
import { ManageAdminsControllerProps } from "./manageAdmins.interface";
import { ManageAdminsDataProps } from "@component/adminList/adminList.interface";
import { useAppDispatch, useAppSelector } from "@services/redux/controller";

const manageAdminsController = (): ManageAdminsControllerProps => {
  const userList = useAppSelector((state) => state.userListReducer);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [adminsData, setAdminsData] = useState<ManageAdminsDataProps[]>(
    userList?.getAdmins
  );
  const user = useAppSelector((state) => state.userReducer);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setLoading(false);
  }, [userList?.getAdmins]);

  useEffect(() => {
    if (userList?.getAdmins?.length === 0) {
      setLoading(true);
    }
    dispatch(getAllAdmins(user.token) as any);
  }, []);

  useEffect(() => {
    setAdminsData(userList?.getAdmins);
  }, [userList?.getAdmins]);

  const handleOpen = (): void => {
    setOpenModal(true);
  };

  const handleClose = (): void => {
    setOpenModal(false);
  };

  return {
    adminsData,
    openModal,
    handleOpen,
    handleClose,
    loading,
  };
};

export default manageAdminsController;
