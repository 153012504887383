import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { RouterProvider } from "react-router-dom";
import { Providers } from "@setup/providers/Providers";
import router from "./setup/routes";
import store from "@services/redux/store";

const App = () => {
  const persistStore = store();
  return (
    <Provider store={persistStore.store}>
      <PersistGate loading={null} persistor={persistStore.persistor}>
        <Providers>
          <RouterProvider router={router} />
        </Providers>
      </PersistGate>
    </Provider>
  );
};

export default App;
