import { Box, styled } from "@mui/material";
import Text from "@component/text/Text";

export const StyledText = styled(Text)(({ theme }) => ({
  color: theme.palette.primary.main,
  wordBreak: "break-word",
  width: "50%",
}));

export const StyledDetailsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  margin: theme.spacing(4, 0),
}));

export const StyledTitleText = styled(Text)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 15,
  fontWeight: "bold",
  width: "45%",
  marginRight: theme.spacing(10),
  [theme.breakpoints.down("md")]: {
    width: "50%",
  },
}));
