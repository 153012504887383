import { Box, List, styled } from "@mui/material";
import UsersContainer from "@component/usersContainer/UsersContainer";

export const StyledUsersContainer = styled(UsersContainer)(({ theme }) => ({
  [theme.breakpoints.between("xs", "md")]: {
    margin: theme.spacing(8, 0),
  },
}));

export const StyledContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "55%",
  [theme.breakpoints.down("md")]: {
    width: "65%",
  },
  marginBottom: theme.spacing(6),
}));

export const StyledDataContainer = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(10),
}));

export const StyledList = styled(List)(({ theme }) => ({
  maxHeight: "90%",
  overflow: "auto",
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: "-15px",
  overflowY: "scroll",
}));

export const StyledListContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "90%",
  overflow: "hidden",
  position: "relative",
  marginTop: theme.spacing(10),
}));

export const StyledProgressBarContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  alignItems: "flex-end",
}));
