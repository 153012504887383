import React, { memo } from "react";
import { useTheme, Stepper, Step, StepButton } from "@mui/material";
import AddUser from "@pages/home/users/addUserSlider/addUser/AddUser";
import addUserSliderController from "./addUserSlider.controller";
import { AddUserSliderProps } from "./addUserSlider.interface";
import Slider from "../../../../component/slider/Slider";
import { stepsData } from "./addUserSlider.const";
import UserProfileStepOne from "./userProfileStepOne/UserProfileStepOne";
import UserProfileStepTwo from "./userProfileStepTwo/UserProfileStepTwo";
import { StyledContainer } from "./addUserSlider.style";

const AddUserSlider: React.FC<AddUserSliderProps> = ({
  openDrawer,
  handleCloseIcon,
  userId,
}) => {
  const theme = useTheme();
  const { activeStep, completed, handleComplete, resetStepper } =
    addUserSliderController({ handleCloseIcon, openDrawer, userId });

  const getStepContent = (step: number) => {
    switch (step) {
      case 0: {
        return <AddUser handleNextButton={handleComplete} userId={userId} />;
      }
      case 1: {
        return (
          <UserProfileStepOne
            handleNextButton={handleComplete}
            userId={userId}
          />
        );
      }
      case 2: {
        return (
          <UserProfileStepTwo handleFinish={handleComplete} userId={userId} />
        );
      }
    }
  };

  return (
    <Slider
      openDrawer={openDrawer}
      handleCloseIcon={() => {
        handleCloseIcon();
        resetStepper();
      }}
    >
      {openDrawer && (
        <StyledContainer>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            sx={{ mb: theme.spacing(4) }}
          >
            {stepsData.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButton>{label}</StepButton>
              </Step>
            ))}
          </Stepper>
          {getStepContent(activeStep)}
        </StyledContainer>
      )}
    </Slider>
  );
};

export default memo(AddUserSlider);
