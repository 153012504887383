import React, { memo } from "react";
import {
  TableContainer,
  Table as MUITable,
  TableHead,
  TableRow,
  useTheme,
  Paper,
  TableBody,
  TablePagination,
} from "@mui/material";
import TableItemCard from "./tableItemCard/TableItemCard";
import DeleteDialog from "@component/deleteDialog/DeleteDialog";
import tableController from "./table.controller";
import { TableCellDataProps, TableProps } from "./table.interface";
import { StyledContainer, StyledTableCell, StyledText } from "./table.style";

const Table: React.FC<TableProps> = ({
  tableCellData,
  tableData,
  navigationTo,
  isButton,
  buttonText,
  isDeleteButton,
  isEditButton,
  handleEdit,
  openDialog,
  handleCloseDialog,
  handleOpenDialog,
  handleDeleteDialog,
  handleSwitch,
  handleDeleteDialogLoading,
}) => {
  const theme = useTheme();
  const {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    handleUserDetails,
  } = tableController();
  return (
    <>
      {tableData.length !== 0 ? (
        <TableContainer component={Paper} sx={{ mt: theme.spacing(8) }}>
          <MUITable sx={{ minWidth: 700 }}>
            <TableHead>
              <TableRow>
                {tableCellData.map((item: TableCellDataProps) => (
                  <StyledTableCell key={item.id} align="center">
                    {item.title}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item: any, index: number) => (
                  <React.Fragment key={item?._id}>
                    <TableItemCard
                      key={item?._id}
                      item={item}
                      isButton={isButton}
                      buttonText={buttonText}
                      isDeleteButton={isDeleteButton}
                      handleDetails={() =>
                        handleUserDetails(item._id, navigationTo ?? "")
                      }
                      isEditButton={isEditButton}
                      handleDeleteButton={() =>
                        handleOpenDialog && handleOpenDialog(item?._id)
                      }
                      handleEdit={() => handleEdit && handleEdit(item?._id)}
                      onHandleSwitch={(check) => {
                        handleSwitch && handleSwitch(item?._id, check);
                      }}
                      checked={item.status === 0}
                    />
                    <DeleteDialog
                      dialogTitle="Are you sure you want to delete this user?"
                      open={openDialog}
                      onClose={handleCloseDialog}
                      handleDelete={() =>
                        handleDeleteDialog && handleDeleteDialog()
                      }
                      handleCancel={handleCloseDialog}
                      loading={handleDeleteDialogLoading}
                    />
                  </React.Fragment>
                ))}
            </TableBody>
          </MUITable>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 75, 100]}
            component="div"
            count={tableData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      ) : (
        <StyledContainer>
          <StyledText>Nothing to show here</StyledText>
        </StyledContainer>
      )}
    </>
  );
};

export default memo(Table);
