import { Box, styled } from "@mui/material";
import { Button } from "@component";
import Input from "@component/input/Input";

export const StyledContainer = styled(Box)(({ theme }) => ({
  width: "80%",
  alignSelf: "center",
  display: "flex",
  flexDirection: "column",
  marginBottom: theme.spacing(18),
}));

export const StyledInput = styled(Input)(({ theme }) => ({
  width: "100%",
  alignSelf: "center",
  padding: theme.spacing(2, 0),
  input: {
    color: theme.palette.common.black,
    height: 10,
  },
  "& .MuiFormHelperText-root": {
    color: theme.palette.error.main,
  },
}));

export const StyledButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  position: "fixed",
  bottom: 0,
  width: "31vw",
  alignSelf: "center",
  background: theme.palette.common.white,
  padding: theme.spacing(6, 0),
  [theme.breakpoints.down("sm")]: {
    width: "80vw",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  width: "27%",
  height: 45,
  color: theme.palette.secondary.main,
  border: "2px solid",
  margin: theme.spacing(4),
  background: theme.palette.common.white,
  "&:hover": {
    background: theme.palette.common.white,
  },
  [theme.breakpoints.down("lg")]: {
    width: "30%",
  },
}));
