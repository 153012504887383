import { Button, Input, Text } from "@component";
import { Box, styled } from "@mui/material";

export const StyledContainer = styled(Box)(({ theme }) => ({
  height: "50vh",
  margin: theme.spacing(0, 14),
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
}));

export const StyledInput = styled(Input)(({ theme }) => ({
  width: "100%",
  alignSelf: "center",
  padding: theme.spacing(2, 0),
  input: {
    color: theme.palette.common.black,
    height: 10,
  },
  "& .MuiFormHelperText-root": {
    color: theme.palette.error.main,
  },
}));

export const StyledText = styled(Text)(({ theme }) => ({
  color: theme.palette.common.black,
  marginLeft: theme.spacing(4),
  marginBottom: theme.spacing(2),
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(12),
  width: "30%",
  height: 40,
  alignSelf: "center",
  [theme.breakpoints.down("sm")]: {
    width: "45%",
  },
}));
