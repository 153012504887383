import { Box, styled } from "@mui/material";

export const StyledContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.common.white,
  padding: theme.spacing(10, 10),
  width: "50%",
  wordBreak: "break-word",
  marginTop: theme.spacing(10),
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  height: "90vh",
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "80vh",
}));
