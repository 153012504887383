import { styled, Box, Fab } from "@mui/material";

export const StyledFabButtonContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "100vh",
}));

export const StyledFabButton = styled(Fab)(({ theme }) => ({
  top: "-10%",
  left: "90%",
  transform: "translate(-50%, -50%)",
  [theme.breakpoints.down("sm")]: {
    top: "-14%",
    left: "90%",
    bottom: 0,
    right: 0,
  },
}));
