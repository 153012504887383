import React from "react";
import { AdminList, CircularProgress, SearchInput } from "@component";
import manageAdminsController from "./manageAdmins.controller";
import { ManageAdminsDataProps } from "@component/adminList/adminList.interface";
import { useAppSelector } from "@services/redux/controller";
import {
  StyledBox,
  StyledButton,
  StyledButtonContainer,
  StyledProgressContainer,
} from "./manageAdmins.style";
import AddAdminSlider from "./addAdminSlider/AddAdminSlider";

const ManageAdmins: React.FC = () => {
  const { handleOpen, openModal, handleClose, loading, adminsData } =
    manageAdminsController();
  const { userData } = useAppSelector((state) => state.userReducer);
  return (
    <StyledBox>
      <StyledButtonContainer>
        {userData?.adminType === 1 && (
          <StyledButton onClick={handleOpen}>Add new account</StyledButton>
        )}
        <AddAdminSlider openDrawer={openModal} handleCloseIcon={handleClose} />
        <SearchInput />
      </StyledButtonContainer>
      {loading ? (
        <StyledProgressContainer>
          <CircularProgress size={50} color={"primary"} />
        </StyledProgressContainer>
      ) : (
        adminsData.map((item: ManageAdminsDataProps) => (
          <AdminList key={item._id} item={item} />
        ))
      )}
    </StyledBox>
  );
};

export default ManageAdmins;
