export default {
  getUserList: "getUserList",
  getCompanyUserList: "getCompanyUserList",
  getAllUsers: "getAllUsers",
  getAdmins: "getAdmins",
  removeUserList: "removeUserList",
  addPersonUser: "addPersonUser",
  addCompanyUser: "addCompanyUser",
  updateUserList: "updateUserList",
  updateCompanyUserList: "updateCompanyUserList",
  removePersonUser: "removePersonUser",
  removeCompanyUser: "removeCompanyUser",
};
