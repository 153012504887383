import React, { memo, useCallback, useState } from "react";
import { IconButton, InputAdornment, TextFieldProps } from "@mui/material";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { StyledInput } from "./input.style";

export interface InputProps {
  showPasswordIcon?: boolean;
}

const Input: React.FC<InputProps & TextFieldProps> = ({
  variant = "standard",
  inputProps,
  type = "text",
  showPasswordIcon = false,
  ...restProps
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handlePasswordToggle = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  return (
    <StyledInput
      variant={variant}
      type={showPasswordIcon ? (showPassword ? "text" : "password") : type}
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="start"
            sx={{
              display: showPasswordIcon ? "flow" : "none",
            }}
          >
            <IconButton onClick={handlePasswordToggle}>
              {showPassword ? (
                <VisibilityOffOutlined fontSize="medium" />
              ) : (
                <VisibilityOutlined fontSize="medium" />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...restProps}
    />
  );
};

export default memo(Input);
