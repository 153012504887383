import { CompanyPortfolioReducerProps } from "./interface";
import type from "./type";

const initialState: CompanyPortfolioReducerProps = {
  portfolioProduct: [],
  portfolioJobWork: [],
};

const companyPortfolioReducer = (
  state: CompanyPortfolioReducerProps = initialState,
  action: any
) => {
  switch (action.type) {
    case type.getPortfolioProductData:
      return {
        ...state,
        portfolioProduct: action.payload,
      };
    case type.getPortfolioJobWorkData:
      return {
        ...state,
        portfolioJobWork: action.payload,
      };
    case type.editPortfolioProductData:
      return {
        ...state,
        portfolioProduct: action.payload,
      };
    case type.editPortfolioJobWorkData:
      return {
        ...state,
        portfolioJobWork: action.payload,
      };
    case type.addPortfolioProductData:
      const addedProduct = [...state.portfolioProduct, action.payload];
      return {
        ...state,
        portfolioProduct: addedProduct,
      };
    case type.addPortfolioJobWorkData:
      const addedJobWork = [...state.portfolioJobWork, action.payload];
      return {
        ...state,
        portfolioJobWork: addedJobWork,
      };
    case type.removePortfolioProductData:
      return {
        ...state,
        portfolioProduct: action.payload,
      };
    case type.removePortfolioJobWorkData:
      return {
        ...state,
        portfolioJobWork: action.payload,
      };
    default:
      return state;
  }
};

export default companyPortfolioReducer;
