import { createBrowserRouter } from "react-router-dom";
import ForgotPassword from "@pages/auth/forgotPassword/ForgotPassword";
import Login from "@pages/auth/login/Login";
import Drawer from "@pages/wrapper/drawer/Drawer";
import Dashboard from "@pages/home/dashboard/Dashboard";
import Users from "@pages/home/users/Users";
import Company from "@pages/home/company/Company";
import AppBar from "@pages/wrapper/appBar/AppBar";
import ManageAdmins from "@pages/home/manageAdmins/ManageAdmins";
import Support from "@pages/home/support/Support";
import Reports from "@pages/home/reports/Reports";
import Account from "@pages/home/account/Account";
import UserDetails from "@pages/home/userDetails/UserDetails";
import CompanyDetails from "@pages/home/companyDetails/CompanyDetails";
import AboutUs from "@pages/home/aboutUs/AboutUs";
import Qualifications from "@pages/home/qualifications/Qualifications";
import Zone from "@pages/home/zone/Zone";
import AllUsers from "@pages/home/allUsers/AllUsers";

export const routes = [
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/forgotPassword",
    element: <ForgotPassword />,
  },
  {
    path: "/drawer",
    element: <Drawer />,
    children: [
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "users",
        element: <Users />,
      },
      {
        path: "users/:userId/userDetails",
        element: <UserDetails />,
      },
      {
        path: "company",
        element: <Company />,
      },
      {
        path: "company/:companyId/companyDetails",
        element: <CompanyDetails />,
      },
      {
        path: "allUsers",
        element: <AllUsers />,
      },
      {
        path: "qualifications",
        element: <Qualifications />,
      },
      {
        path: "zone",
        element: <Zone />,
      },
      {
        path: "aboutUs",
        element: <AboutUs />,
      },
      {
        path: "appBar",
        element: <AppBar />,
        children: [
          {
            path: "account",
            element: <Account />,
          },
          {
            path: "manageAdmins",
            element: <ManageAdmins />,
          },
          {
            path: "support",
            element: <Support />,
          },
          {
            path: "reports",
            element: <Reports />,
          },
        ],
      },
    ],
  },
];

const router = createBrowserRouter(routes);

export default router;
