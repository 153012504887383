import { Container } from "@component";
import { Box, styled } from "@mui/material";

export const StyledUsersContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flex: 1,
  flexDirection: "row",
  justifyContent: "space-between",
  margin: theme.spacing(0, 4),
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

export const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(4, 4),
  background: theme.palette.common.white,
  flexDirection: "column",
}));
