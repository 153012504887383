import { useMemo, useState } from "react";
import { axiosInstance } from "@services/api/api";
import { Constant } from "@services/constant";
import {
  MachineControllerProps,
  MachineProcessDataProps,
  MachineProps,
} from "./machine.interface";
import { removeJobWorkData } from "@services/redux/allCompanyDetails/action";
import { useAppDispatch, useAppSelector } from "@services/redux/controller";

const machineController = ({
  userId,
}: MachineProps): MachineControllerProps => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [index, setIndex] = useState<string>("");
  const [machineId, setMachineId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { jobWork } = useAppSelector((state) => state.allCompanyDetailsReducer);
  const dispatch = useAppDispatch();
  const machineData: MachineProcessDataProps[] = useMemo(
    () =>
      jobWork &&
      jobWork
        ?.filter((item: any) => item.machining)
        .map((item: any) => ({
          _id: item._id,
          title: item.machining,
          description: item.machinigJobDescription,
          file: item.machiningFiles,
        }))
        .reverse(),
    [jobWork]
  );

  const handleCloseDrawer = (): void => {
    setOpenDrawer(false);
  };

  const handleOpenDrawer = (id?: string): void => {
    setOpenDrawer(true);
    setIndex(id);
  };

  const removeMachine = async (machineId: string) => {
    /* API call for removing machine */
    setMachineId(machineId);
    const formData = new URLSearchParams();
    formData.append("userId", userId);
    formData.append("type", "1");
    formData.append("jobWorkId", machineId);
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(
        Constant.RemoveProductJobworkJobVacancy,
        formData.toString()
      );
      setLoading(false);
      dispatch(removeJobWorkData(data.data));
    } catch (error) {
      setLoading(false);
    }
  };

  return {
    machineData,
    handleCloseDrawer,
    openDrawer,
    handleOpenDrawer,
    index,
    removeMachine,
    loading,
    machineId,
  };
};

export default machineController;
