import { memo } from "react";
import { useTheme } from "@mui/material";
import addUserController from "./addCompanyUser.controller";
import { AddCompanyUserProps } from "./addCompanyUser.interface";
import {
  StyledButton,
  StyledButtonContainer,
  StyledContainer,
  StyledInput,
} from "./addCompanyUser.style";

const AddCompanyUser: React.FC<AddCompanyUserProps> = ({
  handleNextButton,
  companyUserId,
}) => {
  const theme = useTheme();
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    mobileNo,
    setMobileNo,
    password,
    setPassword,
    error,
    validation,
    loading,
  } = addUserController({ handleNextButton, companyUserId });
  return (
    <StyledContainer sx={{ mt: companyUserId && theme.spacing(10) }}>
      <StyledInput
        variant="outlined"
        placeholder="First Name"
        InputLabelProps={{ style: { color: theme.palette.common.black } }}
        value={firstName}
        onChange={(val) => setFirstName(val?.target?.value)}
        helperText={error.firstName}
      />
      <StyledInput
        variant="outlined"
        placeholder="Last Name"
        InputLabelProps={{
          style: { color: theme.palette.common.black },
        }}
        value={lastName}
        onChange={(val) => setLastName(val?.target?.value)}
        helperText={error.lastName}
      />
      <StyledInput
        variant="outlined"
        placeholder="Email"
        InputLabelProps={{ style: { color: theme.palette.common.black } }}
        value={email}
        onChange={(val) => setEmail(val?.target?.value)}
        helperText={error.email}
      />
      <StyledInput
        variant="outlined"
        placeholder="Mobile Number"
        InputLabelProps={{ style: { color: theme.palette.common.black } }}
        value={mobileNo}
        onChange={(val) => setMobileNo(val.target.value.slice(0, 10))}
        helperText={error.mobileNo}
      />
      {!companyUserId && (
        <StyledInput
          variant="outlined"
          placeholder="Password"
          InputLabelProps={{ style: { color: theme.palette.common.black } }}
          value={password}
          onChange={(val) => setPassword(val?.target?.value)}
          helperText={error.password}
          showPasswordIcon
        />
      )}
      <StyledButtonContainer>
        <StyledButton
          onClick={() => {
            validation();
            handleNextButton;
          }}
          loading={loading}
        >
          Next
        </StyledButton>
      </StyledButtonContainer>
    </StyledContainer>
  );
};

export default memo(AddCompanyUser);
