import React, { memo } from "react";
import { Box } from "@mui/material";
import CompanyDetailsCard from "@component/card/companyDetailsCard/CompanyDetailsCard";
import { CompanyProfileDetailsProps } from "./companyProfileDetails.interface";
import { Constant } from "@services/constant";
import { image } from "@assets";
import {
  StyledContainer,
  StyledProfileContainer,
  StyledText,
  StyledTextWithBackground,
  StyledUserNameText,
  StyledUserProfile,
} from "./companyProfileDetails.style";

const CompanyProfileDetails: React.FC<CompanyProfileDetailsProps> = ({
  userDetailsData,
  profileImage,
  firstName,
  lastName,
  email,
  isAccountVerified,
}) => {
  return (
    <StyledContainer sx={{ boxShadow: 3 }}>
      <StyledProfileContainer>
        <StyledUserProfile
          src={
            profileImage
              ? `${Constant.baseUrl}${profileImage}`
              : image.userProfile
          }
        />
        <Box>
          <StyledUserNameText color="primary">{`${firstName} ${lastName}`}</StyledUserNameText>
          <StyledText>{email}</StyledText>
          <StyledTextWithBackground>
            {isAccountVerified ? "Verified" : "Not verified"}
          </StyledTextWithBackground>
        </Box>
      </StyledProfileContainer>
      {userDetailsData.map((item) => (
        <CompanyDetailsCard item={item} key={item._id} />
      ))}
    </StyledContainer>
  );
};

export default memo(CompanyProfileDetails);
