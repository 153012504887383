import React, { memo } from "react";
import { useTheme } from "@mui/material";
import addUserController from "./addUser.controller";
import { AddUserProps } from "./addUser.interface";
import {
  StyledButton,
  StyledButtonContainer,
  StyledContainer,
  StyledInput,
} from "./addUser.style";

const AddUser: React.FC<AddUserProps> = ({ handleNextButton, userId }) => {
  const theme = useTheme();
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    mobileNo,
    setMobileNo,
    password,
    setPassword,
    error,
    validation,
    loading,
  } = addUserController({ handleNextButton, userId });
  return (
    <StyledContainer>
      <StyledInput
        variant="outlined"
        placeholder="First Name"
        InputLabelProps={{ style: { color: theme.palette.common.black } }}
        value={firstName}
        onChange={(val) => setFirstName(val?.target?.value)}
        helperText={error.firstName}
      />
      <StyledInput
        variant="outlined"
        placeholder="Last Name"
        InputLabelProps={{
          style: { color: theme.palette.common.black },
        }}
        value={lastName}
        onChange={(val) => setLastName(val?.target?.value)}
        helperText={error.lastName}
      />
      <StyledInput
        variant="outlined"
        placeholder="Email"
        InputLabelProps={{ style: { color: theme.palette.common.black } }}
        value={email}
        onChange={(val) => setEmail(val?.target?.value)}
        helperText={error.email}
      />
      <StyledInput
        variant="outlined"
        placeholder="Mobile Number"
        InputLabelProps={{ style: { color: theme.palette.common.black } }}
        value={mobileNo}
        onChange={(val) => setMobileNo(val.target.value.slice(0, 10))}
        helperText={error.mobileNo}
      />
      {!userId && (
        <StyledInput
          variant="outlined"
          placeholder="Password"
          InputLabelProps={{ style: { color: theme.palette.common.black } }}
          value={password}
          onChange={(val) => setPassword(val?.target?.value)}
          helperText={error.password}
          showPasswordIcon
        />
      )}
      <StyledButtonContainer>
        <StyledButton onClick={validation} loading={loading}>
          Next
        </StyledButton>
      </StyledButtonContainer>
    </StyledContainer>
  );
};

export default memo(AddUser);
