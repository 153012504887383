import React, { memo } from "react";
import { Step, StepButton, Stepper, useTheme } from "@mui/material";
import AddCompanyUser from "@pages/home/company/addCompanyUserSlider/addCompanyUser/AddCompanyUser";
import addCompanyUserSliderController from "./AddCompanyUserSlider.controller";
import { AddCompanyUserSliderProps } from "./addCompanyUserSlider.interface";
import CompanyUserProfileStepOne from "./companyUserProfileStepOne/CompanyUserProfileStepOne";
import CompanyUserProfileStepTwo from "./companyUserProfileStepTwo/CompanyUserProfileStepTwo";
import Slider from "../../../../component/slider/Slider";
import { stepsData } from "./addCompanyUserSlider.const";

const AddCompanyUserSlider: React.FC<AddCompanyUserSliderProps> = ({
  openDrawer,
  handleCloseIcon,
  companyUserId,
}) => {
  const theme = useTheme();
  const { activeStep, handleStep, completed, handleComplete, resetStepper } =
    addCompanyUserSliderController({
      handleCloseIcon,
      openDrawer,
      companyUserId,
    });

  const getStepContent = (step: number) => {
    switch (step) {
      case 0: {
        return (
          <AddCompanyUser
            handleNextButton={handleComplete}
            companyUserId={companyUserId}
          />
        );
      }
      case 1: {
        return (
          <CompanyUserProfileStepOne
            handleNext={handleComplete}
            companyUserId={companyUserId}
          />
        );
      }
      case 2: {
        return (
          <CompanyUserProfileStepTwo
            handleFinish={handleComplete}
            companyUserId={companyUserId}
          />
        );
      }
    }
  };
  return (
    <Slider
      openDrawer={openDrawer}
      handleCloseIcon={() => {
        handleCloseIcon();
        resetStepper();
      }}
    >
      {openDrawer && (
        <>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            sx={{ mb: theme.spacing(4) }}
          >
            {stepsData.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButton onClick={() => handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          {getStepContent(activeStep)}
        </>
      )}
    </Slider>
  );
};

export default memo(AddCompanyUserSlider);
