export default {
  getPortfolioProductData: "getPortfolioProductData",
  getPortfolioJobWorkData: "getPortfolioJobWorkData",
  editPortfolioProductData: "editPortfolioProductData",
  editPortfolioJobWorkData: "editPortfolioJobWorkData",
  removePortfolioProductData: "removePortfolioProductData",
  removePortfolioJobWorkData: "removePortfolioJobWorkData",
  addPortfolioProductData: "addPortfolioProductData",
  addPortfolioJobWorkData: "addPortfolioJobWorkData",
};
