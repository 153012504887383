import {
  Dashboard,
  Person,
  Article,
  FolderCopySharp,
  WorkOutlined,
  Settings,
  LogoutOutlined,
  PeopleAlt,
} from "@mui/icons-material";
import { DrawerDataProps } from "./drawer.interface";

export const drawerData: DrawerDataProps[] = [
  {
    id: 1,
    title: "Dashboard",
    icon: Dashboard,
    navigation: "dashboard",
  },
  {
    id: 2,
    title: "Users",
    icon: Person,
    navigation: "users",
  },
  {
    id: 3,
    title: "Company",
    icon: WorkOutlined,
    navigation: "company",
  },
  {
    id: 4,
    title: "All Users",
    icon: PeopleAlt,
    navigation: "allUsers",
  },
  {
    id: 5,
    title: "Master",
    icon: FolderCopySharp,
    expand: true,
  },
  {
    id: 6,
    title: "Settings",
    icon: Settings,
    navigation: "appBar/account",
  },
  {
    id: 7,
    title: "About Us",
    icon: Article,
    navigation: "aboutUs",
  },
  {
    id: 8,
    title: "Logout",
    icon: LogoutOutlined,
    navigation: "/",
  },
];
