import { EditUserReducerProps } from "./interface";
import type from "./type";

const initialState: EditUserReducerProps = {
  editBasicDetailsData: {
    firstName: null,
    lastName: null,
    email: null,
    mobileNo: null,
    password: null,
  },
  editQualificationsData: {
    qualification: null,
    degree: null,
    qualificationData: [
      {
        expertise: null,
        experience: null,
        experienceError: null,
        expertiseError: null,
      },
    ],
    currentlyWorkingCompanyName: null,
  },
};

const editPersonUserReducer = (
  state: EditUserReducerProps = initialState,
  action: any
) => {
  switch (action.type) {
    case type.editBasicDetails:
      return {
        ...state,
        editBasicDetailsData: action.payload,
      };
    case type.editQualifications:
      return {
        ...state,
        editQualificationsData: action.payload,
      };
    case type.removeUserData:
      return initialState;
    default:
      return state;
  }
};

export default editPersonUserReducer;
