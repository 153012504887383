import { Box, styled } from "@mui/material";

export const BoxContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  width: "345px",
  height: "100%",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(16, 10),
    width: "100%",
  },
  zIndex: theme.zIndex.drawer + 1,
}));
