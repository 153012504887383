import React, { memo, useState } from "react";
import { DeleteOutline, Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Constant } from "@services/constant";
import { image } from "@assets";
import Switch from "@component/switch/Switch";
import { TableItemCardProps } from "./tableItemCard.interface";
import {
  StyledButton,
  StyledTableCell,
  StyledTableRow,
  StyledUserProfile,
} from "./tableItemCard.style";

const TableItemCard: React.FC<TableItemCardProps> = ({
  item,
  handleDetails,
  isButton,
  buttonText,
  isDeleteButton,
  handleDeleteButton,
  isEditButton,
  handleEdit,
  onHandleSwitch,
  checked: isChecked,
}) => {
  const [checked, setChecked] = useState(!isChecked);
  return (
    <StyledTableRow key={item._id}>
      {Object.keys(item)
        .filter((k) => k !== "_id")
        .map((x) => (
          <React.Fragment key={`${item._id}${x}`}>
            {x === "profileImage" ? (
              <StyledTableCell component="th" scope="item">
                <StyledUserProfile
                  src={
                    item[x]
                      ? `${Constant.baseUrl}${item[x]}`
                      : image.userProfile
                  }
                />
              </StyledTableCell>
            ) : (
              <>
                {x !== "status" ? (
                  <StyledTableCell align="center">
                    {item[x] ? item[x] : "-"}
                  </StyledTableCell>
                ) : (
                  <StyledTableCell align="center">
                    <Switch
                      checked={checked}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        {
                          setChecked(!checked);
                          onHandleSwitch && onHandleSwitch(!checked);
                        }
                      }}
                    />
                  </StyledTableCell>
                )}
              </>
            )}
          </React.Fragment>
        ))}
      {isButton && (
        <StyledTableCell align="center">
          <StyledButton onClick={handleDetails}>{buttonText}</StyledButton>
        </StyledTableCell>
      )}
      {isEditButton && (
        <StyledTableCell align="center">
          <IconButton onClick={handleEdit}>
            <Edit color="secondary" />
          </IconButton>
        </StyledTableCell>
      )}
      {isDeleteButton && (
        <StyledTableCell align="center">
          <IconButton onClick={handleDeleteButton}>
            <DeleteOutline sx={{ color: "#EE4B2B" }} />
          </IconButton>
        </StyledTableCell>
      )}
    </StyledTableRow>
  );
};

export default memo(TableItemCard);
