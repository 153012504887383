import { Box, Grid } from "@mui/material";
import dashboardController from "./dashboard.controller";
import { NumberOfUsersCard, UsersChart, UsersFromCountries } from "@component";
import { StyledContainer, StyledUsersContainer } from "./dashboard.style";

const Dashboard: React.FC = () => {
  const { userCount, loading, onCompanyUser, onPersonUser, onTotalUser } =
    dashboardController();
  return (
    <StyledContainer>
      <Box mt={5} mb={6}>
        <Grid container>
          <Grid item xs={6} sm={6} md={4}>
            <NumberOfUsersCard
              loading={loading}
              title="Company Users"
              totalNumber={userCount?.companyCount}
              onUserCount={onCompanyUser}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <NumberOfUsersCard
              loading={loading}
              title="Person Users"
              totalNumber={userCount?.personCount}
              onUserCount={onPersonUser}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <NumberOfUsersCard
              loading={loading}
              title="Total Users"
              totalNumber={userCount?.companyCount + userCount?.personCount}
              onUserCount={onTotalUser}
            />
          </Grid>
        </Grid>
      </Box>
      <StyledUsersContainer>
        <UsersChart />
        <UsersFromCountries />
      </StyledUsersContainer>
    </StyledContainer>
  );
};

export default Dashboard;
