import { AppBarDataProps } from "@pages/wrapper/appBar/appBar.interface";

export const tabData: AppBarDataProps[] = [
  {
    id: 1,
    title: "Products",
  },
  {
    id: 2,
    title: "JobWork",
  },
  {
    id: 3,
    title: "Vacancy",
  },
];
