import { ZoneReducerProps } from "./interface";
import type from "./type";

const initialState: ZoneReducerProps = {
  zones: [],
};

const zoneReducer = (state: ZoneReducerProps = initialState, action: any) => {
  switch (action.type) {
    case type.getZones:
      return {
        ...state,
        zones: action.payload,
      };
    case type.addZones: {
      const data = [...state.zones, action.payload];
      return {
        ...state,
        zones: data,
      };
    }
    case type.updateZones: {
      const data = state.zones.map((item) =>
        item._id === action.payload._id ? action.payload : item
      );
      return {
        zones: data,
      };
    }
    case type.removeZones:
      return {
        zones: action.payload,
      };

    default:
      return state;
  }
};

export default zoneReducer;
