import React, { memo } from "react";
import { Typography, TypographyProps, useTheme } from "@mui/material";

export type TextProps =
  | {
      variant?: "h1";
    }
  | {
      variant?: "h2" | "h3" | "h4" | "h5" | "h6" | "body";
    }
  | {
      variant?: "bodyBig" | "bodySmall";
    };

const Text: React.FC<TextProps & Omit<TypographyProps, "fontWeight">> = (
  props
) => {
  const theme = useTheme();
  return (
    <Typography color={theme.palette.common.white} {...props}>
      {props.children}
    </Typography>
  );
};

export default memo(Text);
