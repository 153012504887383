import types from "./type";

const loginSuccess = (payload: any) => ({
  type: types.loginSuccess,
  payload,
});

const logout = () => ({
  type: types.logout,
});

const setNavigation = (payload: any) => ({
  type: types.setNavigation,
  payload,
});

export { loginSuccess, logout, setNavigation };
