import { useEffect, useMemo, useState } from "react";
import {
  addPortfolioJobWorkData,
  editPortfolioJobWorkData,
} from "@services/redux/companyPortfolio/action";
import { axiosInstance } from "@services/api/api";
import { checkNumeric } from "@utils/validation/validation";
import { Constant } from "@services/constant";
import {
  EditPortfolioMachineProps,
  EditPortfolioMachineControllerProps,
  ErrorProps,
} from "./editPortfolioMachine.interface";
import { JobWorkType } from "@pages/home/companyDetails/allCompanyDetails/machine/editMachineSlider/editMachineSlider.interface";
import { useAppDispatch, useAppSelector } from "@services/redux/controller";
import validationMessage from "@utils/validation/validationMessage";

const editPortfolioMachineController = ({
  userId,
  handleCloseIcon,
  machineId,
  openDrawer,
}: EditPortfolioMachineProps): EditPortfolioMachineControllerProps => {
  useEffect(() => {
    if (machineId) {
      setDescription(machineData[0]?.machinigJobDescription);
      setNoOfMachine(machineData[0]?.noOfMachining);
      setSelectedFile(machineData[0]?.machiningFiles);
    }
  }, []);

  const { portfolioJobWork } = useAppSelector(
    (state) => state.companyPortfolioReducer
  );
  const dispatch = useAppDispatch();
  const machineData = useMemo(
    () =>
      portfolioJobWork?.filter((item: any) => {
        return item._id === machineId;
      }),
    [portfolioJobWork]
  );

  const [selectedMachining, setSelectedMachining] = useState<
    JobWorkType | string
  >("");
  const [selectMachiningMainCategory, setSelectMachiningMainCategory] =
    useState<JobWorkType | string>("");
  const [selectMachiningSubMainCategory, setSelectMachiningSubMainCategory] =
    useState<JobWorkType | string>("");
  const [description, setDescription] = useState<string>("");
  const [noOfMachine, setNoOfMachine] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<Blob | MediaSource | null>(
    null
  );
  const [removeFile, setRemoveFile] = useState<string>();
  const [error, setError] = useState<ErrorProps>({
    selectMachineError: null,
    descriptionError: null,
    noOfMachineError: null,
  });
  const [selectedFileError, setSelectedFileError] = useState<string | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string>("");

  const selectedMachine: string = selectMachiningSubMainCategory
    ? `${selectMachiningMainCategory?.name}-${selectMachiningSubMainCategory?.name}-${selectedMachining?.name}`
    : selectedMachining
    ? `${
        selectMachiningMainCategory
          ? selectMachiningMainCategory.name + "-" + selectedMachining?.name
          : selectedMachining?.name
      }`
    : machineData[0]?.machining
    ? machineData[0]?.machining
    : "Select Machine";

  const handleCloseSnackBar = (): void => {
    setOpenSnackBar(false);
  };

  const fileType = ["image/png"];

  const handleSelectedFile = (e: any): void => {
    /* Functionality to choose image for profile image */
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile && fileType.includes(selectedFile.type)) {
        setSelectedFile(selectedFile);
        setSelectedFileError("");
      } else {
        setSelectedFile(null);
        setSelectedFileError("Please select image file");
      }
    }
  };

  const validation = (): void => {
    /* Validation for edit machine form */
    let isValidate = true;
    if (!selectedMachine) {
      isValidate = false;
      error.selectMachineError = validationMessage.selectMachineValidation;
    } else {
      error.selectMachineError = "";
    }
    if (!description) {
      isValidate = false;
      error.descriptionError = validationMessage.validationDescription;
    } else {
      error.descriptionError = "";
    }
    if (!noOfMachine) {
      isValidate = false;
      error.noOfMachineError = validationMessage.noOfMachineValidation;
    } else if (!checkNumeric(noOfMachine)) {
      isValidate = false;
      error.noOfMachineError = validationMessage.validNoOfMachineValidation;
    } else {
      error.noOfMachineError = "";
    }
    setError({ ...error });
    if (isValidate) {
      if (machineId) {
        editMachine();
      } else {
        addMachine();
      }
    }
  };

  const onDeleteIcon = (): void => {
    if (selectedFile) {
      setRemoveFile(machineData[0]?.machiningFiles);
    }
    setSelectedFile(null);
  };

  const addMachine = async () => {
    /* API call for adding machine */
    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("machining", selectedMachine);
    formData.append("machinigJobDescription", description);
    formData.append("noOfMachining", noOfMachine);
    formData.append("machiningFiles", selectedFile);
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(
        Constant.addPortfolioJobWork,
        formData
      );
      setLoading(false);
      dispatch(addPortfolioJobWorkData(data.data));
      setTimeout(() => handleCloseIcon(), 2000);
      setOpenSnackBar(true);
      setSnackBarMessage(data.message);
    } catch (error: any) {
      setLoading(false);
      setOpenSnackBar(true);
      setSnackBarMessage(error.data.message);
    }
  };

  const editMachine = async () => {
    /* API call for editing machine */
    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("jobWorkId", machineId);
    formData.append("machining", selectedMachine);
    formData.append("machinigJobDescription", description);
    formData.append("noOfMachining", noOfMachine);
    formData.append("machiningFiles", selectedFile);
    if (removeFile) {
      formData.append("removeMachiningUrl", removeFile);
    }
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(
        Constant.editPortfolioJobWork,
        formData
      );
      setLoading(false);
      dispatch(editPortfolioJobWorkData(data.data));
      setTimeout(() => handleCloseIcon(), 2000);
      setOpenSnackBar(true);
      setSnackBarMessage(data.message);
    } catch (error: any) {
      setLoading(false);
      setOpenSnackBar(true);
      setSnackBarMessage(error.data.message);
    }
  };

  return {
    description,
    error,
    handleSelectedFile,
    noOfMachine,
    selectedFile,
    selectedFileError,
    selectedMachine,
    setDescription,
    setNoOfMachine,
    validation,
    handleCloseSnackBar,
    loading,
    openSnackBar,
    snackBarMessage,
    onDeleteIcon,
    selectedMachining,
    selectMachiningMainCategory,
    selectMachiningSubMainCategory,
    setSelectedMachining,
    setSelectMachiningMainCategory,
    setSelectMachiningSubMainCategory,
  };
};

export default editPortfolioMachineController;
