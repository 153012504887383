import React from "react";
import { Grid, useTheme } from "@mui/material";
import { ReportCard } from "@component";
import { reportData } from "./reports.const";
import { StyledBox } from "./reports.style";

const Reports: React.FC = () => {
  const theme = useTheme();
  return (
    <StyledBox>
      <Grid container spacing={theme.spacing(8)}>
        {reportData.map((item) => (
          <Grid item xs={12} sm={12} md={6} lg={4} key={item.id}>
            <ReportCard item={item} />
          </Grid>
        ))}
      </Grid>
    </StyledBox>
  );
};

export default Reports;
