import { useEffect, useState } from "react";
import {
  addQualifications,
  updateQualifications,
} from "@services/redux/qualifications/action";
import {
  AddQualificationsSliderControllerProps,
  AddQualificationsSliderProps,
} from "./addQualificationsSlider.interface";
import { axiosInstance } from "@services/api/api";
import { Constant } from "@services/constant";
import { checkName } from "@utils/validation/validation";
import { useAppDispatch, useAppSelector } from "@services/redux/controller";
import validationMessage from "@utils/validation/validationMessage";
import { QualificationProps } from "@services/redux/qualifications/interface";

const addQualificationsSliderController = ({
  handleCloseIcon,
  qualificationId,
}: AddQualificationsSliderProps): AddQualificationsSliderControllerProps => {
  const [qualification, setQualification] = useState<string>();
  const [qualificationError, setQualificationError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string>("");

  const dispatch = useAppDispatch();

  const { qualifications } = useAppSelector(
    (state) => state.qualificationReducer
  );
  const qualificationName = qualifications.filter(
    (item: QualificationProps) => item._id === qualificationId
  );

  useEffect(() => {
    if (qualificationId) {
      setQualification(qualificationName[0].qualification);
    } else {
      setQualification("");
    }
  }, [qualificationId]);

  const handleCloseSnackBar = (): void => {
    setOpenSnackBar(false);
  };

  const addQualification = async () => {
    /* API call for adding or editing qualification */
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(
        Constant.addEditQualifications,
        {
          qualification: qualification,
          id: qualificationId,
        }
      );
      if (qualificationId) {
        dispatch(updateQualifications(data.data));
      } else {
        dispatch(addQualifications(data.data));
      }
      setLoading(false);
      setQualification("");
      setTimeout(() => handleCloseIcon(), 2000);
      setOpenSnackBar(true);
      setSnackBarMessage(data.message);
    } catch (error: any) {
      setLoading(false);
      setOpenSnackBar(true);
      setSnackBarMessage(error.data.message);
    }
  };

  const validation = (): void => {
    /* Validation for qualification */
    let isValid = true;
    if (!qualification) {
      isValid = false;
      setQualificationError(validationMessage.validationQualificationMessage);
    } else if (!checkName(qualification)) {
      isValid = false;
      setQualificationError(validationMessage.validationValidQualification);
    } else {
      setQualificationError("");
    }
    if (isValid) {
      addQualification();
    }
  };

  return {
    qualification,
    setQualification,
    qualificationError,
    loading,
    openSnackBar,
    validation,
    handleCloseSnackBar,
    snackBarMessage,
  };
};

export default addQualificationsSliderController;
