import React, { memo } from "react";
import { Box, useTheme } from "@mui/material";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  CheckBoxOutlineBlank,
  CheckBox,
} from "@mui/icons-material";
import {
  listItems,
  listSubItems,
  MultipleSelectionDropDownProps,
} from "./multipleSelectionDropDown.interface";
import multipleSelectionDropDownController from "./multipleSelectionDropDown.controller";
import {
  StyledList,
  StyledButton,
  StyledListContainer,
  Container,
  MainSelectionContainer,
  StyledText,
} from "./multipleSelectionDropDown.style";

const MultipleSelectionDropDown: React.FC<MultipleSelectionDropDownProps> = ({
  data,
  onSelectValue,
  placeholder,
  selectedValues,
  values,
}) => {
  const theme = useTheme();
  const {
    currentExpandView,
    currentSubChildExpandView,
    currentSubExpandView,
    onClickItems,
    onClickSingleItem,
    singleSelectionValue,
    openDetails,
    openSubDetails,
    openSubChildDetails,
    modalVisible,
    setModalVisible,
    arrow,
    setArrow,
  } = multipleSelectionDropDownController({
    data,
    onSelectValue,
    placeholder,
    selectedValues,
    values,
  });

  const SubDataRenderView: React.FC<listSubItems> = ({ item, index }: any) => {
    return (
      <Box ml={theme.spacing(8)} mt={theme.spacing(4)}>
        {!item?.children ? (
          <Container onClick={() => onClickItems(item)}>
            <StyledText color={theme.palette.common.black}>
              {item?.name}
            </StyledText>
            {singleSelectionValue?.id == item.id ? (
              <CheckBox />
            ) : (
              <CheckBoxOutlineBlank />
            )}
          </Container>
        ) : (
          <Container
            onClick={() => {
              currentSubChildExpandView(item);
            }}
          >
            <StyledText>{item?.name}</StyledText>
            {item?.id != openSubChildDetails?.id ? (
              <KeyboardArrowDown />
            ) : (
              <KeyboardArrowUp />
            )}
          </Container>
        )}
        {openSubChildDetails?.id == item.id &&
          item?.children?.map((item: any) => <SubDataRenderView item={item} />)}
      </Box>
    );
  };

  const ListDetailsRenderView: React.FC<listSubItems> = ({
    item,
    index,
  }: any) => {
    return (
      <Box ml={theme.spacing(8)} mt={theme.spacing(4)}>
        {!item?.children ? (
          <Container onClick={() => onClickItems(item)}>
            <StyledText>{item?.name}</StyledText>
            {singleSelectionValue?.id == item.id ? (
              <CheckBox />
            ) : (
              <CheckBoxOutlineBlank />
            )}
          </Container>
        ) : (
          <Container onClick={() => currentSubExpandView(item)}>
            <StyledText>{item?.name}</StyledText>
            {item?.id != openSubDetails?.id ? (
              <KeyboardArrowDown />
            ) : (
              <KeyboardArrowUp />
            )}
          </Container>
        )}
        {openSubDetails?.id == item.id && (
          <Box>
            {item?.children.map((item) => (
              <SubDataRenderView item={item} index={item.id} />
            ))}
          </Box>
        )}
      </Box>
    );
  };

  const DetailsRenderView: React.FC<listItems> = ({ item, index }) => {
    return (
      <>
        <MainSelectionContainer>
          {item?.children ? (
            <Container
              onClick={() => {
                currentExpandView(item);
              }}
            >
              <StyledText>{item?.name}</StyledText>
              {item?.id != openDetails?.id ? (
                <KeyboardArrowDown />
              ) : (
                <KeyboardArrowUp />
              )}
            </Container>
          ) : (
            <Container
              onClick={() => {
                onClickSingleItem(item);
              }}
            >
              <StyledText>{item?.name}</StyledText>
              {singleSelectionValue?.id == item.id ? (
                <CheckBox />
              ) : (
                <CheckBoxOutlineBlank />
              )}
            </Container>
          )}
        </MainSelectionContainer>
        {item?.id == openDetails?.id && (
          <Box>
            {item?.children.map((item, index) => (
              <ListDetailsRenderView item={item} index={item.id} key={index} />
            ))}
          </Box>
        )}
      </>
    );
  };
  return (
    <>
      <StyledButton
        onClick={() => setModalVisible(!modalVisible)}
        variant="text"
        endIcon={<KeyboardArrowDown />}
      >
        {placeholder}
      </StyledButton>
      {modalVisible && (
        <StyledListContainer sx={{ boxShadow: 4 }}>
          <StyledList>
            {data.map((item: any) => (
              <DetailsRenderView item={item} />
            ))}
          </StyledList>
        </StyledListContainer>
      )}
    </>
  );
};

export default memo(MultipleSelectionDropDown);
