import { useState } from "react";
import {
  AddUserSliderControllerProps,
  AddUserSliderProps,
} from "./addUserSlider.interface";
import { stepsData } from "./addUserSlider.const";

const addUserSliderController = ({
  handleCloseIcon,
}: AddUserSliderProps): AddUserSliderControllerProps => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? stepsData.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const totalSteps = (): number => {
    return stepsData.length;
  };

  const completedSteps = (): number => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleComplete = (): void => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
    if (allStepsCompleted()) {
      handleCloseIcon();
      setActiveStep(0);
      setCompleted({});
    }
  };

  const resetStepper = (): void => {
    setActiveStep(0);
    setCompleted({});
  };

  return {
    activeStep,
    allStepsCompleted,
    completedSteps,
    handleComplete,
    handleNext,
    isLastStep,
    totalSteps,
    completed,
    resetStepper,
  };
};

export default addUserSliderController;
