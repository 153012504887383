import React from "react";
import { useTheme } from "@mui/material";
import { Button, Input } from "@component";
import forgotPasswordController from "./forgotPassword.controller";
import { image } from "@assets";
import {
  StyledBackToLogin,
  StyledCenterBox,
  StyledForgotPasswordContainer,
  StyledLogo,
  StyledText,
} from "./forgotPassword.style";

const ForgotPassword: React.FC = () => {
  const theme = useTheme();
  const { email, setEmail, onBack } = forgotPasswordController();
  return (
    <StyledForgotPasswordContainer>
      <StyledCenterBox>
        <StyledLogo src={image.bsetuLogo} />
        <StyledText variant="h6" mt={theme.spacing(14)}>
          Forgot Password
        </StyledText>
        <StyledText variant="h6" mt={theme.spacing(6)}>
          Enter the email address that you used to create acccount.
        </StyledText>
        <Input
          label="Email"
          value={email}
          fullWidth
          onChange={(val) => setEmail(val?.target?.value)}
          sx={{
            input: { color: theme.palette.common.black },
          }}
          InputLabelProps={{ style: { color: theme.palette.common.black } }}
        />
        <Button variant="contained" fullWidth sx={{ mt: theme.spacing(16) }}>
          Continue
        </Button>
        <StyledBackToLogin variant="text" onClick={onBack}>
          Back to Login
        </StyledBackToLogin>
      </StyledCenterBox>
    </StyledForgotPasswordContainer>
  );
};

export default ForgotPassword;
