import React, { memo } from "react";
import { useTheme } from "@mui/material";
import addQualificationsSliderController from "./addQualificationsSlider.controller";
import { AddQualificationsSliderProps } from "./addQualificationsSlider.interface";
import { Slider, SnackBar } from "@component";
import {
  StyledButton,
  StyledContainer,
  StyledInput,
  StyledText,
} from "./addQualificationsSlider.style";

const AddQualificationsSlider: React.FC<AddQualificationsSliderProps> = ({
  openDrawer,
  handleCloseIcon,
  qualificationId,
}) => {
  const theme = useTheme();
  const {
    qualification,
    setQualification,
    qualificationError,
    loading,
    validation,
    handleCloseSnackBar,
    openSnackBar,
    snackBarMessage,
  } = addQualificationsSliderController({
    openDrawer,
    handleCloseIcon,
    qualificationId,
  });
  return (
    <Slider
      title={qualificationId ? "Edit Qualifications" : "Add Qualifications"}
      openDrawer={openDrawer}
      handleCloseIcon={handleCloseIcon}
    >
      <StyledContainer>
        <StyledText>Qualification</StyledText>
        <StyledInput
          variant="outlined"
          placeholder="Qualification"
          InputLabelProps={{ style: { color: theme.palette.common.black } }}
          value={qualification}
          onChange={(val) => setQualification(val?.target?.value)}
          helperText={qualificationError}
        />
        <StyledButton
          loading={loading}
          onClick={validation}
          progressColor="info"
        >
          {qualificationId ? "Edit" : " Add"}
        </StyledButton>
        <SnackBar
          open={openSnackBar}
          onClose={handleCloseSnackBar}
          onCloseClick={handleCloseSnackBar}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          message={snackBarMessage}
        />
      </StyledContainer>
    </Slider>
  );
};

export default memo(AddQualificationsSlider);
