import { useEffect, useState } from "react";
import {
  addCompanyUser,
  updateCompanyUserList,
} from "@services/redux/userList/action";
import { axiosInstance } from "@services/api/api";
import {
  checkAnnualIncome,
  checkGST,
  checkNumeric,
  checkString,
  checkWebsiteLink,
} from "@utils/validation/validation";
import { Company } from "@services/redux/userList/interface";
import {
  CompanyTypeDataProps,
  CompanyUserProfileStepTwoControllerProps,
  CompanyUserProfileStepTwoProps,
  ValidationProps,
} from "./companyUserProfileStepTwo.interface";
import { Constant } from "@services/constant";
import { removeEditData } from "@services/redux/addCompanyUser/action";
import { useAppDispatch, useAppSelector } from "@services/redux/controller";
import validationMessage from "@utils/validation/validationMessage";

const companyUserProfileStepTwoController = ({
  handleFinish,
  companyUserId,
}: CompanyUserProfileStepTwoProps): CompanyUserProfileStepTwoControllerProps => {
  const dispatch = useAppDispatch();
  const { companyUserList } = useAppSelector((state) => state.userListReducer);
  const [gstNumber, setGstNumber] = useState<string>();
  const [annualTurnOver, setAnnualTurnOver] = useState<string>();
  const [noOfEmployees, setNoOfEmployees] = useState<number | string>();
  const [aboutCompany, setAboutCompany] = useState<string>();
  const [websiteLink, setWebsiteLink] = useState<string>();
  const [select, setSelect] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string>("");
  const [error, setError] = useState<ValidationProps>({
    gstNumber: null,
    annualTurnOver: null,
    noOfEmployees: null,
    aboutCompany: null,
    websiteLink: null,
    select: null,
  });

  useEffect(() => {
    if (companyUserId) {
      setGstNumber(editData[0].gstNumber);
      setAnnualTurnOver(editData[0].annualTurnOver);
      setNoOfEmployees(editData[0].noOfEmpolyees);
      setAboutCompany(editData[0].companyAboutUs);
      setWebsiteLink(editData[0].websiteLink);
      setSelect(
        editData[0].companyType ? editData[0].companyType.split(",") : []
      );
    }
  }, []);

  let editData: Company[] = companyUserList.filter(
    (item: Company) => item._id === companyUserId
  );

  const { editBasicDetailsData, editCompanyDetailsData } = useAppSelector(
    (state) => state.editCompanyUserReducer
  );
  const { firstName, lastName, email, mobileNo } = editBasicDetailsData;
  const { legalFirmName, ownerName, companyEmail, companyMobileNo, address } =
    editCompanyDetailsData;

  const handleCloseSnackBar = (): void => {
    setOpenSnackBar(false);
  };

  const multipleSelect = (item: CompanyTypeDataProps): void => {
    /* Multiple selection */
    if (select.includes(item.type)) {
      setSelect(select.filter((element) => element !== item.type));
    } else {
      setSelect((e) => [...e, item.type]);
    }
  };

  const userId: string | null = localStorage.getItem("addCompanyUserId");

  const registrationFormData = {
    id: userId,
    gstNumber: gstNumber && gstNumber,
    annualTurnOver: annualTurnOver && annualTurnOver,
    noOfEmpolyees: noOfEmployees && noOfEmployees,
    companyType: select.toString(),
    companyAboutUs: aboutCompany && aboutCompany,
    websiteLink: websiteLink && websiteLink,
  };

  const companyUserRegistrationStepThree = async () => {
    /* API call for company user registration step three */
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(
        Constant.companyRegisterStep3,
        registrationFormData
      );
      setLoading(false);
      setOpenSnackBar(true);
      setSnackBarMessage(data.message);
      setTimeout(() => handleFinish(), 3000);
      dispatch(addCompanyUser(data.data[0]));
    } catch (error: any) {
      setOpenSnackBar(true);
      setSnackBarMessage(error.data.message);
      setLoading(false);
    }
  };

  const formData = {
    id: companyUserId,
    firstName: firstName,
    lastName: lastName,
    email: email,
    mobileNumber: mobileNo,
    registerType: "0",
    legalFirmName: legalFirmName,
    ownerName: ownerName,
    companyMobileNumber: companyMobileNo,
    companyEmail: companyEmail,
    address: address,
    gstNumber: gstNumber,
    annualTurnOver: annualTurnOver,
    noOfEmpolyees: noOfEmployees,
    companyType: select.toString(),
    companyAboutUs: aboutCompany,
    websiteLink: websiteLink,
  };

  const editCompanyUserData = async () => {
    /* API call for editing company user data */
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(
        Constant.editCompanyUserData,
        formData
      );
      setLoading(false);
      setOpenSnackBar(true);
      setSnackBarMessage(data.message);
      dispatch(updateCompanyUserList(data.data));
      setTimeout(() => handleFinish(), 3000);
    } catch (error: any) {
      setLoading(false);
      setOpenSnackBar(true);
      setSnackBarMessage(error.data.message);
    }
  };

  const validation = (): void => {
    /* Validation for company type and other company details */
    let isValidate = true;
    // if (select.length === 0) {
    //   isValidate = false;
    //   error.select = validationMessage.validationSelectCompanyTypeMessage;
    // } else {
    //   error.select = null;
    // }
    if (gstNumber) {
      if (!gstNumber) {
        isValidate = false;
        error.gstNumber = validationMessage.validationGSTNumber;
      } else if (
        gstNumber.length < 15 ||
        gstNumber.length > 23 ||
        !checkGST(gstNumber)
      ) {
        isValidate = false;
        error.gstNumber = validationMessage.validationValidGSTNumber;
      } else {
        error.gstNumber = null;
      }
    }
    if (annualTurnOver) {
      if (!annualTurnOver) {
        isValidate = false;
        error.annualTurnOver = validationMessage.validatonAnnualTurnOver;
      } else if (!checkAnnualIncome(annualTurnOver)) {
        isValidate = false;
        error.annualTurnOver = validationMessage.validatonValidAnnualTurnOver;
      } else {
        error.annualTurnOver = null;
      }
    }
    if (noOfEmployees) {
      if (!noOfEmployees) {
        isValidate = false;
        error.noOfEmployees = validationMessage.validatonNoOfEmployees;
      } else if (!checkNumeric(noOfEmployees.toString())) {
        isValidate = false;
        error.noOfEmployees = validationMessage.validatonValidNoOfEmployees;
      } else {
        error.noOfEmployees = null;
      }
    }
    if (aboutCompany) {
      if (!aboutCompany) {
        isValidate = false;
        error.aboutCompany = validationMessage.validationAboutCompany;
      } else if (!checkString(aboutCompany)) {
        isValidate = false;
        error.aboutCompany = validationMessage.validationValidAboutCompany;
      } else {
        error.aboutCompany = null;
      }
    }
    if (!checkWebsiteLink(websiteLink) && websiteLink) {
      isValidate = false;
      error.websiteLink = validationMessage.validatonValidWebsiteLink;
    } else {
      error.websiteLink = null;
    }
    setError({ ...error });
    if (isValidate) {
      if (!companyUserId) {
        companyUserRegistrationStepThree();
      } else {
        editCompanyUserData();
        dispatch(removeEditData());
      }
    }
  };

  return {
    gstNumber,
    setGstNumber,
    annualTurnOver,
    setAnnualTurnOver,
    noOfEmployees,
    setNoOfEmployees,
    aboutCompany,
    setAboutCompany,
    websiteLink,
    setWebsiteLink,
    select,
    error,
    validation,
    multipleSelect,
    loading,
    snackBarMessage,
    openSnackBar,
    handleCloseSnackBar,
  };
};

export default companyUserProfileStepTwoController;
