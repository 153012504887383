export enum Constant {
  baseUrl = "https://api.bsetu.com/",
  login = "admin/login",
  addAdmin = "addAdmin",
  allAdmin = "allAdmin",
  changePassword = "changePasswordWithOldPassword",
  allUsers = "allUser",
  companyRegisterStep1 = "companyRegisterStep1",
  companyRegisterStep2 = "companyRegisterStep2",
  companyRegisterStep3 = "companyRegisterStep3",
  personalProfile = "personalProfile",
  personalProfileStep2 = "personalProfileStep2",
  getQualifications = "admin/qualifications",
  addEditQualifications = "admin/addedit-qualification",
  removeQualification = "admin/remove-qualification",
  userCount = "admin/count",
  addEditAboutUs = "admin/addedit-aboutus",
  getAboutUs = "admin/aboutus",
  editPersonUser = "admin/edit-personuser",
  editCompanyUserData = "admin/edit-companyuser",
  updateQualificationStatus = "admin/qualifications/status",
  removeUser = "admin/remove-user",
  getZones = "admin/zone",
  addEditZone = "admin/addedit-zone",
  zoneStatusUpdate = "admin/zone-status",
  companyAllData = "admin/company-all-data",
  editProductAdmin = "admin/product-edit",
  editJobWorkAdmin = "admin/jobwork-edit",
  editJobVacancyAdmin = "admin/jobvacancy/edit",
  editCompanyJobWorkAdmin = "admin/companyjobwork/edit",
  RemoveProductJobworkJobVacancy = "admin/product-jobwork-jobvacancy/remove",
  getCompanyPortfolio = "admin/companyportfolio",
  removePortfolioProductJobWork = "admin/company-product-jobwork/remove",
  editPortfolioProduct = "admin/companyproduct/edit",
  editPortfolioJobWork = "admin/companyjobwork/edit",
  addPortfolioProduct = "admin/companyproduct-add",
  addPortfolioJobWork = "admin/companyjobwork-add",
  addProduct = "admin/product-add",
  addJobVacancy = "admin/jobvacancy-add",
  addJobWork = "admin/jobwork-add",
}
