import { Box, Paper, styled } from "@mui/material";
import Button from "@component/button/Button";

export const StyledPaper = styled(Paper)(({ theme }) => ({
  minHeight: 230,
  width: 290,
  background: theme.palette.primary.main,
  padding: theme.spacing(6),
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

export const StyledButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: theme.spacing(14),
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  width: "45%",
  borderRadius: theme.spacing(4),
  "&.Mui-disabled": {
    color: theme.palette.grey[600],
    background: theme.palette.grey[500],
  },
}));
