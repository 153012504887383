import { theme } from "@setup/theme/theme";
import { CssBaseline, ThemeProvider } from "@mui/material";

interface Props {
  children?: React.ReactNode;
}

export const Providers: React.FC<Props> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
