import React from "react";
import { useTheme } from "@mui/material";
import { Button, SnackBar, Text } from "@component";
import { image } from "@assets";
import loginController from "./login.controller";
import {
  StyledCenterBox,
  StyledInput,
  StyledLoginButton,
  StyledLoginContainer,
  StyledLogo,
} from "./login.style";

const Login: React.FC = () => {
  const theme = useTheme();
  const {
    email,
    setEmail,
    password,
    setPassword,
    onForgotPassword,
    error,
    validation,
    loading,
    openSnackBar,
    setOpenSnackBar,
    snackBarMessage,
  } = loginController();

  return (
    <StyledLoginContainer>
      <StyledCenterBox>
        <StyledLogo src={image.bsetuLogo} />
        <Text
          variant="h5"
          mt={theme.spacing(16)}
          color={theme.palette.common.black}
        >
          Welcome
        </Text>
        <Text
          variant="h6"
          mt={theme.spacing(2)}
          color={theme.palette.common.black}
        >
          Please Login to Continue
        </Text>
        <StyledInput
          label="Email"
          InputLabelProps={{ style: { color: theme.palette.common.black } }}
          fullWidth
          value={email}
          onChange={(val) => setEmail(val?.target?.value)}
          helperText={error?.email}
        />
        <StyledInput
          label="Password"
          InputLabelProps={{ style: { color: theme.palette.common.black } }}
          fullWidth
          value={password}
          onChange={(val) => setPassword(val?.target?.value)}
          showPasswordIcon
          helperText={error?.password}
        />
        <Button
          variant="text"
          onClick={onForgotPassword}
          sx={{ alignSelf: "flex-end", color: theme.palette.common.black }}
        >
          Forgot Password?
        </Button>
        <StyledLoginButton
          fullWidth
          variant="contained"
          onClick={validation}
          loading={loading}
          progressColor="info"
        >
          Login
        </StyledLoginButton>
        <SnackBar
          open={openSnackBar}
          onClose={() => setOpenSnackBar(false)}
          onCloseClick={() => setOpenSnackBar(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          message={snackBarMessage}
          autoHideDuration={6000}
        />
      </StyledCenterBox>
    </StyledLoginContainer>
  );
};

export default Login;
