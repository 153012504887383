import { Box, styled } from "@mui/material";

export const StyledBox = styled(Box)(({ theme }) => ({
  width: "49%",
  padding: theme.spacing(6, 6),
  height: "70vh",
  background: theme.palette.common.white,
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "70vh",
  },
}));
