import { useEffect, useMemo, useState } from "react";
import { axiosInstance } from "@services/api/api";
import { Constant } from "@services/constant";
import { getUserList, removePersonUser } from "@services/redux/userList/action";
import { User } from "@services/redux/userList/interface";
import { useAppDispatch, useAppSelector } from "@services/redux/controller";
import { UserDataProps, UsersControllerProps } from "./users.interface";

const usersController = (): UsersControllerProps => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [index, setIndex] = useState<string>();
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const users = useAppSelector((state) => state.userListReducer);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(
    users.userList.length > 0 ? false : true
  );

  const userData = useMemo(() => {
    return users?.userList.map((item: User) => ({
      _id: item._id,
      profileImage: item.profileImage,
      name: `${item?.firstName} ${item?.lastName ?? ""}`,
      email: item.email,
      mobileNumber: item.mobileNumber,
      companyName: item.currentlyWorkingCompanyName,
    }));
  }, [users?.userList]);
  const [filterData, setFilterData] = useState<UserDataProps[] | null>(null);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  useEffect(() => {
    onGetAllUsers();
  }, []);

  const filter = (value: string): void => {
    /* Functionality to search users */
    let filterData = userData.filter((item: UserDataProps) => {
      let search = [item.name, item.email, item.mobileNumber];
      let regex = search.toString().toLowerCase();
      return regex.toString().match(value);
    });
    setSearchValue(value);
    setFilterData(filterData);
  };

  const handleOpen = (id?: string): void => {
    /* Open add/edit user slider */
    setOpenModal(true);
    setIndex(id);
  };

  const handleClose = (): void => {
    /* Close add/edit user slider */
    setOpenModal(false);
  };

  const handleDialogOpen = (id: string): void => {
    /* Open delete user dialog */
    setOpenDialog(true);
    setIndex(id);
  };

  const handleDialogClose = (): void => {
    /* Close delete user dialog */
    setOpenDialog(false);
  };

  const onGetAllUsers = async () => {
    /* API call for getting all users */
    try {
      const { data } = await axiosInstance.post(Constant.allUsers, {
        registerType: 1,
      });
      dispatch(getUserList(data.data));
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const removeUser = async () => {
    /* API call for removing person user */
    try {
      setDeleteLoading(true);
      const { data } = await axiosInstance.post(Constant.removeUser, {
        id: index,
      });
      setDeleteLoading(false);
      handleDialogClose();
      dispatch(removePersonUser(index));
    } catch (error) {
      setDeleteLoading(false);
    }
  };

  return {
    loading,
    openModal,
    handleOpen,
    handleClose,
    filter,
    filterData,
    searchValue,
    userData,
    openDialog,
    handleDialogOpen,
    handleDialogClose,
    index,
    removeUser,
    deleteLoading,
  };
};

export default usersController;
