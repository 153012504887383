export default {
  getProductData: "getProductData",
  getJobWorkData: "getJobWorkData",
  getVacancyData: "getVacancyData",
  addProductData: "addProductData",
  addJobWorkData: "addJobWorkData",
  addVacancyData: "addVacancyData",
  editProductData: "editProductData",
  editJobWorkData: "editJobWorkData",
  editVacancyData: "editVacancyData",
  removeProductData: "removeProductData",
  removeJobWorkData: "removeJobWorkData",
  removeVacancyData: "removeVacancyData",
};
