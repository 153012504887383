import React from "react";
import { useTheme } from "@mui/material";
import accountController from "./account.controller";
import { image } from "@assets";
import { SnackBar } from "@component";
import { useAppSelector } from "@services/redux/controller";
import {
  StyledContainer,
  StyledButton,
  StyledInput,
  StyledInputBox,
  StyledText,
  StyledUserProfile,
  StyledProfileContainer,
  StyledBox,
  StyledChangeText,
} from "./account.style";

const Account: React.FC = () => {
  const theme = useTheme();
  const {
    oldPassword,
    setOldPassword,
    newPassword,
    setNewPassword,
    confirmPassword,
    setConfirmPassword,
    loading,
    openSnackBar,
    setOpenSnackBar,
    validation,
    error,
    updateMessage,
  } = accountController();
  const { userData } = useAppSelector((state) => state.userReducer);
  return (
    <StyledContainer>
      <StyledProfileContainer>
        <StyledUserProfile src={image.userProfile} />
        <StyledBox>
          <StyledText>{`${userData?.firstName} ${userData?.lastName}`}</StyledText>
          <StyledText>{userData?.email}</StyledText>
        </StyledBox>
      </StyledProfileContainer>
      <StyledChangeText variant="h5">Change your password</StyledChangeText>
      <StyledInputBox>
        <StyledText flex={1}>Current Password</StyledText>
        <StyledInput
          variant="outlined"
          showPasswordIcon
          InputLabelProps={{ style: { color: theme.palette.common.black } }}
          fullWidth
          value={oldPassword}
          onChange={(val) => setOldPassword(val?.target?.value)}
          helperText={error.oldPassword}
        />
      </StyledInputBox>
      <StyledInputBox>
        <StyledText flex={1}>New Password</StyledText>
        <StyledInput
          variant="outlined"
          showPasswordIcon
          InputLabelProps={{ style: { color: theme.palette.common.black } }}
          fullWidth
          value={newPassword}
          onChange={(val) => setNewPassword(val?.target?.value)}
          helperText={error.newPassword}
        />
      </StyledInputBox>
      <StyledInputBox>
        <StyledText flex={1}>Confirm Password</StyledText>
        <StyledInput
          variant="outlined"
          showPasswordIcon
          InputLabelProps={{ style: { color: theme.palette.common.black } }}
          fullWidth
          value={confirmPassword}
          onChange={(val) => setConfirmPassword(val?.target?.value)}
          helperText={error.confirmPassword}
        />
      </StyledInputBox>
      <StyledInputBox sx={{ justifyContent: "flex-end" }}>
        <StyledButton
          onClick={validation}
          loading={loading}
          progressColor="info"
        >
          Update
        </StyledButton>
      </StyledInputBox>
      <SnackBar
        open={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        onCloseClick={() => setOpenSnackBar(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        message={updateMessage}
        autoHideDuration={6000}
      />
    </StyledContainer>
  );
};

export default Account;
