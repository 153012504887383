import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import { DrawerDataProps } from "@pages/wrapper/drawer/drawer.interface";
import {
  DrawerItemControllerProps,
  DrawerItemProps,
} from "./drawerItem.interface";
import { logout, setNavigation } from "@services/redux/user/action";
import { removeUserList } from "@services/redux/userList/action";
import { TableCellDataProps } from "@component/customTable/table.interface";
import { useAppDispatch, useAppSelector } from "@services/redux/controller";

const drawerItemController = ({
  data,
  onItemClick,
}: DrawerItemProps): DrawerItemControllerProps => {
  const navigation = useNavigate();
  const [openCollapse, setOpenCollapse] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { navigationTo } = useAppSelector((state) => state.userReducer);
  const theme = useTheme();

  const selectedItem = (item: TableCellDataProps): string => {
    if (item.title === navigationTo) {
      return theme.palette.secondary.main;
    } else {
      return theme.palette.common.white;
    }
  };

  const handleOpenSettings = (): void => {
    setOpenCollapse(!openCollapse);
  };

  const onItemPress = (item: DrawerDataProps): void => {
    item.navigation && dispatch(setNavigation(item.title));
    item.navigation && navigation(item.navigation);
    if (item.navigation === "/") {
      dispatch(logout());
      dispatch(removeUserList());
    }
    if (item.expand) {
      dispatch(setNavigation("Master"));
      handleOpenSettings();
    }
  };

  const onQualificationButton = (): void => {
    navigation("qualifications");
    onItemClick();
  };

  const onZoneButton = (): void => {
    navigation("zone");
    onItemClick();
  };

  return {
    selectedItem,
    openCollapse,
    onItemPress,
    onQualificationButton,
    onZoneButton,
  };
};

export default drawerItemController;
