import { styled, TableCell, tableCellClasses, TableRow } from "@mui/material";
import Button from "@component/button/Button";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: theme.spacing(7),
    padding: theme.spacing(4, 8),
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.grey[500],
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const StyledUserProfile = styled("img")(({ theme }) => ({
  height: theme.spacing(17),
  width: theme.spacing(17),
  borderRadius: theme.spacing(17),
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  height: 35,
  width: 75,
  borderRadius: theme.spacing(10),
}));
