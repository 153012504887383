import React, { memo } from "react";
import { Box, MenuItem, useTheme } from "@mui/material";
import { expertiseData, qualificationData } from "./userProfileStepOne.const";
import userProfileStepOneController from "./userProfileStepOne.controller";
import { UserProfileStepOneProps } from "./userProfileStepOne.interface";
import {
  StyledAddButton,
  StyledButton,
  StyledButtonContainer,
  StyledContainer,
  StyledErrorText,
  StyledExperienceInput,
  StyledInput,
  StyledInputContainer,
  StyledSelection,
} from "./userProfileStepOne.style";
import { MultipleSelectionDropDown } from "@component";

const UserProfileStepOne: React.FC<UserProfileStepOneProps> = ({
  handleNextButton,
  userId,
}) => {
  const theme = useTheme();
  const {
    handleSelectionChange,
    selectQualification,
    courseName,
    setCourseName,
    companyName,
    setCompanyName,
    expertiseAndExperience,
    addMoreExpertiseHandler,
    expertiseAndExperienceInputHandler,
    validation,
    error,
    loading,
    qualificationsData,
    placeholderText,
    selectExpertise,
    editData,
  } = userProfileStepOneController({
    handleNextButton,
    userId,
  });
  return (
    <StyledContainer>
      <StyledSelection
        labelname={
          editData[0]?.qualification
            ? editData[0]?.qualification
            : "Qualification"
        }
        inputlabelstyle={{
          "&.Mui-focused": {
            color: theme.palette.grey[900],
          },
        }}
        value={selectQualification}
        onChange={(e: any) => handleSelectionChange(e)}
        size={"small"}
        MenuProps={{
          PaperProps: { sx: { maxHeight: "33%" } },
        }}
      >
        {qualificationsData.map((item) => (
          <MenuItem key={item._id} value={item?.qualification}>
            {item?.qualification}
          </MenuItem>
        ))}
      </StyledSelection>
      <StyledErrorText>{error.selectQualification}</StyledErrorText>
      <StyledInput
        variant="outlined"
        placeholder="Course Name"
        InputLabelProps={{ style: { color: theme.palette.common.black } }}
        value={courseName}
        onChange={(val) => setCourseName(val?.target?.value)}
        helperText={error.courseName}
      />
      {expertiseAndExperience.map((input, index) => (
        <StyledInputContainer key={index}>
          {/* <StyledInput
            variant="outlined"
            placeholder="Expertise"
            InputLabelProps={{ style: { color: theme.palette.common.black } }}
            sx={{ mr: theme.spacing(8) }}
            value={input.expertise}
            onChange={(val) =>
              expertiseAndExperienceInputHandler(
                val?.target?.value,
                index,
                "expertise"
              )
            }
            helperText={input.expertiseError}
          /> */}
          <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
            <MultipleSelectionDropDown
              data={expertiseData}
              placeholder={placeholderText(input)}
              onSelectValue={(
                details: any,
                mainCategory: any,
                subMainCategory: any
              ) => {
                selectExpertise(index, details, mainCategory, subMainCategory);
              }}
              values={input?.selectedCategory}
            />
          </Box>
          <StyledExperienceInput
            variant="outlined"
            placeholder="Experience(years)"
            InputLabelProps={{ style: { color: theme.palette.common.black } }}
            sx={{ ml: theme.spacing(8) }}
            value={input.experience}
            onChange={(val) =>
              expertiseAndExperienceInputHandler(
                val?.target?.value,
                index,
                "experience"
              )
            }
            helperText={input.experienceError}
          />
        </StyledInputContainer>
      ))}
      <StyledAddButton onClick={addMoreExpertiseHandler}>
        Add More
      </StyledAddButton>
      <StyledInput
        variant="outlined"
        placeholder="Currently Working(Company Name)"
        InputLabelProps={{ style: { color: theme.palette.common.black } }}
        value={companyName}
        onChange={(val) => setCompanyName(val?.target?.value)}
        helperText={error.companyName}
      />
      <StyledButtonContainer>
        <StyledButton
          onClick={() => {
            validation();
            handleNextButton;
          }}
          loading={loading}
        >
          Next
        </StyledButton>
      </StyledButtonContainer>
    </StyledContainer>
  );
};

export default memo(UserProfileStepOne);
