import { Button, ButtonProps, lighten, styled, Theme } from "@mui/material";

const getBackgroundColor = (
  theme: Theme,
  variant: Pick<ButtonProps, "variant">
) => {
  if (variant.variant === "text") {
    return "transparent";
  }
  return theme.palette.secondary.main;
};

const getHoverColor = (theme: Theme, variant: Pick<ButtonProps, "variant">) => {
  if (variant.variant === "text") {
    return "transparent";
  }
  return lighten(theme.palette.secondary.main, 0.3);
};

export const StyledButtonContainer = styled(Button)(({ theme, variant }) => ({
  background: getBackgroundColor(theme, { variant: variant }),
  borderRadius: 10,
  height: theme.spacing(16),
  color: theme.palette.common.white,
  textTransform: "none",
  "&:hover": {
    background: getHoverColor(theme, { variant: variant }),
  },
}));
